import React from 'react'
import clip from "../../assets/images/clip.gif";

const CaliforniaPrivacyNotice = () => {
  return (
    <>
    <section className='terms_page'>
        <div className='container'>

            <div className='rowd'>

            <h1>CALIFORNIA  PRIVACY NOTICE</h1><br/>
  This California Privacy Notice (the &ldquo;CA Disclosures&rdquo;)  supplements the information contained in our  Privacy Policy and applies solely  to individual residents of the State of California (&quot;consumers&quot; or &quot;you&quot;).<br/>
  Unless otherwise expressly stated, all terms in these CA  Disclosures have the same meaning as defined in our Privacy Policy or as otherwise defined the California Consumer  Privacy Act (&ldquo;CCPA&rdquo;).
<h2>SCOPE</h2>
<p>When we use the term &ldquo;personal information&rdquo; in these CA Disclosures,  we mean information that identifies,  relates to, describes, is reasonably capable of being associated with, or could  reasonably be linked, directly  or indirectly, with a particular consumer or household.<br/>
  For the purposes  of these Disclosures, personal information does not include:</p>
<ol>
  <ol>
    <li>Publicly available information from government records.</li>
    <li>Deidentified, aggregated or anonymized information that  is maintained in a form that is not capable of being associated with or linked  to you.</li>
    <li>Information excluded from the  CCPA's scope, such as health or medical information covered by the Health Insurance Portability and  Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical  trial data; or information covered by  certain sector-specific privacy laws, including the Fair Credit Reporting Act  (FRCA), the Gramm-Leach-Bliley Act  (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy  Protection Act of 1994.</li>
    <li>Information relating  to our employees, contractors and other personnel.</li>
  </ol>
</ol>
<p>&nbsp;</p>
<h2>COLLECTION AND USE OF PERSONAL INFORMATION </h2>
<p>We collect personal information from and about you for a  variety of purposes. To learn more about the  types of personal  information we collect,  the sources from which we collect or receive personal  information, and the purposes for which we use this information, please  refer to What Information Do We Collect About You and How Do We Use It? in our Privacy  Policy.</p>
<br clear="all"/>
<div>
  <p>In the last 12 months, we have collected the following categories of personal information:</p>
  <table border="1" cellspacing="0" cellpadding="0">
    <tr>
      <td width="312" valign="top"><p>Category of Personal Information Collected </p></td>
      <td width="61" valign="top"><p>Collect ed </p></td>
      <td width="251" valign="top"><p>Categories of Sources </p></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Identifiers</strong>, such as your name, address, phone number,    email address, date of birth    or other similar    identifiers.</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p> <img width="18" height="18" src={clip}/> </p> </td>
      <td width="251" valign="top"><ol>
        <li>Directly from you</li>
      </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Our business partners and    affiliates</li>
        </ol>
        <ol>
          <li>Third parties you direct    to share information with us</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Social networks</li>
        </ol>
        <ol>
          <li>Data sellers</li>
        </ol></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>California    Customer Records </strong>(Cal. Civ. Code § 1798.80(e)), such as birthdate, contact information and payment information.</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><img width="18" height="18" src={clip}/></p></td>
      <td width="251" valign="top"><ol>
        <li>Directly from you</li>
      </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Our business partners and    affiliates</li>
        </ol>
        <ol>
          <li>Third parties you direct    to share information with us</li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Social networks</li>
        </ol>
        <ol>
          <li>Data sellers</li>
        </ol></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p>&nbsp;</p>
        <p><strong>Commercial Information, </strong>such as property information and purchase history.</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p><img width="18" height="18" src={clip}/></p></td>
      <td width="251" valign="top"><ol>
        <li>Directly from you</li>
      </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Your browser or device</li>
        </ol></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p><strong>Internet/Network Information, </strong>such as device information, logs and analytics data.</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p><img width="18" height="18" src={clip}/></p></td>
      <td width="251" valign="top"><p>&nbsp;</p>
        <ol>
          <li>Your browser or device</li>
        </ol></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p>G<strong>eolocation Data, </strong>such as precise    location information from    your device or generated based on IP address.</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p><img width="18" height="18" src={clip}/></p></td>
      <td width="251" valign="top"><p>&nbsp;</p>
        <ol>
          <li>Your browser or device</li>
        </ol></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p><strong>Sensory Information, </strong>such as recordings of phone calls    with us, where    permitted by law.</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p><img width="18" height="18" src={clip}/></p></td>
      <td width="251" valign="top"><p>&nbsp;</p>
        <ol>
          <li>Directly from    you</li>
        </ol></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p>&nbsp;</p>
        <p><strong>Profession/Employment Information, </strong>such as your company    information and, for applicants, your employment history.</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><img width="18" height="18" src={clip}/></p></td>
      <td width="251" valign="top"><ol>
        <li>Directly from you</li>
      </ol>
        <p>&nbsp;</p>
        <ol>
          <li>From our customers</li>
        </ol>
        <ol>
          <li>For applicants, from third    parties you direct    to share information with us</li>
        </ol></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p><strong>Non-Public Education Information </strong>(20 U.S.C.<br/>
        § 1232g, 34 C.F.R. Part    99), such as educational history, degree (for applicants)</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p><img width="18" height="18" src={clip}/></p></td>
      <td width="251" valign="top"><p>&nbsp;</p>
        <ol>
          <li>Directly from    you • Third parties you direct to share information from us</li>
        </ol></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p>&nbsp;</p>
        <p><strong>Other Personal Information, </strong>including information    you post on our social media pages or otherwise provide    to us</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><img width="18" height="18" src={clip}/></p></td>
      <td width="251" valign="top"><ol>
        <li>Directly from you</li>
      </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Third parties you direct    to share information from us</li>
        </ol>
        <ol>
          <li>Your browser or device</li>
        </ol></td>
    </tr>
    <tr>
      <td width="312" valign="top"><p>&nbsp;</p>
        <p><strong>Inferences</strong>, such as predictions about your interests and preferences.</p></td>
      <td width="61" valign="top"><p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><img width="18" height="18" src={clip}/></p></td>
      <td width="251" valign="top"><ol>
        <li>Your browser or device</li>
      </ol>
        <p>&nbsp;</p>
        <ol>
          <li>Information generated or derived from    your online browsing and usage activity</li>
        </ol></td>
    </tr>
  </table>
</div>
<br clear="all"/>

<br clear="all"/>
<div>
  <h2>DISCLOSURE OF PERSONAL INFORMATION </h2>
  <p>As described in the section called How We Share Your  Information in our Privacy Policy, we share personal  information with third parties for business purposes or we may sell your  personal information to third parties,  subject to your right to opt  out of those sales (see The Right to Opt-Out of Personal Information Sales below).<br/>
    In the previous 12 months, we have disclosed the  following categories of personal information to third parties for a business purpose:</p>
  <ol>
    <ol>
      <li>All of the categories of personal information we collect, explained in the table above.</li>
    </ol>
  </ol>
  <p>In the previous 12 months, we have sold the following categories of personal  information to third  parties, subject to your settings  and preferences and your Right to Opt Out:</p>
  <ol>
    <ol>
      <li>Identifiers</li>
      <li>Commercial Information</li>
      <li>Internet / Network Information</li>
      <li>Geolocation Data</li>
    </ol>
  </ol>
  <p>The categories of third parties  to whom we sell or disclose your personal information for a business  purpose include:</p>
  <h2>Our affiliate companies</h2>
  <ol>
    <ol>
      <li><strong>Other third party  business partners </strong>who use this information to market to you, to perform analytics  and conduct research, or for other purposes;</li>
      <li><strong>Service providers and advisors </strong>that perform  services for us, on our behalf, which may include  providing marketing and advertising services, providing mailing or email  services, tax and accounting  services, data enhancement services, fraud prevention, web hosting, or similar services;</li>
      <li><strong>Businesses as needed  to provide Services </strong>or to fulfil  your request or transaction, including, for example, order fulfilment, payment  processing;</li>
      <li><strong>Ad networks and advertising partners </strong>to deliver advertising and personalized content  to you on our Service, on  other sites and services you may use, and across other devices you may use, as well as provide on behalf of  advertising-related services such as reporting, attribution, analytics and market research;</li>
      <li><strong>Analytics providers </strong>to better  understand the demographics of our users and visitors,  and to personalize, enhance and improve our Service;</li>
      <li><strong>Social networks </strong>for advertising or personalization purposes,  or to engage with you.</li>
    </ol>
  </ol>
  <p>We  may also disclose personal information to other third parties at your direction  or upon your request, or to comply  with legal process  or contractual obligations, as described in our Privacy  Policy.</p>
</div>
<br clear="all"/>
<div>
  <h2>Third-Party Tracking and Online Advertising</h2>
  <p>We utilize third-party partners to engage in online advertising. These ad networks,  social media companies and other third-party businesses  collect your personal information directly from your browser or device through cookies or similar  tracking technology when you visit or interact with our website, use our apps or otherwise engage with us  online. These third parties use your personal information to serve relevant ads on our site, on other  websites or mobile apps, or on other devices you may use, or to personalize content and perform other  advertising-related services such as reporting, attribution, analytics and market research. These third-party  businesses may use such information for their own purposes in accordance with their own privacy  statements, which may include reselling this information to additional third parties, including other advertising networks.<br/>
    Please see Third Party Data Collection and Interest  Based Advertising in our Privacy Policy for more information about how third parties  use cookies and related technologies to collect information automatically on our websites and other online services, and the  choices you may have in relation to those choices.</p><br clear="all"/>
  <h2>YOUR  CALIFORNIA CONSUMER RIGHTS</h2>
 <p>
    As a California resident, you may be able to exercise the  following rights in relation to the Personal  Information about you that we have collected (subject to certain  limitations at law):</p>
</div>
<br clear="all"/>
<div>
  <table border="1" cellspacing="0" cellpadding="0">
  <tr>
      <td width="124" valign="top"><p><strong>The     Right    to Know</strong></p></td>
      <td width="500" valign="top"><p>You have the right to request any or all of the following information relating to
your personal information we have collected and disclosed in the last 12 months, upon verification of your identity:
</p>
<ol>
   
   <li>	The specific pieces of personal information we have collected about you;</li>

   <li>	The categories of personal information we have collected about you;</li>

   <li>	The categories of sources of the personal information;</li>

   <li>	The categories of personal information that we have disclosed to third parties for a business purpose, and the categories of recipients to whom this information was disclosed;</li>

   <li>The categories of personal    information we have sold and the categories of third parties    to whom the information was sold; and</li>
   <li>The business or commercial purposes for collecting or selling the personal information.</li>

</ol>
</td>
    </tr>

    <tr>
      <td width="124" valign="top"><p><strong>The     Right    to Request Deletion</strong></p></td>
      <td width="500" valign="top"><p>You have the right to request the deletion of personal information we have collected from you, subject to certain exceptions.</p></td>
    </tr>
    <tr>
      <td width="124" valign="top"><p><strong>The Right to Opt Out of Personal Information Sales</strong></p></td>
      <td width="500" valign="top"><p>&nbsp;</p>
        <p>You have the right to direct us not to sell personal information we have collected about    you to third    parties now or in the future.</p></td>
    </tr>
    <tr>
      <td width="124" valign="top"><p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>The Right    to Non-Discriminat ion</strong></p></td>
      <td width="500" valign="top"><p>You have the right not to receive    discriminatory treatment for exercising these rights.</p>
        <p>&nbsp;</p>
        <p>However, please note that if the exercise of these    rights limits our ability to process personal    information (such as in the case of a deletion request), we may no longer be able to provide you our products and    services or engage with you in the same manner.</p></td>
    </tr>
    <tr>
      <td width="124" valign="top"><p>&nbsp;</p>
        <p><strong>&ldquo;Shine           the Light&rdquo;</strong></p></td>
      <td width="500" valign="top"><p>California residents that have an    established business relationship with us have rights to know how their information is disclosed to third    parties for their direct marketing purposes under California&rsquo;s &ldquo;Shine    the Light&rdquo; law (Civ. Code § 1798.83).</p></td>
    </tr>
  </table>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><strong>Third Party Tracking and Online Advertising</strong><br/>
    We utilize third-party partners  to collect certain  personal information directly  from your browser  or device when you visit or interact  with our websites  and other online  services for the purpose of</p>
</div>
<br clear="all"/>
<p>serving  relevant advertisements on our site, as well as on other websites or mobile  applications, or personalize content  or perform other advertising-related services such as reporting, attribution, analytics and market research. These  third-party businesses will use personal information for their own purposes in accordance with their own  privacy notice or privacy policy, which may include reselling this information to additional third parties. Please  visit the Third Party Data Collection and Interest Based Advertising section  of our Privacy Policy for more information about how third parties use cookies and related technologies to collect information automatically on our websites and other online  services, and the choices you may have in relation  to those practices.</p>
<h2>Minors  Under Age 16</h2>
<p>Our websites are not directed  to minors. We do not sell the personal information of consumers we know to be less than 16 years of age.</p>
<h2>California&rsquo;s &ldquo;Shine  the Light&rdquo; Law</h2>
<p>California's &quot;Shine the Light&quot; law (Civil Code  Section §1798.83) provides certain rights to California residents that have an established business relationship with us  with regard to the disclosure of certain types  of personal information to third parties for their direct marketing purposes.  To opt-out of having your personal  information disclosed to third parties for their direct marketing purposes,  please submit a California Consumer  Rights Request at <a href="mailto:support@Blitsestates.com">support@Blitsestates.com </a>providing your contact information to be added to our suppression list.</p>
<h2>6. ADDITIONAL INFORMATION Updates to These CA Disclosures </h2>
<p>We will update  these CA Disclosures from time to time. When we make changes to these CA Disclosures, we will change the &quot;Last  Updated&quot; date at the beginning of these Disclosures. All changes shall be effective  from the date of publication unless otherwise provided  in the notification.</p>
<h2>Contact Us</h2>
<p>If you have any questions or requests in connection with  this Notice or other privacy-related matters,  please send an email to <a href="mailto:support@Blitsestates.com">support@Blitsestates.com</a></p>

            </div>

        </div>
    </section>
    </>
  )
}

export default CaliforniaPrivacyNotice