import React, { useEffect } from "react";
import coinImg from "../../assets/images/blits-coin.png";
import { Link } from "react-router-dom";
import { useState } from "react";

const BlitsCoinOffer = () => {
  const [isOpen, setIsOpen] = useState(false);
  /* useEffect(() => {
    setTimeout(() => {
      if (
        localStorage.getItem("popup-shown") === null &&
        localStorage.getItem("popup-shown") != 1
      ) {
        setIsOpen(true);
      }
    }, 1000);
  }, []); */
  return (
    <div className={`offer-popup ${isOpen ? "open" : ""}`}>
      <div className="offer-popup-inner">
        <div
          className="closePopup"
          onClick={() => [
            setIsOpen(false),
            localStorage.setItem("popup-shown", 1),
          ]}
        ></div>
        <div className="row align-items-center g-0">
          <div className="col-lg-6">
            <div className="offer-popup-img">
              <img src={coinImg} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="offer-popup-con">
              <span>First time in crypto market</span>
              <h1 className="offer-popup-title">
                Get <b>40%</b> cashback* on Blitscoin
              </h1>
              <p>Buy today and get 40% BTS Coin cashback</p>
              <Link to={"/buytoken"} className="def-btn">
                Buy Blitscoin Now
              </Link>
              <div className="tc-apply-text">*T&C Apply</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlitsCoinOffer;
