import { useEffect, useState } from "react";
import { getBlogs } from "../../modules/blogs/actions";
import { showErrorMsg } from "../../utils/notification";
import { Link } from "react-router-dom";
import moment from "moment";
import { Spinner } from "react-bootstrap";

function BlogListingPage() {
  const [blogs, setBlogs] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAllBlogs();
  }, []);

  const getAllBlogs = () => {
    getBlogs().then(
      (res) => {
        setLoading(false);
        if (res?.statuscode === 200) {
          setBlogs(res.data);
        } else {
          showErrorMsg(res?.message);
        }
      },
      (err) => {
        setLoading(false);
        showErrorMsg(err.message);
      }
    );
  };
  return (
    <>
      <section className="blog-sec common-sec">
        <div className="container">
          <div className="sec-head text-center">
            <h1 className="sec-title">Our Blogs</h1>
          </div>
          <div className="row">
            {!loading &&
              blogs?.length > 0 &&
              blogs?.map((item, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
                  <div className="blog-box">
                    <div className="blog-box-img">
                      <Link to={`/blog/${item?.id}`}>
                        <img src={item?.image} />
                      </Link>
                      <span className="blog-box-date">
                        {moment(item?.created_at).format("DD MMM YYYY")}
                      </span>
                    </div>
                    <div className="blog-box-con">
                      <h3 className="blog-box-title">
                        <Link to={`/blog/${item?.id}`} className="ms-0">
                          {item?.title}
                        </Link>
                      </h3>
                      <Link to={`/blog/${item?.id}`} className="more-det">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            {loading && (
              <div className="text-center">
                <Spinner size={true} />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogListingPage;
