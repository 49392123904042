import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import CoinImg from "../../assets/images/chinese-coin.png";
import bed from "../../assets/images/bed.svg";
import built from "../../assets/images/built.svg";
import land from "../../assets/images/land.svg";
import garage from "../../assets/images/garb.svg";
import prop from "../../assets/images/prop.svg";
import wash from "../../assets/images/wash.svg";
import downl from "../../assets/images/down.svg";
import tic from "../../assets/images/tic.svg";
import withRouter from "../../utils/withRouter";
import { v4 as uuidv4 } from "uuid";
import { editProperty, listProperty } from "../../modules/listing/actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { useNavigate } from "react-router-dom";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MyMapComponent from "../../components/MyMapComponent/MyMapComponent";
import Spinner from "../../components/Loader";
import moment from "moment";
import { deletePropertyStorage } from "../../utils/helper";
import Web3 from "web3";
import tokenABI from "../../utils/tokenABI";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ListPropertyHeader from "./ListPropertyHeader";
import { getConversion } from "../../modules/blits/actions";

const PropertyPreview = ({ location }) => {
  let propertyDetail = location.state;

  const navigate = useNavigate();
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [conversion, setConversion] = useState("");

  let web3 = new Web3();

  const handleClose = () => setShow(false);

  const ethEnabled = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information from the MetaMask provider information
      web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.enable();

        metamaskDetails();
        return true;
      } catch (e) {
        // User denied access
        return false;
      }
    } else {
      alert("Please install MetaMask to use this service!");
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
    }

    return false;
  };

  const metamaskDetails = async () => {
    setIsLoading(true);

    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
    // const tokenAddress = "0xB2d4Cc77f233e1e9442EBd11E22D9A286f2D35C3";

    const accounts = await new web3.eth.getAccounts();
    const NameContract = new web3.eth.Contract(tokenABI, tokenAddress);
    try {
      const balance = await NameContract.methods.balanceOf(accounts[0]).call();

      let value = Web3.utils.fromWei(balance, "ether");
      let finval = Math.floor(value * 1000) / 1000;
      if (finval < 100) {
        showErrorMsg("Not enough BTS Coins");
      } else {
        await NameContract.methods
          .transfer(
            "0x92B65d6392f82c622FCB1A7c51893950f35FD9f5",
            web3.utils.toWei("100", "ether")
          )
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log("hash", hash);
          })
          .on("receipt", function (receipt) {
            publishProperty(1);
          })
          .on("error", function (error, receipt) {
            publishProperty(0);
          });
      }
    } catch (error) {
      showErrorMsg("Not enough BTS Coins");
    }
  };

  const publishProperty = (paid) => {
    setIsSubmitForm(true);
    var public_url = uuidv4();

    let data = location.state;

    var form = new URLSearchParams();

    form.append("propertyId", data.id);
    form.append("data[title]", data.title);
    form.append("data[country]", data.country);
    form.append("data[state]", data.state);
    form.append("data[city]", data.city);
    form.append("data[type]", data.type);
    form.append("data[description]", data.description);
    form.append("data[overview_1]", data.overview_1);
    form.append("data[overview_2]", data.overview_2);
    form.append("data[overview_3]", data.overview_3);
    form.append("data[overview_4]", data.overview_4);
    form.append("data[overview_5]", data.overview_5);
    form.append("data[overview_6]", data.overview_6);
    form.append("data[speciality]", data.speciality);
    form.append("data[why_invest]", data.why_invest);
    form.append("data[latitude]", data.latitude);
    form.append("data[longitude]", data.longitude);
    form.append("data[cost]", data.cost);
    form.append("data[dilution]", data.dilution);
    form.append("data[shares]", data.shares);
    form.append("data[share_value]", data.share_value);
    form.append("data[min_share]", data.min_share);
    form.append("data[max_share]", data.max_share);
    form.append("data[open_window]", data.open_window);
    form.append("data[public_url]", public_url);
    form.append("data[paid]", paid);
    form.append("data[tile_image]", data.images[0].path);
    for (var i = 0; i < data.amenities?.length; i++) {
      form.append(`amenities[${i}]`, data.amenities[i]);
    }
    for (var i = 0; i < data.docs?.length; i++) {
      form.append(`docs[${i}][path]`, data.docs[i].path);
      form.append(`docs[${i}][type]`, data.docs[i].type);
    }
    for (var j = 0; j < data.images?.length; j++) {
      form.append(`images[${j}][path]`, data.images[j].path);
      form.append(`images[${j}][type]`, data.images[j].type);
    }
    // delete data.amenitiesByName;
    if (propertyDetail.mode === "edit") {
      editProperty(form).then(
        (res) => {
          deletePropertyStorage();
          setShow(false);
          showSuccessMsg("Property edited Successfully");
          setIsSubmitForm(false);
          navigate("/my-listings");
        },
        (err) => {
          setShow(false);
          console.log(err);
          setIsSubmitForm(false);
          showErrorMsg(err.message);
        }
      );
    } else {
      listProperty(form).then(
        (res) => {
          deletePropertyStorage();
          setShow(false);
          showSuccessMsg("Property submitted Successfully");
          setIsSubmitForm(false);
          navigate("/my-listings");
        },
        (err) => {
          setShow(false);
          console.log(err);
          setIsSubmitForm(false);
          showErrorMsg(err.message);
        }
      );
    }
  };

  const render = (status) => {
    switch (status) {
      default:
        return <></>;
      case Status.LOADING:
        return <></>;
      case Status.FAILURE:
        return <></>;
      case Status.SUCCESS:
        return <MyMapComponent />;
    }
  };

  useEffect(() => {
    fetchRate();
  }, []);

  const fetchRate = () => {
    getConversion().then(
      (res) => {
        let conversion = res.data.data[0].conversion;
        setConversion(conversion);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  let buyPercent = (propertyDetail.purchased / propertyDetail.shares) * 100;
  // calculation of open window
  var currentDate = moment();
  var utc = moment.utc(currentDate, "YYYY-MM-DD HH:mm:ss");
  var local = utc.local();

  let finalDate = local.add("days", propertyDetail.open_window);
  var now = moment();
  let open_status;
  if (now < finalDate) {
    open_status = true;
  } else {
    open_status = false;
  }

  // images videos calculation
  let imgs = [];
  let vids = [];
  propertyDetail.images.map((data) => {
    if (data.type === 0) {
      imgs.push(data);
    } else if (data.type === 1) {
      vids.push(data);
    }
  });

  let diluteVal = propertyDetail.shares / propertyDetail.dilution;
  const sendRoute = () => {
    handleClose();
    navigate("/my-listings");
  };

  return (
    <>
      <ListPropertyHeader />
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>Purchase Confirmation</Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <Modal.Body>
            Please wait while we are making the payment. This might take a
            while...
          </Modal.Body>
        ) : (
          <Modal.Body>
            Do you really want to list your property at 100 BTS coins?
          </Modal.Body>
        )}
        <Modal.Footer>
          {isLoading ? (
            <Button variant="primary" disabled>
              Processing &nbsp; <Spinner size={true} />
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={(e) => sendRoute()}>
                No
              </Button>
              <Button variant="primary" onClick={ethEnabled}>
                Yes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <section className="preview-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12 det-title-wrap">
              <a href="">
                <svg
                  width="12"
                  height="23"
                  viewBox="0 0 12 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 21.9604L1 11.9632L11 1.96606"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
              <div className="det-title">
                <h1>
                  {propertyDetail.title}
                  <span>
                    {propertyDetail.type === "0" ? "Residential" : "Commercial"}
                  </span>
                </h1>
                <small>
                  {propertyDetail.city}, {propertyDetail.country}
                </small>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 search-form">
              <div className="form-control-sr">
                <input
                  type={"text"}
                  placeholder="Search with name, location or type..."
                />
                <button>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00017 14.9964C11.8663 14.9964 15.0003 11.8632 15.0003 7.99819C15.0003 4.1332 11.8663 1 8.00017 1C4.13408 1 1 4.1332 1 7.99819C1 11.8632 4.13408 14.9964 8.00017 14.9964Z"
                      stroke="#CECECE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.95 12.9473L17.0001 16.9963"
                      stroke="#CECECE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="det-banner">
        <div className="car-desc">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              {open_status ? (
                <span className="d-name position-relative">
                  Closing in: {finalDate.diff(now, "days")} days
                </span>
              ) : (
                <span className="r-name position-relative">Closing soon</span>
              )}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 sp-dc">
              <span className="post-dte">
                {imgs.length} images,{vids.length} videos
              </span>
            </div>
          </div>
        </div>
        <Carousel>
          {propertyDetail.images &&
            propertyDetail.images.length > 0 &&
            propertyDetail.images.map((data, index) => (
              <Carousel.Item key={index}>
                {data.type === 0 ? (
                  <img
                    className="d-block w-100 h-85 object-fit-cover"
                    src={data.path}
                    alt="First slide"
                  />
                ) : (
                  <video
                    width="400"
                    controls
                    autoPlay
                    className="d-block w-100 h-85 object-fit-cover"
                  >
                    <source src={data.path} type="video/mp4" />
                    <source src={data.path} type="video/ogg" />
                    Your browser does not support HTML video.
                  </video>
                )}
              </Carousel.Item>
            ))}
        </Carousel>
      </section>

      <section className="det-prop-ow">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-12 b-coin">
              <img src={CoinImg} alt="" />${propertyDetail.cost} ~
              <span>${propertyDetail.cost}</span>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 b-dets">
              <div className="ownr-name">
                <div className="own-stat">S</div>
                <div>
                  <small>Owner/Builder</small>
                  <span className="ow-nme">Sherlet Johnson</span>
                </div>
              </div>

              <div className="sub-status">
                <small>Launched on</small>
                <p>1st September 2022</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details-of-property">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-sm-12 det-left">
              <h2>Property overview</h2>
              <div className="col-12">
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={bed} />
                        {propertyDetail.type === "0" ? "Bed rooms" : "Floors"}
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_1}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={wash} />
                        {propertyDetail.type === "0"
                          ? "Wash room"
                          : "Elevators"}
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_2}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={built} />
                        Built area
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_3}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={land} />
                        Land area
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_4}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={garage} />
                        {propertyDetail.type === "0"
                          ? "Garage Capacity"
                          : "Total Parking"}
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_5}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={prop} />
                        Property type
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_6 === "0"
                          ? "Nuclear Family"
                          : propertyDetail.overview_6 === "1"
                          ? "Extended Family"
                          : propertyDetail.overview_6 === "2"
                          ? "Office space"
                          : propertyDetail.overview_6 === "3"
                          ? "Industrial use"
                          : propertyDetail.overview_6 === "4"
                          ? "Multi-family rental"
                          : propertyDetail.overview_6 === "5"
                          ? "Retail Spaces"
                          : "NA"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4 ab-proprety mb-4">
                <h2>About property</h2>
                <p>{propertyDetail.description}</p>
              </div>

              {propertyDetail.amenitiesByName.length > 0 && (
                <div className="col-12 mt-4">
                  <div className="ab-amnty">
                    <table width="100%">
                      <tbody>
                        <th>
                          <td>
                            <h2>Amenities</h2>
                          </td>
                        </th>
                        {propertyDetail.amenitiesByName.length > 0 &&
                          propertyDetail.amenitiesByName.map((data, index) => (
                            <tr key={index}>
                              <td>{data.name}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="col-12 mt-4 ab-proprety">
                <h2>Speciality of the property</h2>
                <p>{propertyDetail.speciality}</p>
              </div>

              {propertyDetail.docs && propertyDetail.docs.length > 0 && (
                <div className="col-12 mt-2 ex-proprety ex-legal">
                  <h2>Property documents/ Legal documents</h2>
                  <table width="80%">
                    {propertyDetail.docs.length > 0 &&
                      propertyDetail.docs.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <img src={tic} alt="" />
                            Document
                          </td>
                          <td>
                            <a href={data.path}>
                              Download <img src={downl} alt="" />{" "}
                            </a>
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
              )}

              <div className="col-12 mt-4 ab-invest">
                <h2>
                  Why should you invest?{" "}
                  <span className="ml-2">How to invest?</span>
                </h2>
                <div>{propertyDetail.why_invest}</div>
              </div>

              <div className="col-12 mt-4 ab-invest">
                <h2>Location and nearby</h2>
                <Wrapper
                  apiKey={process.env.REACT_APP_MAPS_KEY}
                  render={render}
                >
                  <MyMapComponent
                    center={{
                      lat: parseInt(propertyDetail?.latitude),
                      lng: parseInt(propertyDetail?.longitude),
                    }}
                    zoom={12}
                    isMarkerShown
                  />
                </Wrapper>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12 det-right">
              <div className="col-12 mt-4 ab-invest ow-adrs">
                <h2>Investment details</h2>
                <table width="80%">
                  <tr>
                    <td>Available shares</td>
                    <td>
                      : {propertyDetail.shares}
                      Shares
                    </td>
                  </tr>
                  <tr>
                    <td>Value of 1 share</td>
                    <td>
                      : ${propertyDetail.share_value} (
                      {propertyDetail.share_value * conversion} Blits Coin)
                    </td>
                  </tr>
                  <tr>
                    <td>Total dilution</td>
                    <td>
                      : {propertyDetail.shares * propertyDetail.share_value} (
                      {propertyDetail.dilution}%) / {propertyDetail.shares}{" "}
                      shares
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="col-12 sub-btns px-5 pb-5">
        {!isSubmitForm && (
          <button
            type="button"
            className="back-btn"
            onClick={() => navigate(-1)}
          >
            Go back
          </button>
        )}
        {propertyDetail.mode === "edit" ? (
          <button
            type="submit"
            className="def-btn"
            onClick={(e) => publishProperty(1)}
            // disabled={() => publishProperty(1)}
          >
            {isSubmitForm && <Spinner size={true} />}
            {!isSubmitForm && <>Publish</>}
          </button>
        ) : (
          <button
            type="submit"
            className="def-btn"
            onClick={(e) => setShow(true)}
            disabled={isSubmitForm}
          >
            {isSubmitForm && <Spinner size={true} />}
            {!isSubmitForm && <>Publish</>}
          </button>
        )}
      </div>
    </>
  );
};
export default withRouter(PropertyPreview);
