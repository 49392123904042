// Node modules
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";

// Importing Routes
import "./App.css";
import "./assets/css/root.scss";
import Header from "./components/Header/Header";
import ListingPage from "./views/ListingPage/ListingPage";
import DetailPage from "./views/DetailPage/DetailPage";
import Footer from "./components/Footer/Footer";
import HomePage from "./views/HomePage/HomePage";
import BuyBlits from "./views/BuyBlitsPage/BuyBlitsPage";
import LoginPage from "./views/LoginPage/LoginPage";
import RegisterPage from "./views/RegisterPage/RegisterPage";
import NotFoundPage from "./views/NotFoundPage/NotFoundPage";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import SetNewPassword from "./views/SetNewPassword/SetNewPassword";
import AddPropertyDetails from "./views/ListProperty/AddPropertyDetails";
import AddPropertyFeatures from "./views/ListProperty/AddPropertyFeatures";
import AddPropertyCosting from "./views/ListProperty/AddProperyCosting";
import ResetLinkSuccess from "./views/ResetLinkSuccess/ResetLinkSuccess";
import Stripe from "./views/Stripe/Stripe";
import Privacy from "./views/Privacy/Privacy";
import Terms from "./views/Terms/Terms";
import MyProfilePage from "./views/MyProfilePage/MyProfilePage";
import MyBlitsCoin from "./views/MyBlitsCoin/MyBlitsCoin";
import MyInvestments from "./views/MyInvestments/MyInvestments";
import MyListings from "./views/MyListings/MyListings";
import PropertyPreview from "./views/ListProperty/PropertyPreview";
import CaliforniaPrivacyNotice from "./views/Terms/CaliforniaPrivacyNotice";
import PrivacyNotice from "./views/Terms/PrivacyNotice";
import InquiryAgentPage from "./views/InquiryPage/InquiryAgentPage";
import InquirySellerPage from "./views/InquiryPage/InquirySellerPage";
import InquiryBuyerPage from "./views/InquiryPage/InquiryBuyerPage";
import InquiryThankYou from "./views/InquiryPage/InquiryThankYou";
import BlogListingPage from "./views/BlogPage";
import BlogDetailsPage from "./views/BlogPage/details";
import BlitsCoinOffer from "./views/BlitsCoinOffer";

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Header />
        <BlitsCoinOffer />
        <Routes>
          {/* Homepage */}
          <Route path="/" element={<HomePage />} />

          {/* Blogpage */}
          <Route path="/blog" element={<BlogListingPage />} />
          <Route path="/blog/:id" element={<BlogDetailsPage />} />

          {/* Inquiry */}
          <Route path="/inquiry/agent" element={<InquiryAgentPage />} />
          <Route path="/inquiry/seller" element={<InquirySellerPage />} />
          <Route path="/inquiry/buyer" element={<InquiryBuyerPage />} />
          <Route path="/inquiry/thank-you" element={<InquiryThankYou />} />

          {/* property listing page */}
          <Route path="/property" element={<ListingPage />} />
          <Route path="/detail-page/:url" element={<DetailPage />} />

          {/* add property page */}
          <Route path="/add-property" element={<AddPropertyDetails />} />
          <Route path="/add-features" element={<AddPropertyFeatures />} />
          <Route path="/add-costing" element={<AddPropertyCosting />} />
          <Route path="/property-preview" element={<PropertyPreview />} />

          {/* tokenomics */}
          <Route path="/buytoken" element={<BuyBlits />} />

          {/* auth routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/reset-password-success"
            element={<ResetLinkSuccess />}
          />
          <Route path="/set-password" element={<SetNewPassword />} />
          <Route path="/stripe" element={<Stripe />} />
          {/* <Route path="/stripe/:usd_amount/:type/:blits_count" element={<Stripe />} /> */}

          {/* Profile internal pages */}
          <Route path="/my-profile" element={<MyProfilePage />} />
          <Route path="/my-blitscoin" element={<MyBlitsCoin />} />
          <Route path="/my-investments" element={<MyInvestments />} />
          <Route path="/my-listings" element={<MyListings />} />

          {/* payment page */}
          {/* <Route path="/stripe" element={<Stripe />} /> */}

          {/* 404 route */}
          <Route path="*" element={<Navigate to="/" replace />} />

          {/* Side Pages */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route
            path="/california-terms"
            element={<CaliforniaPrivacyNotice />}
          />
          <Route path="/privacy-notice" element={<PrivacyNotice />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
