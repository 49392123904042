import { useEffect, useRef, useState } from "react";
import { getAmenities } from "../../modules/listing/actions";
import ListPropertyHeader from "./ListPropertyHeader";
import { useNavigate } from "react-router-dom";
import {
  getLocalStorage,
  getPropertyStorage,
  setPropertyStorage,
} from "../../utils/helper";

import { showErrorMsg } from "../../utils/notification";
import Spinner from "../../components/Loader";
import withRouter from "../../utils/withRouter";

function AddPropertyFeatures({ location }) {
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [amenitylist, setAmenitylist] = useState([]);
  const [isFetchingAmenities, setisFetchingAmenities] = useState(true);
  // for naming

  const [amenitiesByName, setAmenityByName] = useState([]);
  // const [localData, setLocalData] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [mode, setMode] = useState("add");
  const navigate = useNavigate();

  // form states
  const [allValues, setAllValues] = useState({});

  // for setting form values
  const changeHandler = (e) => {
    setAllValues((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    editCheck();
  }, [isFetchingAmenities]);

  // console.log(isFetchingAmenities);
  const editCheck = () => {
    if (!isFetchingAmenities) {
      if (location?.state?.mode === "edit") {
        let editData = location.state;
        setMode("edit");
        setAllValues(editData);
        // for adding check values
        let a = [];
        let b = [];
        for (let j = 0; j < editData.amenities.length; j++) {
          for (let i = 0; i < amenitiesList.length; i++) {
            if (editData.amenities[j].amenity_name === amenitiesList[i].name) {
              a.push(...amenitylist, amenitiesList[i].id);
              b.push(...amenitiesByName, amenitiesList[i]);
              setAmenitylist(a);
              setAmenityByName(b);
            }
          }
        }
      }
    }
  };

  // setting previous page values
  useEffect(() => {
    setAllValues(location.state);
  }, []);

  // hitting on load for user data check and amenitites fetching
  useEffect(() => {
    checkUserData();
    fetchAmenities();
  }, []);

  // for fetching all amenities
  const fetchAmenities = () => {
    setisFetchingAmenities(true);
    getAmenities().then(
      (res) => {
        let list = res.data.data;
        setAmenitiesList(list);
        setisFetchingAmenities(false);
      },
      (err) => {
        setisFetchingAmenities(false);
        console.log(err);
      }
    );
  };

  // // for checkbox of amenities
  const storeAmenities = (data) => {
    let a = [];
    let b = [];
    if (!amenitylist.includes(data.id)) {
      a.push(...amenitylist, data.id);
      b.push(...amenitiesByName, data);
      setAmenitylist(a);
      setAmenityByName(b);
    } else {
      let d = amenitylist;
      let updated = d.filter((item) => item !== data.id);
      setAmenitylist(updated);
      setAmenityByName(updated);
    }
  };

  // for checking auth
  const checkUserData = () => {
    const storage = getLocalStorage();
    if (!storage) {
      navigate("/signup");
    }
  };

  const setPageBack = (e) => {
    let data = getPropertyStorage();
    let finData = JSON.parse(data);
    finData.pageComplete = 2;
    setPropertyStorage(finData);
    navigate(-1);
  };
  // const type = 0;

  const formSubmit = (e) => {
    e.preventDefault();
    var params = allValues;
    params.mode = mode;
    params.pageComplete = 2;
    params.amenities = amenitylist;
    params.amenitiesByName = amenitiesByName;
    if (params.overview_1 === undefined || params.overview_1 === "") {
      if (params.type === "1") {
        showErrorMsg("Please enter Total Bedrooms");
      } else {
        showErrorMsg("Please enter Total Floors");
      }
    } else if (params.overview_2 === undefined || params.overview_2 === "") {
      if (params.type === "1") {
        showErrorMsg("Please enter Total Washrooms");
      } else {
        showErrorMsg("Please enter Total Elevators");
      }
    } else if (params.overview_3 === undefined || params.overview_3 === "") {
      showErrorMsg("Please enter Total Build area");
    } else if (params.overview_4 === undefined || params.overview_4 === "") {
      showErrorMsg("Please enter Total Land area");
    } else if (params.overview_5 === undefined || params.overview_5 === "") {
      showErrorMsg("Please enter Garage Capacity");
    } else if (
      params.overview_6 === undefined ||
      params.overview_6 === null ||
      params.overview_6 === ""
    ) {
      showErrorMsg("Please enter Type of living");
    } else if (params.speciality === undefined || params.speciality === "") {
      showErrorMsg("Please enter Speciality");
    } else if (params.why_invest === undefined || params.why_invest === "") {
      showErrorMsg("Please enter Investment reason");
    } else {
      setPropertyStorage(params);
      navigate("/add-costing", {
        state: params,
      });
    }
  };
  return (
    <>
      <ListPropertyHeader />
      {isLoading && (
        <div className="spinner-padding">
          <Spinner size={false} />
          <p>Loading....</p>
        </div>
      )}

      {!isLoading && (
        <section className="listproperty_wrap">
          <div className="container">
            {isFetchingAmenities ? (
              "Loading..."
            ) : (
              <div className="row">
                <div className="col-xl-9 col-lg-9 col-md-12 listproperty_left">
                  <h4>Add property overview</h4>
                  <form onSubmit={formSubmit}>
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 mb-3 listproperty_left_label_secondpage">
                        <label>
                          No of {allValues.type === "0" ? "bedrooms" : "floors"}
                          <span>*</span>
                        </label>
                        <span className="inp-wrap">
                          <input
                            name="overview_1"
                            type="text"
                            placeholder="0"
                            value={allValues.overview_1}
                            onChange={changeHandler}
                          />
                          <small>
                            {allValues.type === "0" ? "Bed rooms" : "Floors"}
                          </small>
                        </span>
                      </div>

                      <div className="col-xl-4 col-lg-4 mb-3 listproperty_left_label_secondpage">
                        <label>
                          No of{" "}
                          {allValues.type === "0" ? "washrooms" : "elevators"}
                          <span>*</span>
                        </label>
                        <span className="inp-wrap">
                          <input
                            name="overview_2"
                            type="text"
                            placeholder="0"
                            value={allValues.overview_2}
                            onChange={changeHandler}
                          />
                          <small>
                            {allValues.type === "0"
                              ? "Wash rooms"
                              : "Elevators"}
                          </small>
                        </span>
                      </div>

                      <div className="col-xl-4 col-lg-4 mb-3 listproperty_left_label_secondpage">
                        <label>
                          Build area<span>*</span>
                        </label>
                        <span className="inp-wrap">
                          <input
                            name="overview_3"
                            type="text"
                            placeholder="0"
                            value={allValues.overview_3}
                            onChange={changeHandler}
                          />
                          <small>SqFt</small>
                        </span>
                      </div>

                      <div className="col-xl-4 col-lg-4 mb-3 listproperty_left_label_secondpage">
                        <label>
                          Total land area<span>*</span>
                        </label>
                        <span className="inp-wrap">
                          <input
                            name="overview_4"
                            type="text"
                            placeholder="0"
                            value={allValues.overview_4}
                            onChange={changeHandler}
                          />
                          <small>Sqft</small>
                        </span>
                      </div>

                      <div className="col-xl-4 col-lg-4 mb-3 listproperty_left_label_secondpage">
                        <label>
                          {allValues.type === "0"
                            ? "Garage Capacity"
                            : "Total Parking"}
                          <span>*</span>
                        </label>
                        <span className="inp-wrap">
                          <select
                            onChange={(e) =>
                              setAllValues((prev) => ({
                                ...prev,
                                overview_5: e.target.value,
                              }))
                            }
                            value={allValues.overview_5}
                          >
                            <option disabled>Choose no. of cars</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="10+">More than 10</option>
                          </select>
                        </span>
                      </div>

                      <div className="col-xl-4 col-lg-4 mb-3 listproperty_left_label_secondpage">
                        <label>
                          {allValues.type === "0"
                            ? "Type of living"
                            : "Property Type"}{" "}
                          <span>*</span>
                        </label>
                        <span className="inp-wrap">
                          <select
                            onChange={(e) =>
                              setAllValues((prev) => ({
                                ...prev,
                                overview_6: parseInt(e.target.value),
                              }))
                            }
                            value={allValues.overview_6}
                          >
                            {allValues.type === "0" ? (
                              <>
                                <option value="">Select a family</option>
                                <option value="0">Nuclear Family</option>
                                <option value="1">Extended Family</option>
                              </>
                            ) : (
                              <>
                                {" "}
                                <option value="">Select a type</option>
                                <option value="2">Office space</option>
                                <option value="3">Industrial use</option>
                                <option value="4">Multi-family rental</option>
                                <option value="5">Retail Spaces</option>
                              </>
                            )}
                          </select>
                        </span>
                      </div>
                    </div>
                    <div className="col-12 listproperty__media mb-4 mt-3">
                      <h4>Choose amenities</h4>
                      <div className="col-12 listproperty__amneties">
                        {!isFetchingAmenities &&
                          amenitiesList.map((item, index) => (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={amenitylist.includes(item.id)}
                                value={`check_${item.id}`}
                                id={`check_${item.id}`}
                                onChange={(e) => storeAmenities(item)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`check_${item.id}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="col-12 listproperty__media mb-4 mt-3">
                      <h4>Other highlights</h4>

                      <div className="row">
                        <div className="col-xl-3 mb-3 listproperty_left_label align-items-start">
                          <label>
                            Speciality of property<span>*</span>
                          </label>
                        </div>
                        <div className="col-xl-9 mb-3 listproperty_left_input">
                          <input
                            onChange={changeHandler}
                            value={allValues.speciality}
                            as="textarea"
                            name="speciality"
                            placeholder="If any -  Historical Importance / Geographical importance / vaastu / VIP Past owners. It means a field of importance that can be added by owner that will separate it from other properties and add value to the demand ..."
                          />
                        </div>

                        <div className="col-xl-3 mb-3 listproperty_left_label align-items-center">
                          <label>
                            Why should you invest<span>*</span>
                          </label>
                        </div>
                        <div className="col-xl-9 mb-3 listproperty_left_input add_property">
                          <input
                            onChange={changeHandler}
                            value={allValues.why_invest}
                            name="why_invest"
                            type="text"
                            placeholder="Add 1st point here as bullet point"
                          />
                        </div>
                      </div>
                    </div>

                    <p className="note">
                      Note: You can come back later and change the details
                    </p>

                    <div className="col-12 sub-btns">
                      <button
                        type="button"
                        className="back-btn"
                        onClick={(e) => setPageBack(e)}
                      >
                        Go back
                      </button>
                      <button type="submit" className="def-btn">
                        Next
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 listproperty_right ">
                  <div className="listproperty_right_in">
                    <span>2</span>
                    <h3>Let your listing stand out </h3>
                    <p>
                      This is the step where you add the basic but the top
                      important details of the property. There’s no optional
                      fileds and you need add the details in a very clear manner
                      in order to get attestion from the other properties listed
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default withRouter(AddPropertyFeatures);
