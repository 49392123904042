import React from "react";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Listing from "../../components/PropertyCard/PropertyCard";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import withRouter from "../../utils/withRouter";

function RecentListing(props) {
  let item = props.item;
  let loader = props.isFetchingSideProperty;
  return (
    <div>
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={30}
        slidesPerView={4}
        navigation
        pagination={{ clickable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          "@1.00": {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          "@1.50": {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
      >
        {loader && (
          <div className="d-flex">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4 mx-3">
              <div className="single-l-in" style={{ height: "90vh" }}></div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4 mx-3">
              <div className="single-l-in" style={{ height: "90vh" }}></div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4 mx-3">
              <div className="single-l-in" style={{ height: "90vh" }}></div>
            </div>
          </div>
        )}
        {!loader &&
          item.map((data, index) => (
            <SwiperSlide key={index}>
              <PropertyCard item={data} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default withRouter(RecentListing);
