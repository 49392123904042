import React, { useEffect, useRef, useState } from "react";
import ethImg from "../../assets/images/eth.png";
import blitsImg from "../../assets/images/blits.png";
import swapImg from "../../assets/images/filter-s.svg";
import toolTip from "../../assets/images/tooltip.svg";
import tockmImg from "../../assets/images/tockm.png";
import homeImg from "../../assets/images/home-bg.svg";
import tot_1Img from "../../assets/images/tot-1.svg";
import tot_2Img from "../../assets/images/tot-2.svg";
import tot_3Img from "../../assets/images/tot-3.svg";
import tot_4Img from "../../assets/images/tot-4.svg";
import tot_5Img from "../../assets/images/tot-5.svg";
import safetyImg from "../../assets/images/safety.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getLocalStorage, getToken } from "../../utils/helper";
import { getCoins, getConversion } from "../../modules/blits/actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import Web3 from "web3";
import withRouter from "../../utils/withRouter";
import { updateProfile } from "../../modules/auth/actions";
import Spinner from "../../components/Loader";

const BuyBlits = (props) => {
  let preData = props.location.state;
  // console.log("preData", preData);
  const [amount, setAmount] = useState(0);
  const [coinCount, setCoinCount] = useState(0);
  const [conversion, setConversion] = useState("");
  const [transactionfee, setTransactionfee] = useState(1);
  const [transactionamount, setTransactionamount] = useState(1);
  // web3 states
  const [web3Enabled, setWeb3Enabled] = useState(false);

  const [accounts, setAccounts] = useState([]);

  const [balance, setBalance] = useState([]);

  const [soldCoins, setSoldCoins] = useState({});
  const [isLoading, setisLoading] = useState(false);

  let web3 = new Web3();

  if (props.amount && props.amount !== "") {
    setAmount(props.amount);
  }

  const navigate = useNavigate();

  let params = { amount, coinCount, transactionamount };
  const topSection = useRef(null);
  const whyBlits = useRef(null);

  const scrollTop = () => {
    window.scrollTo({
      top: topSection.current["offsetTop"],
      behavior: "smooth",
    });
  };

  useEffect(() => {
    checkUserData();
    getCoinData();
  }, []);

  const getCoinData = () => {
    getCoins().then((res) => {
      setSoldCoins(res.data.data);
    });
  };

  useEffect(() => {
    if (preData?.scrollRef) {
      whyBlits.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  });

  const checkUserData = () => {
    const storage = getLocalStorage();
    if (!storage) {
      navigate("/signup");
    }
  };

  useEffect(() => {
    scrollTop();
    ethEnabled();
    fetchRate();
  }, []);

  useEffect(() => {
    setValues();
  }, [preData, conversion]);

  const setValues = async () => {
    if (preData?.coinAmt !== undefined) {
      setCoinCount(preData.coinAmt);
      setAmount(preData.coinAmt * conversion);
      setTransactionamount((preData.coinAmt * transactionfee) / 100);
    } else {
      setCoinCount(0);
      setAmount(0);
    }
  };

  const fetchRate = () => {
    getConversion().then(
      (res) => {
        let conversion = res.data.data[0].conversion;
        setConversion(conversion);
      },
      (err) => {
        // console.log(err);
      }
    );
  };

  const getUSDConversion = (event) => {
    let value = event.target.value;
    let finalValue = value.replace(/^0+/, "");
    setTransactionamount((finalValue * transactionfee) / 100);
    setAmount(finalValue);
    setCoinCount(finalValue);
    let usd = finalValue * conversion;
    setAmount(usd);
  };
  const getBTSCoin = (event) => {
    let value = event.target.value;
    let finalValue = value.replace(/^0+/, "");
    setTransactionamount((finalValue * transactionfee) / 100);
    setAmount(finalValue);
    setCoinCount(finalValue);
    let coins = finalValue * conversion;
    setCoinCount(coins);
  };

  const handleSubmit = (event) => {
    if (amount === 0 || coinCount === 0) {
      showErrorMsg("Please enter USD amount or Number of Coin!");
    } else {
      setisLoading(true);
      var data = {
        wallet_address: accounts[0],
      };
      updateProfile(data).then(
        (res) => {
          navigate("/stripe", { state: params });
          setisLoading(false);
        },
        (err) => {
          showErrorMsg(err.message);
          setisLoading(false);
        }
      );
    }
  };

  const ethEnabled = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information from the MetaMask provider information
      web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.enable();

        metamaskDetails();
        return true;
      } catch (e) {
        // User denied access
        return false;
      }
    } else {
      alert("Please install MetaMask to use this service!");
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
    }

    return false;
  };

  const metamaskDetails = async () => {
    setWeb3Enabled(true);
    var accs = await web3.eth.getAccounts();
    const newAccounts = await Promise.all(
      accs.map(async (address) => {
        const balance = await web3.eth.getBalance(address);
        let value = Web3.utils.fromWei(balance, "ether");
        setBalance(Math.floor(value * 1000) / 1000);
      })
    );
    setAccounts(accs);
  };

  return (
    <>
      <section className="blits-banner" ref={topSection}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 ba-text">
              <div>
                <h1>
                  Buy Blits coin and
                  <br /> invest it on DAO
                </h1>
                <p>
                  You can buy Blits coin using fiat, we’re limited currently to
                  USD
                </p>
                <div className="icon-wrap">
                  <div className="change-coin">
                    <div>
                      <strong>
                        <input
                          type="number"
                          name="coin"
                          value={coinCount}
                          onChange={getUSDConversion}
                          className="conversion_fields"
                        />
                      </strong>
                      {/*  <small>Gas fee: 12.3%</small> */}
                    </div>
                    <span className="et-c">
                      <img src={blitsImg} alt="blitsImage" />
                      BTS
                    </span>
                  </div>

                  <button className="swap-bt">
                    <img src={swapImg} alt="swapImg" />
                  </button>

                  <div className="change-coin">
                    <div>
                      <strong>
                        <input
                          type="number"
                          name="amount"
                          value={amount}
                          onChange={getBTSCoin}
                          className="conversion_fields"
                        />
                      </strong>
                      {amount !== 0 && amount !== "" ? (
                        <small> Transaction fee ${transactionamount}</small>
                      ) : null}
                    </div>
                    <span className="et-c">
                      <img src={ethImg} alt="ethImg" />
                      USD
                    </span>
                  </div>
                </div>
                <div className="change-coin-bg">
                  <strong>
                    <img src={toolTip} alt="tooltip" />1 BTS = 0.0215 ETH ~
                    $165.3
                  </strong>

                  <div>
                    <small>Transaction fee</small>
                    <span>
                      {transactionfee}%{" "}
                      {amount !== 0 && amount !== "" ? (
                        <>or ${transactionamount}</>
                      ) : null}
                    </span>
                  </div>
                </div>
                {accounts.length === 0 ? (
                  <button type="button" className="def-btn">
                    Connect Metamask
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="def-btn"
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner size={true} />}
                    {!isLoading && <>Buy now</>}
                  </button>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 ba-tocken">
              <div className="ba-tocken-in">
                <img src={tockmImg} alt="" />
                <h4>Things at a glance</h4>
                <p>
                  Blits Coin(BTS) is trending over the marketplace and provides
                  higher returns. Hare are some statistics before you invest
                </p>
                <div className="ban-gla">
                  <div className="row mb-4">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ic-t">
                      <small>Initial circulation</small>
                      3.4M
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ic-t">
                      <small>Total supply</small>
                      40%
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ic-t">
                      <small>No of token sold</small>
                      {soldCoins.coins_sold}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ic-t">
                      <small>Token value</small>
                      $1
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="how-works">
        <div className="container">
          <div className="row">
            <h2>How to buy Blits coin</h2>
            <div className="col-xl-10 mx-auto page-blts">
              <div className="row">
                <div className="col-xl-3 col-lg-3 buy-bl">
                  <span>1</span>
                  <h4>Install MetaMask Wallets</h4>
                  <p>
                    Download the MetaMask Chrome Extension. Once installed you
                    will see the MetaMask homepage and you can get started.{" "}
                  </p>
                </div>
                <div className="col-xl-3 col-lg-3 buy-bl">
                  <span>2</span>
                  <h4>Connect Wallet</h4>
                  <p>
                    Follow the installation procedure. Create a password for
                    your wallet. We recommend you use a strong password of at
                    least 8 characters long.{" "}
                  </p>
                </div>
                <div className="col-xl-6 col-lg-6"></div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6"></div>
                <div className="col-xl-3 col-lg-3 buy-bl left-1">
                  <span>3</span>
                  <h4>Buy Blits Coin</h4>
                  <p>
                    You only need to connect the wallet address specified on our
                    website to purchase Blits Coin and pay via USD.{" "}
                  </p>
                </div>
                <div className="col-xl-3 col-lg-3 buy-bl">
                  <span>4</span>
                  <h4>Start investing</h4>
                  <p>
                    After you purchase Blits Coin, you can buy any Property
                    shares over the DAO Marketplace.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 text-center mt-5 blists-text" ref={whyBlits}>
              <h2>Why Blits coin?</h2>

              <p>
                Blits real estate is similar to traditional real estate
                investing but with a layer of blockchain, allowing investors to
                participate in real estate through digital payments and
                participate in real estate through Blits Coin(BTS). Blits Coin
                has made it easier for property builders and owners to obtain
                funds for their projects with a transparent model to get quick
                returns on their investments.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="ftr-bg">
        <img src={homeImg} className="ftr-img" alt="" />
        <div className="insider">
          <div className="container">
            <div className="row">
              <div className="tocken-part">
                <h3>Tokonomics</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s
                </p>
                <div className="row justify-content-center">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tc-q">
                    <img src={tot_1Img} alt="" />
                    <small>Total sale quantity</small>
                    {soldCoins.total_coins}
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tc-q">
                    <img src={tot_2Img} alt="" />
                    <small>Token price</small>1
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tc-q">
                    <img src={tot_3Img} alt="" />
                    <small>Token sale status</small>
                    ON
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tc-q">
                    <img src={tot_4Img} alt="" />
                    <small>Tokens sold</small>
                    {soldCoins.coins_sold}
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tc-q">
                    <img src={tot_5Img} alt="" />
                    <small>Unsold tokens</small>
                    {soldCoins.coinBalance}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sfe-trip">
        <div className="container">
          <div className="col-xl-9 p-0 mx-auto">
            <div className="row">
              <div className="col-xl-5 col-lg-4 col-md-6 col-sm-12 sf-img">
                <img src={safetyImg} alt="" />
              </div>
              <div className="col-xl-7 col-lg-8 col-md-6 col-sm-12 sf-text">
                <h3>Safety Tips & Important Disclaimer</h3>
                <p>
                  Never share your Metamask wallet private key or account with
                  anyone, ever. Please visit our Terms & Condition page for
                  safety tips before you get started. Blockchain transactions
                  are permanent and irreversible. Our team cannot retrieve lost
                  or stolen assets & BlitsEstates.com does not assume any
                  responsibility or liability, we offer help but do not make
                  guarantees. All markets and exchanges operate independently
                  and with high volatility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default withRouter(BuyBlits);