import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import withRouter from "../../utils/withRouter";

//test
//const stripePromise = loadStripe(
//  "pk_test_51HCSmCAQgwEW0kk8TN4QTC4HEfw1QQM2Cdd5VU6TcOc3FbEufjlk5J7qH0mBaX56HjGsv9RZsakG6vx0sJKaIy4s00FSGHILEW"
//);
const stripePromise = loadStripe("pk_live_51MHSpkFrgXVIlWcYCwO9z5xvZL4kTkiWo08NZJgcdmDIWoEZCIUuwYg18YNSY8hc71Z8xiqitdjZdSX0K8GXCN9500G6ZWGTLq");

const successMessage = () => {
  return (
    <div className="success-msg s-box align-items-center d-flex justify-content-center">
      {/* <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        className="bi bi-check2"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
        />
      </svg> */}
      <h2 className="title">
        Your Payment has been made successfully. We are redirecting...
      </h2>
    </div>
  );
};

function Stripe(props) {
  const navigate = useNavigate();
  let usd_amount = props.location.state.amount;
  let transactionfee = props.location.state.transactionamount;
  let blits_count = props.location.state.coinCount;
  let type = "coin";
  const [details, setDetails] = useState([]);

  useEffect(() => {
    // componentDidMount();
  }, []);

  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const stripeAmount = parseFloat(usd_amount) + transactionfee;
  const stripePlan = type;
  const blitsCount = blits_count;

  const cart = () => {
    return (
      <React.Fragment>
        <h1>
          <div onClick={() => navigate(-1)}></div>&nbsp;Review your purchase
        </h1>
        <table width="100%;" className="border-0 str-tble">
          <tbody>
            <tr>
              <td className="rwn-amnt">
                Amount
                <br />
              </td>
              <td className="rwn-amnt">${parseFloat(usd_amount)}</td>
            </tr>
            <tr>
              <td className="rwn-amnt">
                Transaction Fees
                <br />
              </td>
              <td className="rwn-amnt">${transactionfee}</td>
            </tr>
            <tr>
              <td>
                <hr className="border-bottom-pay" />
              </td>
              <td>
                <hr className="border-bottom-pay" />
              </td>
            </tr>
            <tr>
              <td>
                <b>Amount to pay</b>
              </td>
              <td>
                <b>${stripeAmount}</b>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="urn-ftr">
          <p>
            Powered by Stripe
            <img />
          </p>
        </div>
      </React.Fragment>
    );
  };

  return stripeAmount > 0 ? (
    <div className="container no-footer">
      <div className="layout urm-chkout-wrap">
        <div className="urm-chkout-wrap-in">
          <div className="row">
            {paymentCompleted ? (
              <>
                <div className="col-sm-6 col-12 urn-pay-dets"></div>
                <div className="col-sm-6 col-12 urn-pay-screen">
                  {successMessage()}
                </div>
              </>
            ) : (
              <React.Fragment>
                <div className="col-sm-6 col-12 urn-pay-dets">{cart()}</div>
                <div className="col-sm-6 col-12 urn-pay-screen">
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      amount={stripeAmount}
                      plan={stripePlan}
                      productid={stripeAmount}
                      blitscount={blitsCount}
                      setPaymentCompleted={setPaymentCompleted}
                    />
                  </Elements>
                  <div id="urn-ftr">
                    By confirming your subscription, you allow Blits Estates, Corp to
                    charge your card for this payment and future payments in
                    accordance with the terms. You can always cancel your
                    subscription
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    "<Loader />"
  );
}

export default withRouter(Stripe);