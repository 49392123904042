import { Formik, Form, Field, ErrorMessage } from "formik";
import Spinner from "../../components/Loader";
import * as Yup from "yup";
import withRouter from "../../utils/withRouter";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { updatePassword } from "../../modules/auth/actions";
import { useNavigate } from "react-router-dom";
import OpenEye from "../../assets/images/eye-solid.svg";
import CloseEye from "../../assets/images/eye-slash-solid.svg";
import { useState } from "react";

function SetNewPassword(props) {
  const navigate = useNavigate();

  const [viewpassword, setViewPassword] = useState(false);
  let uToken = props.location.search;
  let token = uToken.substring(7);

  const resetValuesSchema = () => {
    return {
      password: "",
    };
  };

  const resetSchema = Yup.object().shape({
    password: Yup.string()
      .min(
        8,
        "Please enter a password containing at least 1 upper case, 1 lower case, 1 numeric, and 1 special character."
      )
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
      )
      .required("Please enter a valid password"),
  });

  const resetPassword = (values, { setSubmitting, resetForm }) => {
    var params = {
      token: token,
      password: values.password,
    };
    updatePassword(params).then(
      (res) => {
        showSuccessMsg(res.message);
        setSubmitting(false);
        navigate("/login");
      },
      (err) => {
        setSubmitting(false);
        showErrorMsg(err.message);
      }
    );
  };

  return (
    <>
      <section className="reg-wrap">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 mx-auto">
              <div className="forgot-reg-in">
                <h1>Reset Password</h1>
                <p>Enter a new password</p>

                <Formik
                  initialValues={resetValuesSchema}
                  validationSchema={resetSchema}
                  onSubmit={resetPassword}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 mb-3">
                          <label>Password</label>
                          <div className="d-flex">
                            <Field
                              type={!viewpassword ? "password" : "text"}
                              placeholder="Enter a strong password"
                              className="form-control"
                              name="password"
                            />
                            <div
                              className="eye-icon"
                              onClick={(e) => setViewPassword(!viewpassword)}
                            >
                              <img
                                src={!viewpassword ? CloseEye : OpenEye}
                                alt=""
                              />
                            </div>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>

                        <div className="col-xl-12 col-lg-12 for-forgot">
                          <button type="submit" className="def-btn ml-auto">
                            {isSubmitting && <Spinner size={true} />}
                            {!isSubmitting && <>Reset Password</>}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default withRouter(SetNewPassword);
