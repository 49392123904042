import { Button, Modal } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

export default function CoinValidationModal({ show, handleClose }) {
  const navigate = useNavigate();
  return (
    <Modal show={show} onClick={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Listing Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="">You need 100 BTS Coins to List a property!</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Okay
        </Button>
        <Button variant="primary" onClick={(e) => navigate("/buytoken")}>
          Buy Tokens
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
