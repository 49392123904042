function ResetLinkSuccess() {
  return (
    <section className="reg-wrap">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-12 mx-auto">
            <div className="notfound-block">
              <h1>Reset Password Success</h1>
              <p>We have sent a reset password link to your email address!</p>
              <a
                className="def-btn mt-4"
                href="https://gmail.com"
                target={`_blank`}
              >
                Open your email
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ResetLinkSuccess;
