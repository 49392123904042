import { useEffect, useRef, useState } from "react";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import { getLocations, getProperties } from "../../modules/listing/actions";
import Select from "react-select";
import MultiRangeSlider from "../../components/multipleRangeSlider/MultiRangeSlider";
import withRouter from "../../utils/withRouter";
import CloseIcon from "../../assets/images/close-btn.svg";
import EmptyImage from "../../assets/illustration/empty.svg";

function ListingPage() {
  const [propertyList, setProperyList] = useState([]);
  const [filterpropertyList, setFilterProperyList] = useState([]);
  const [isFetchingProperty, setIsFetchingProperty] = useState(false);
  const [locations, setLocations] = useState([]);
  const [isFetchingLocations, setIsFetchingLocations] = useState(false);
  const [filters, setFilters] = useState({});
  const [apiMinPrice, setApiMinPrice] = useState(2000);
  const [apiMaxPrice, setApiMaxPrice] = useState(10000000);
  const [searchValue, setSearchValue] = useState("");
  const [orderFilter, setOrderFilter] = useState(0);
  //  set pricing
  const minPrice = 2000;
  const maxPrice = 10000000;

  // const [priceRange, setPriceRange] = useState(0);
  const [priceRangeSlider, setPriceRangeSlider] = useState(false);

  const handleSearchChange = (value) => {
    let inputValue = value.toLowerCase();
    setSearchValue(inputValue.toLowerCase());
    if (inputValue !== "") {
      const filtered = propertyList.filter((item) => {
        return (
          item.title.toLowerCase().indexOf(inputValue) >= 0 ||
          item.country.toLowerCase().indexOf(inputValue) >= 0 ||
          item.city.toLowerCase().indexOf(inputValue) >= 0
        );
      });
      setFilterProperyList(filtered);
    } else {
      setFilterProperyList(propertyList);
    }
  };

  useEffect(() => {
    getAllProperty();
    getAllLocations();
  }, []);

  const getAllProperty = () => {
    setIsFetchingProperty(true);
    getProperties().then(
      (res) => {
        let list = res.data.data;
        setProperyList(list);
        setFilterProperyList(list);
        setIsFetchingProperty(false);
      },
      (err) => {
        setIsFetchingProperty(false);
      }
    );
  };

  const getAllLocations = () => {
    setIsFetchingLocations(true);

    getLocations().then(
      (res) => {
        let list = res.data.data;
        setLocations(list);
        setIsFetchingLocations(false);
      },
      (err) => {
        setIsFetchingLocations(false);
      }
    );
  };

  const formattedArray = (array) => {
    return array.map((item) => {
      return {
        label: item.label,
        value: item.value,
      };
    });
  };

  const formattedArrayLocations = (array) => {
    return array.map((item) => {
      return {
        label: item.location,
        value: item.location,
      };
    });
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      display: "inline-block",
      width: "100%",
      minHeight: "1px",
      textAlign: "left",
      border: "none",
      fontSize: "14px",
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid #c0c0c0",
      borderRadius: "20px",
      width: "100%",
    }),
    input: (provided) => ({
      ...provided,
      minHeight: "1px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
      paddingTop: "0",
      paddingBottom: "0",
      color: "#c0c0c0",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      minHeight: "1px",
      height: "24px",
      background: "#fff",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      //   minHeight: '1px',
      //   height: '40px',
      paddingTop: "0",
      paddingBottom: "0",
    }),
    singleValue: (provided) => ({
      ...provided,
      minHeight: "1px",
      paddingBottom: "2px",
    }),
  };

  const investmentRanges = [
    { label: "0-20% diluted", value: { mindilution: 0, maxdilution: 20 } },
    { label: "20-40% diluted", value: { mindilution: 20, maxdilution: 40 } },
    { label: "40-60 % diluted", value: { mindilution: 40, maxdilution: 60 } },
    {
      label: "more than 60% diluted",
      value: { mindilution: 60, maxdilution: 100 },
    },
  ];
  const investmentStates = [
    { label: "More than 30% available", value: "30" },
    { label: "More than 50% available", value: "50" },
    { label: "More than 80% available", value: "80" },
  ];

  const propertyType = [
    { label: "Residential", value: 0 },
    { label: "Commercial", value: 1 },
  ];

  useEffect(() => {
    filterApi();
  }, [filters]);

  const filterApi = (type, data) => {
    setIsFetchingProperty(true);

    let tempUrl = "";
    Object.keys(filters).map((key, index) => {
      if (index === 0) {
        tempUrl = `${tempUrl}${key}=${filters[key]}`;
        return;
      }
      tempUrl = `${tempUrl}&&${key}=${filters[key]}`;
    });
    getProperties(tempUrl).then(
      (res) => {
        let list = res.data.data;
        setProperyList(list);
        setFilterProperyList(list);
        setIsFetchingProperty(false);
      },
      (err) => {
        setIsFetchingProperty(false);
      }
    );
  };

  const applyPriceAction = (e) => {
    setPriceRangeSlider(false);
    setFilters((prev) => ({
      ...prev,
      minprice: apiMinPrice,
      maxprice: apiMaxPrice,
    }));
  };
  return (
    <>
      <section className="top-search">
        <div className="container">
          <div className="row">
            <div className="inner_area">
              <div className="col-in">
                <label htmlFor="">Property type</label>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className=""
                  isSearchable={true}
                  placeholder={"Choose one"}
                  onChange={(value) => {
                    setFilters((prev) => ({
                      ...prev,
                      type: value.value,
                    }));
                  }}
                  styles={customStyles}
                  options={formattedArray(propertyType)}
                />
              </div>

              <div className="col-in">
                <label htmlFor="">Price range</label>
                <div
                  className={`slider_cover`}
                  onClick={(e) => setPriceRangeSlider(true)}
                >
                  {apiMinPrice === 2000 && apiMaxPrice === 10000000
                    ? "Showing all"
                    : `${apiMinPrice}$ - ${apiMaxPrice}$`}
                </div>
                <div
                  className={`${priceRangeSlider ? "d-flex" : "d-none"
                    } inner_slider_cover`}
                >
                  <MultiRangeSlider
                    min={minPrice}
                    max={maxPrice}
                    onChange={({ min, max }) => {
                      setApiMinPrice(min);
                      setApiMaxPrice(max);
                    }}
                  />
                  <button
                    className="apply_button"
                    onClick={(e) => applyPriceAction(e)}
                  >
                    Apply
                  </button>
                  <div className="cursorPointer">
                    <img
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={(e) => setPriceRangeSlider(false)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-in">
                <label htmlFor="">Dilution ranges</label>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className=""
                  isSearchable={true}
                  placeholder={"Showing all"}
                  onChange={(value) => {
                    setFilters((prev) => ({
                      ...prev,
                      mindilution: value.value.mindilution,
                      maxdilution: value.value.maxdilution,
                    }));
                  }}
                  styles={customStyles}
                  options={formattedArray(investmentRanges)}
                />
              </div>
              <div className="col-in">
                <label htmlFor="">Subscription status</label>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className=""
                  isSearchable={true}
                  placeholder={"More than 50% avail.."}
                  onChange={(value) => {
                    // console.log(value.value);
                    setFilters((prev) => ({
                      ...prev,
                      mininvestment: value.value,
                      maxinvestment: 100,
                    }));
                  }}
                  styles={customStyles}
                  options={formattedArray(investmentStates)}
                />
              </div>
              <div className="col-in">
                <label htmlFor="">Location of property</label>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className=""
                  isSearchable={true}
                  placeholder={"Search here..."}
                  onChange={(value) => {
                    setFilters((prev) => ({
                      ...prev,
                      location: value.value,
                    }));
                  }}
                  styles={customStyles}
                  options={formattedArrayLocations(locations)}
                />
              </div>
              <div className="col-in">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.12759 4.89102H1V1.76343"
                    stroke="#E8505B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.21154 10.7884C4.01344 11.5903 5.03513 12.1364 6.1474 12.3576C7.25967 12.5789 8.41256 12.4653 9.4603 12.0314C10.508 11.5974 11.4035 10.8624 12.0336 9.9195C12.6636 8.97657 12.9999 7.86797 12.9999 6.73391C12.9999 5.59985 12.6636 4.49126 12.0336 3.54832C11.4035 2.60539 10.508 1.87046 9.4603 1.43647C8.41256 1.00248 7.25967 0.888934 6.1474 1.11018C5.03513 1.33142 4.01344 1.87752 3.21154 2.67943L1 4.89096"
                    stroke="#E8505B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input
                  type={"submit"}
                  value="Reset all"
                  onClick={(e) => window.location.reload(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="listing-page mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-3 col-md-12 listing-p-tile">
              <h1>All properties</h1>
              <span className="sub">
                Showing {filterpropertyList.length} results
              </span>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-12 serch-form">
              <div className="form-control-search">
                <input
                  type={"text"}
                  placeholder="Search with name, location or type..."
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
                <button>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00015 15.0003C11.8662 15.0003 15.0003 11.8662 15.0003 8.00015C15.0003 4.13407 11.8662 1 8.00015 1C4.13407 1 1 4.13407 1 8.00015C1 11.8662 4.13407 15.0003 8.00015 15.0003Z"
                      stroke="#888888"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.95 12.9504L17.0001 17.0006"
                      stroke="#888888"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <ul className="quick-filters">
                <li>
                  <svg
                    // className={`${
                    //   orderFilter === 1 || orderFilter === 2
                    //     ? "svg_selected"
                    //     : ""
                    // }`}
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.20007 4.22314L10.6001 1.82315L13.0001 4.22314"
                      stroke={
                        orderFilter === 2 || orderFilter === 3
                          ? "#029a3f"
                          : "#666666"
                      }
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.8824 13.1174L10.8824 1.82336"
                      stroke={
                        orderFilter === 2 || orderFilter === 3
                          ? "#029a3f"
                          : "#666666"
                      }
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.79998 10.2943L3.39999 12.6943L1 10.2943"
                      stroke={
                        orderFilter === 1 || orderFilter === 4
                          ? "#029a3f"
                          : "#666666"
                      }
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.40015 1.40027L3.40015 12.6943"
                      stroke={
                        orderFilter === 1 || orderFilter === 4
                          ? "#029a3f"
                          : "#666666"
                      }
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </li>
                <li>
                  <div
                    className={`${orderFilter === 3 || orderFilter === 4 ? "_selected" : ""
                      }`}
                    onClick={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        orderby:
                          orderFilter === 4 ? 3 : orderFilter === 3 ? 4 : 4,
                      }));
                      orderFilter === 4
                        ? setOrderFilter(3)
                        : orderFilter === 3
                          ? setOrderFilter(4)
                          : setOrderFilter(4);
                    }}
                  >
                    Popularity
                  </div>
                </li>
                <li>
                  <div
                    className={`${orderFilter === 1 || orderFilter === 2 ? "_selected" : ""
                      }`}
                    onClick={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        orderby:
                          orderFilter === 1 ? 2 : orderFilter === 2 ? 1 : 1,
                      }));
                      orderFilter === 1
                        ? setOrderFilter(2)
                        : orderFilter === 2
                          ? setOrderFilter(1)
                          : setOrderFilter(1);
                    }}
                  >
                    Price
                  </div>
                </li>
                {/* <li>
                  <div >Relevence</div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="listing-wrap">
        <div className="container">
          <div className="row">
            {isFetchingProperty && (
              <>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4">
                  <div
                    className="single-l-in"
                    style={{ height: "100vh" }}
                  ></div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4">
                  <div
                    className="single-l-in"
                    style={{ height: "100vh" }}
                  ></div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4">
                  <div
                    className="single-l-in"
                    style={{ height: "100vh" }}
                  ></div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4">
                  <div
                    className="single-l-in"
                    style={{ height: "100vh" }}
                  ></div>
                </div>
              </>
            )}
            {!isFetchingProperty &&
              filterpropertyList.length > 0 &&
              filterpropertyList.map((item, index) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4"
                  key={index}
                >
                  <PropertyCard item={item} />
                </div>
              ))}
            {!isFetchingProperty && filterpropertyList.length === 0 && (
              <div className="col-12 mb-4">
                <div className=" text-center py-5 mt-5">
                  <img src={EmptyImage} alt="EmptyImage" width={300} />
                  <h1 className="text-grey pt-5">No Properties found</h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(ListingPage);
