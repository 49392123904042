import { API_URLS } from "../../configs/urls";
import axios from "axios";

/**
 *  Blogs List
 */

export async function getBlogs(params) {
  try {
    const response = await axios({
      method: "get",
      url: API_URLS.BLOGS.LISTING,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.error;
  }
}

/**
 *  Single Blog
 */

export async function getSingleBlog(id) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_URLS.BLOGS.LISTING}/${id}`,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.error;
  }
}
