import undvImg from "../../assets/images/rej.svg";
import UserHeader from "../../components/UserHeader/UserHeader";
import { useEffect, useState } from "react";
import { getUserListing } from "../../modules/dashboard/actions";
import { useNavigate } from "react-router-dom";
import EmptyImage from "../../assets/illustration/empty.svg";
import {
  editProperty,
  getPropertyDetails,
} from "../../modules/listing/actions";
import banImg from "../../assets/images/img.jpg";
import bed from "../../assets/images/bed.svg";
import built from "../../assets/images/built.svg";
import land from "../../assets/images/land.svg";
import garage from "../../assets/images/garb.svg";
import prop from "../../assets/images/prop.svg";
import wash from "../../assets/images/wash.svg";
import map from "../../assets/images/map.png";
import blitImg from "../../assets/images/chinese-coin.png";
// import apprImg from "../../assets/images/apr.svg";
import noteImg from "../../assets/images/note.svg";
import dolorImg from "../../assets/images/dolor-sy.svg";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MyMapComponent from "../../components/MyMapComponent/MyMapComponent";
// import rejImg from "../../assets/images/undr-vf.svg";
import moment from "moment";
import { getConversion } from "../../modules/blits/actions";
import Web3 from "web3";
import tokenABI from "../../utils/tokenABI";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "../../components/Loader";

function MyListings() {
  const navigate = useNavigate();

  const [propertyList, setProperyList] = useState([]);

  const [isFetchingProperty, setIsFetchingProperty] = useState(false);

  const [isFetchingDetails, setIsFetchingDetails] = useState(false);
  const [propIndex, setPropIndex] = useState(0);

  const [propertyDetail, setPropertyDetails] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [images, setImages] = useState([]);
  const [docs, setDocs] = useState([]);
  const [conversion, setConversion] = useState("");
  // form states
  const [allValues, setAllValues] = useState({});
  // for modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isLoading, setIsLoading] = useState(false);
  const [propId, setPropId] = useState(null);

  // const [publicUrl, setPublicUrl] = useState('');

  useEffect(() => {
    getAllSideProperty();
    fetchRate();
  }, []);

  let web3 = new Web3();

  const ethEnabled = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information from the MetaMask provider information
      web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.enable();

        metamaskDetails();
        return true;
      } catch (e) {
        // User denied access
        return false;
      }
    } else {
      alert("Please install MetaMask to use this service!");
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
    }

    return false;
  };

  const metamaskDetails = async () => {
    setIsLoading(true);

    //const tokenAddress = "0x452bBde39d3a14965da2448bfCbDcC8BfEDF8c11";
    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;

    const accounts = await new web3.eth.getAccounts();
    const NameContract = new web3.eth.Contract(tokenABI, tokenAddress);
    try {
      const balance = await NameContract.methods.balanceOf(accounts[0]).call();

      let value = Web3.utils.fromWei(balance, "ether");
      let finval = Math.floor(value * 1000) / 1000;
      if (finval < 100) {
        showErrorMsg("Not enough BTS Coins");
      } else {
        await NameContract.methods
          .transfer(
            "0x92B65d6392f82c622FCB1A7c51893950f35FD9f5",
            web3.utils.toWei("100", "ether")
          )
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {
            console.log("hash", hash);
          })
          .on("receipt", function (receipt) {
            var urlencoded = new URLSearchParams();
            urlencoded.append("data[paid]", 1);
            urlencoded.append("propertyId", propId);

            editProperty(urlencoded).then(
              (res) => {
                showSuccessMsg("Paid Successfully");
                setIsLoading(false);
                setShow(false);
                setPropId(null);

                setTimeout(() => {
                  window.location.reload(true);
                }, 1500);
              },
              (err) => {
                showSuccessMsg("Something went wrong!");
                setIsLoading(false);
                setShow(false);
                setPropId(null);
                setTimeout(() => {
                  window.location.reload(true);
                }, 1500);
              }
            );
          })
          .on("error", function (hash) {
            showSuccessMsg("Something went wrong!");
            setIsLoading(false);
            setShow(false);
            setPropId(null);
          });
      }
    } catch (error) {
      showErrorMsg("Not enough BTS Coins");
    }
  };

  const getAllSideProperty = () => {
    setIsFetchingProperty(true);
    setIsFetchingDetails(true);
    getUserListing().then(
      (res) => {
        let list = res.data.data;
        if (list.length > 0) {
          let url = list[propIndex].public_url;
          fetchDetails(url);
          setProperyList(list);
          setIsFetchingProperty(false);
          // setIsFetchingDetails(false);
        } else {
          setIsFetchingProperty(false);
          // setIsFetchingDetails(false);
        }
      },
      (err) => {
        setIsFetchingProperty(false);
      }
    );
  };

  const fetchDetails = (url) => {
    setIsFetchingDetails(true);
    getPropertyDetails(url).then(
      (res) => {
        let details = res.data;
        let d = details.data[0];
        d.docs = details.docs.data;
        d.images = details.images.data;
        d.amenities = details.amenities.data;
        d.mode = "edit";
        setAllValues(d);
        setPropertyDetails(details.data[0]);
        setAmenities(details.amenities.data);
        setImages(details.images.data);
        setDocs(details.docs.data);
        setIsFetchingDetails(false);
      },
      (err) => {
        setIsFetchingDetails(false);
      }
    );
  };

  const selectProperty = (key) => {
    setPropIndex(key);

    // setPublicUrl(propertyList[key].public_url)
    fetchDetails(propertyList[key].public_url);
  };

  const fetchRate = () => {
    getConversion().then(
      (res) => {
        let conversion = res.data.data[0].conversion;
        setConversion(conversion);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const render = (status) => {
    switch (status) {
      default:
        return <></>;
      case Status.LOADING:
        return <></>;
      case Status.FAILURE:
        return <></>;
      case Status.SUCCESS:
        return <MyMapComponent />;
    }
  };
  // calculation of open window
  var utc = moment.utc(propertyDetail.created_at, "YYYY-MM-DD HH:mm:ss");
  var local = utc.local();
  let finalDate = local.add("days", propertyDetail.open_window);
  var now = moment();
  let open_status;
  if (now < finalDate) {
    open_status = true;
  } else {
    open_status = false;
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>Purchase Confirmation</Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <Modal.Body>
            Please wait while we are making the payment. This might take a
            while...
          </Modal.Body>
        ) : (
          <Modal.Body>
            Do you really want to list your property at 100 BTS coins?
          </Modal.Body>
        )}
        <Modal.Footer>
          {isLoading ? (
            <Button variant="primary" disabled>
              Processing &nbsp; <Spinner size={true} />
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={ethEnabled}>
                Yes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <UserHeader />
      {isFetchingProperty && (
        <div className="spinner-padding">
          <Spinner size={false} />
          <p>Loading....</p>
        </div>
      )}
      {!isFetchingProperty && (
        <section className="profle_content my_listing">
          <div className="container">
            {propertyList.length === 0 && !isFetchingProperty && (
              <>
                <div className="text-center pt-5">
                  <img src={EmptyImage} alt="EmptyImage" width={300} />
                  <h1 className="text-grey pt-5">No Property listed!</h1>
                  <h2
                    className="green_link"
                    onClick={(e) => navigate("/add-property")}
                  >
                    + List new property now
                  </h2>
                </div>
              </>
            )}

            {isFetchingProperty && (
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 profle_left_sect">
                  <h3>
                    My properties
                    <span onClick={(e) => navigate("/add-property")}>
                      + List new property
                    </span>
                  </h3>
                  <div
                    className="property_invested under_varification text-center justify-content-center"
                    style={{ height: "25vh" }}
                  >
                    <h3 className="text-grey text-center mt-3">Loading...</h3>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 profile_right_side">
                  {isFetchingDetails && (
                    <div className="col-12 mt-3 holding_details">
                      <div className="ab-amnty" style={{ height: "35vh" }}>
                        <h1 className="text-grey text-center mt-5 pt-3">
                          Loading...
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {propertyList.length > 0 && !isFetchingProperty && (
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 profle_left_sect">
                  <>
                    <h3>
                      My properties
                      <span onClick={(e) => navigate("/add-property")}>
                        + List new property
                      </span>
                    </h3>
                    {isFetchingProperty && (
                      <div
                        className="property_invested under_varification text-center justify-content-center"
                        style={{ height: "25vh" }}
                      >
                        <h3 className="text-grey text-center mt-3">
                          Loading...
                        </h3>
                      </div>
                    )}
                    {!isFetchingProperty &&
                      propertyList.length > 0 &&
                      propertyList.map((data, index) => (
                        <div
                          className={`property_invested ${
                            data.approved === 0
                              ? "under_varification"
                              : data.approved === 1
                              ? "approved_varification"
                              : data.approved === 2
                              ? "rejected_varification"
                              : ""
                          } ${propIndex === index ? "_selectedProperty" : ""}`}
                          onClick={(e) => selectProperty(index)}
                          key={index}
                        >
                          <span className="varify-icon">
                            <img src={undvImg} alt="" />
                          </span>
                          <div className="property_inv_img">
                            <div className="property_inv_img_in">
                              <img src={data.tile_image} alt="" />
                            </div>
                            <span>${data.cost}</span>
                          </div>
                          <div className="property_inv_details">
                            <h4>{data.title}</h4>
                            <small>{data.city + ", " + data.country}</small>
                            <span className="bl_amount">
                              {data.open_window} days remaining{" "}
                              <span className="_varify">
                                {data.approved === 0
                                  ? "Under verification"
                                  : data.approved === 1
                                  ? "Approved"
                                  : data.approved === 2
                                  ? "Rejected"
                                  : ""}
                              </span>
                            </span>
                            <span className="bl_sh_budget">
                              Shares bought:{" "}
                              <strong>{data.purchased} shares</strong>
                            </span>
                          </div>
                        </div>
                      ))}
                  </>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 profile_right_side">
                  {isFetchingDetails && (
                    <div className="col-12 mt-3 holding_details">
                      <div className="ab-amnty" style={{ height: "35vh" }}>
                        <h1 className="text-grey text-center mt-5 pt-3">
                          Loading...
                        </h1>
                      </div>
                    </div>
                  )}
                  {!isFetchingDetails && (
                    <div>
                      {propertyDetail.paid === 0 ? (
                        <div className="error_banner">
                          <p>Please pay the amount to list property now. </p>
                          <button
                            onClick={(e) => {
                              setShow(true);
                              setPropId(propertyDetail.id);
                            }}
                          >
                            Pay now
                          </button>
                        </div>
                      ) : null}
                      <div className="proprty_title">
                        <div className="proprty_title_in">
                          <h1>
                            {propertyDetail.title}
                            <span>
                              {propertyDetail.type === "0"
                                ? "Residential"
                                : "Commercial"}
                            </span>
                          </h1>
                          <small>
                            {propertyDetail.city}, {propertyDetail.country}
                          </small>
                        </div>

                        <button
                          type="button"
                          className="def-btn"
                          onClick={(e) =>
                            navigate("/add-property", {
                              state: allValues,
                            })
                          }
                        >
                          Edit property details
                        </button>
                      </div>

                      <div className="col-12 mt-4 mb-4">
                        <div className="row">
                          <div className="col-xl-6 col-lg-12 listing_on">
                            <small>Listed on</small>
                            <img src={noteImg} alt="" />{" "}
                            {moment(propertyDetail.created_at).format(
                              "DD MMM YYYY"
                            )}{" "}
                            {open_status ? (
                              <span>
                                {finalDate.diff(now, "days")} days left
                              </span>
                            ) : (
                              <span>Closing soon</span>
                            )}
                          </div>
                          <div className="col-xl-6 col-lg-12 listing_on">
                            <small>Shared sold till now</small>
                            <img src={dolorImg} alt="" />{" "}
                            {propertyDetail.shares} Shares
                            <span>
                              {propertyDetail.shares - propertyDetail.purchased}{" "}
                              remains
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mt-3 holding_details">
                        <div className="ab-amnty">
                          <table width="100%">
                            <tbody>
                              <th>
                                <td>
                                  <h2>Cost and share details</h2>
                                </td>
                              </th>
                              <tr>
                                <td>Total cost in USD</td>
                                <td className="ch_value">
                                  : ${propertyDetail.cost}{" "}
                                  <span>
                                    {" "}
                                    <img src={blitImg} alt="" />
                                    {propertyDetail.cost * conversion} Blits
                                    Coins{" "}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>Subscription status</td>
                                <td>
                                  :{" "}
                                  {(propertyDetail.purchased /
                                    propertyDetail.shares) *
                                    100}
                                  % Subscribed
                                </td>
                              </tr>
                              <tr className="cur_value">
                                <td>Total dilution</td>
                                <td>
                                  {" "}
                                  :{" "}
                                  {propertyDetail.shares /
                                    propertyDetail.dilution}{" "}
                                  ({propertyDetail.dilution}%) /{" "}
                                  {propertyDetail.shares} shares
                                </td>
                              </tr>
                              <tr>
                                <td>Total number of shares</td>
                                <td className="ch_value">
                                  : {propertyDetail.shares}
                                </td>
                              </tr>
                              <tr className="cur_value">
                                <td>Value of 1 share</td>
                                <td>
                                  : ${propertyDetail.share_value}{" "}
                                  <span>
                                    {" "}
                                    <img src={blitImg} alt="" />
                                    {propertyDetail.share_value *
                                      conversion}{" "}
                                    Blits Coins{" "}
                                  </span>
                                </td>
                              </tr>
                              {/* <tr className="cur_value">
                              <td>Minimum share limit</td>
                              <td>: {propertyDetail.min_share}</td>
                            </tr> */}
                              {/* <tr className="cur_value">
                              <td>Maximum share limit</td>
                              <td>: {propertyDetail.max_share}</td>
                            </tr> */}
                              <tr className="cur_value">
                                <td>Investment open window</td>
                                <td>
                                  : {propertyDetail.open_window} days from the
                                  date of publishing
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-12 det-left mt-4">
                        <h2>Property overview</h2>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 am-det-w">
                              <div className="am-det-in">
                                <span className="amnty-title">
                                  <img src={bed} alt="" />
                                  Bed rooms
                                </span>
                                <span className="amnty-desc">
                                  {propertyDetail.overview_1}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 am-det-w">
                              <div className="am-det-in">
                                <span className="amnty-title">
                                  <img src={wash} alt="" />
                                  Washroom
                                </span>
                                <span className="amnty-desc">
                                  {propertyDetail.overview_2}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 am-det-w">
                              <div className="am-det-in">
                                <span className="amnty-title">
                                  <img src={built} alt="" />
                                  Built area
                                </span>
                                <span className="amnty-desc">
                                  {propertyDetail.overview_3}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 am-det-w">
                              <div className="am-det-in">
                                <span className="amnty-title">
                                  <img src={land} alt="" />
                                  Land area
                                </span>
                                <span className="amnty-desc">
                                  {propertyDetail.overview_4}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 am-det-w">
                              <div className="am-det-in">
                                <span className="amnty-title">
                                  <img src={garage} alt="" />
                                  Garage
                                </span>
                                <span className="amnty-desc">
                                  {propertyDetail.overview_5}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 am-det-w">
                              <div className="am-det-in">
                                <span className="amnty-title">
                                  <img src={prop} alt="" />
                                  Property type
                                </span>
                                <span className="amnty-desc">
                                  {propertyDetail.overview_6 === "0"
                                    ? "Nuclear Family"
                                    : propertyDetail.overview_6 === "1"
                                    ? "Extended Family"
                                    : propertyDetail.overview_6 === "2"
                                    ? "Office space"
                                    : propertyDetail.overview_6 === "3"
                                    ? "Industrial use"
                                    : propertyDetail.overview_6 === "4"
                                    ? "Multi-family rental"
                                    : propertyDetail.overview_6 === "5"
                                    ? "Retail Spaces"
                                    : "NA"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-4 ab-proprety mb-4">
                          <h2>About property</h2>
                          <p>{propertyDetail.description}</p>
                        </div>

                        {amenities.length > 0 && (
                          <div className="col-12 mt-4">
                            <div className="ab-amnty">
                              <table width="100%">
                                <tbody>
                                  <th>
                                    <td>
                                      <h2>Amenities</h2>
                                    </td>
                                  </th>
                                  {amenities.length > 0 &&
                                    amenities.map((data, index) => (
                                      <tr key={index}>
                                        <td>{data.amenity_name}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        <div className="col-12 mt-4 ab-proprety">
                          <h2>Speciality of the property</h2>
                          <p>{propertyDetail.speciality}</p>
                        </div>

                        <div className="col-12 mt-3 ab-invest">
                          <h2>Why should you invest?</h2>

                          {propertyDetail?.why_invest &&
                            propertyDetail?.why_invest}
                        </div>

                        <div className="col-12 mt-3 ab-invest media_listing">
                          <h2>Images and videos added</h2>
                          <div className="col-12">
                            <div className="row m-0">
                              {images.length === 0 && <p>No images to show</p>}
                              {images.map((data, index) => (
                                <>
                                  {data.type === 0 ? (
                                    <div
                                      className="property_inv_img_in"
                                      key={index}
                                    >
                                      <img src={data.path} alt="" />
                                    </div>
                                  ) : null}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-4 ab-invest">
                          <h2>Location and nearby</h2>
                          <Wrapper
                            apiKey={process.env.REACT_APP_MAPS_KEY}
                            render={render}
                          >
                            <MyMapComponent
                              center={{
                                lat: parseInt(propertyDetail?.latitude),
                                lng: parseInt(propertyDetail?.longitude),
                              }}
                              zoom={12}
                              isMarkerShown
                            />
                          </Wrapper>
                        </div>
                        {/* 
                  <div className="col-12 mt-4 ex-proprety">
                    <h2>Existing investers of this property (14)</h2>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td>
                            <div className="aut-img">S</div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                          <td>
                            <div className="aut-img">S</div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div className="aut-img">
                              <img
                                src="https://cdn.pixabay.com/photo/2015/07/20/12/57/ambassador-852766__340.jpg"
                                alt=""
                              />
                            </div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                          <td>
                            <div className="aut-img">
                              <img
                                src="https://cdn.pixabay.com/photo/2015/07/20/12/57/ambassador-852766__340.jpg"
                                alt=""
                              />
                            </div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div className="aut-img">S</div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                          <td>
                            <div className="aut-img">S</div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div className="aut-img">
                              <img
                                src="https://cdn.pixabay.com/photo/2015/07/20/12/57/ambassador-852766__340.jpg"
                                alt=""
                              />
                            </div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                          <td>
                            <div className="aut-img">
                              <img
                                src="https://cdn.pixabay.com/photo/2015/07/20/12/57/ambassador-852766__340.jpg"
                                alt=""
                              />
                            </div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="aut-img">S</div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                          <td>
                            <div className="aut-img">S</div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div className="aut-img">
                              <img
                                src="https://cdn.pixabay.com/photo/2015/07/20/12/57/ambassador-852766__340.jpg"
                                alt=""
                              />
                            </div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                          <td>
                            <div className="aut-img">
                              <img
                                src="https://cdn.pixabay.com/photo/2015/07/20/12/57/ambassador-852766__340.jpg"
                                alt=""
                              />
                            </div>
                            <div className="au-detp">
                              Émile Germain
                              <span className="cntr">France</span>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <span className="dte-invst">2 Sep 2022</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="view_listing col-12">
                    <a href="#">Show more...</a>
                  </div> */}
                      </div>

                      <div className="view_listing col-12">
                        <p>
                          Not getting more attention? try making it featured
                        </p>
                      </div>

                      <div className="view_listing col-12">
                        <button className="more-det">Make it featured</button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}
export default MyListings;
