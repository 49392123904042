import { API_URLS } from "../../configs/urls";
import axios from "axios";
import { getToken } from "../../utils/helper";

/**
 *  User Listing
 */
export async function getUserListing(params) {
  const token = getToken();
  try {
    const response = await axios({
      method: "get",
      url: API_URLS.DASHBOARD.USER_LISTING,
      data: params,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.error;
  }
}

/**
 *  User Investment
 */
export async function getUserInvestment(params) {
  const token = getToken();
  try {
    const response = await axios({
      method: "get",
      url: API_URLS.DASHBOARD.USER_INVESTMENT,
      data: params,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.error;
  }
}
