import closeImg from "../../assets/images/close-btn.svg";
import bltsImg from "../../assets/images/chinese-coin.png";
import ListPropertyHeader from "./ListPropertyHeader";
import { useEffect, useState } from "react";
import {
  getLocalStorage,
  getPropertyStorage,
  setPropertyStorage,
} from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import withRouter from "../../utils/withRouter";
import S3FileUpload, { deleteFile } from "react-s3";
import { showErrorMsg } from "../../utils/notification";
import { getConversion } from "../../modules/blits/actions";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Spinner from "../../components/Loader";

function AddPropertyCosting({ location }) {
  const { width } = useWindowDimensions();
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_BUCKET_DIR,
    region: process.env.REACT_APP_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };

  const navigate = useNavigate();
  const [docs, setAllDocs] = useState([]);
  const [digiSign, setAllDigiSign] = useState([]);
  const [conversion, setConversion] = useState("");
  const [isLoading, setIsloading] = useState(false);
  // const [localData, setLocalData] = useState({});
  const [mode, setMode] = useState("add");

  const handleFile = async (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          let item = { type: 0, path: data.location };
          setAllDocs([...docs, item]);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      showErrorMsg("File Type not supported");
    }
  };
  const handleSignFile = async (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          let item = { type: 1, path: data.location };
          setAllDigiSign([...digiSign, item]);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      showErrorMsg("File Type not supported");
    }
  };

  // form states
  const [allValues, setAllValues] = useState({});

  // for setting form values
  const changeHandler = (e) => {
    setAllValues((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    editCheck();
  }, []);

  const editCheck = () => {
    if (location?.state?.mode === "edit") {
      let editData = location.state;
      setMode("edit");
      setAllValues(editData);
      let docs = [];
      let signs = [];
      editData?.docs?.map((data) => {
        if (data.type === 0) {
          docs.push(data);
        } else if (data.type === 1) {
          signs.push(data);
        }
      });
      setAllDocs(docs);
      setAllDigiSign(signs);
    }
  };

  useEffect(() => {
    setAllValues(location.state);
  }, []);

  useEffect(() => {
    checkUserData();
    fetchRate();
  }, []);

  const checkUserData = () => {
    const storage = getLocalStorage();
    if (!storage) {
      navigate("/signup");
    }
  };

  const deleteDocument = (url) => {
    let data = docs.filter(function (data) {
      return data.path !== url;
    });
    setAllDocs(data);
  };

  const deleteSignDocument = (url) => {
    let data = docs.filter(function (data) {
      return data.path !== url;
    });
    setAllDigiSign(data);
  };

  const setDilution = (e) => {
    let price = allValues.cost;
    let inputValue = e.value;
    let dilValue = Math.round((price * inputValue) / 100);
    setAllValues((prev) => ({
      ...prev,
      dilution: inputValue,
      dilution_amount: dilValue,
    }));
  };

  const ranges = [
    { days: 10 },
    { days: 20 },
    { days: 40 },
    { days: 50 },
    { days: 80 },
    { days: 100 },
  ];

  const fetchRate = () => {
    getConversion().then(
      (res) => {
        let conversion = res.data.data[0].conversion;
        setConversion(conversion);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const options = [
    { days: 30 },
    { days: 60 },
    { days: 90 },
    { days: 180 },
    { days: 360 },
  ];
  const customStyles = {
    container: (provided) => ({
      ...provided,
      display: "inline-block",
      width: "100%",
      minHeight: "1px",
      textAlign: "left",
      border: "none",
      fontSize: "16px",
    }),
    control: (provided) => ({
      ...provided,
      border: "2px solid #cccccc",
      borderRadius: "6px",
      width: width !== null && width < 991 ? "100%" : "90%",
    }),
    input: (provided) => ({
      ...provided,
      minHeight: "1px",
      height: "40px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
      paddingTop: "0",
      paddingBottom: "0",
      color: "#c0c0c0",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      minHeight: "1px",
      height: "24px",
      background: "#fff",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      //   minHeight: '1px',
      //   height: '40px',
      paddingTop: "0",
      paddingBottom: "0",
      paddingLeft: "15px",
      paddingRight: "15px",
    }),
    singleValue: (provided) => ({
      ...provided,
      minHeight: "1px",
      height: "20px",
      paddingBottom: "2px",
      fontWeight: 700,
    }),
    placeholder: (provided) => ({
      ...provided,
      top: "55%",
      color: "#C0C0C0",
      fontWeight: "400",
    }),
  };

  const formattedArray = (array) => {
    return array.map((item) => {
      return {
        label: item.days + " days",
        value: item.days,
      };
    });
  };

  const formattedArrayPercent = (array) => {
    return array.map((item) => {
      return {
        label: "Make " + item.days + " % dilution",
        value: item.days,
      };
    });
  };

  const setPageBack = (e) => {
    let data = getPropertyStorage();
    let finData = JSON.parse(data);
    finData.pageComplete = 2;
    setPropertyStorage(finData);
    navigate(-1);
  };

  const setTotalShares = (e) => {
    let data = e.target.value;
    if (data === "") {
      setAllValues((prev) => ({
        ...prev,
        shares: 0,
        share_value: 0,
      }));
    } else {
      let val = Math.round(allValues.dilution_amount / data);
      setAllValues((prev) => ({
        ...prev,
        shares: data,
        share_value: val,
      }));
    }
  };

  const setShareValue = (e) => {
    let data = e.target.value;
    if (data === "") {
      setAllValues((prev) => ({
        ...prev,
        shares: 0,
        share_value: 0,
      }));
    } else {
      let val = Math.round(allValues.dilution_amount / data);
      setAllValues((prev) => ({
        ...prev,
        shares: val,
        share_value: data,
      }));
    }
  };

  const formSubmit = (e) => {
    e.preventDefault();
    var params = allValues;

    params.mode = mode;
    params.docs = docs.concat(digiSign);
    params.pageComplete = 3;

    params.min_share = 1;
    params.max_share = 9999999;

    if (params.cost === undefined || params.cost === "") {
      showErrorMsg("Please add the cost");
    } else if (allValues.dilution === undefined || allValues.dilution === "") {
      showErrorMsg("Please add dilution value");
    } else if (allValues.shares === undefined || allValues.shares === "") {
      showErrorMsg("Please add number of shares");
    } else if (
      allValues.open_window === undefined ||
      allValues.open_window === ""
    ) {
      showErrorMsg("Please enter window time");
    } else if (docs.length === 0) {
      showErrorMsg("Please add atleast one document");
    } else if (digiSign.length === 0) {
      showErrorMsg("Please add Digital signature");
    } else {
      setPropertyStorage(params);
      navigate("/property-preview", {
        state: params,
      });
    }
  };

  return (
    <>
      <ListPropertyHeader />
      {isLoading && (
        <div className="spinner-padding">
          <Spinner size={false} />
          <p>Loading....</p>
        </div>
      )}
      {!isLoading && (
        <section className="listproperty_wrap">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-12 listproperty_left">
                <h4 className="mb-4">Add cost and share details</h4>
                <form onSubmit={formSubmit}>
                  <div className="row">
                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>
                        Total Property cost (USD)<span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input">
                      <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-12">
                          <span className="inp-wrap">
                            <input
                              name="cost"
                              onChange={changeHandler}
                              value={allValues.cost}
                              type="text"
                              placeholder="0"
                            />
                            <small>USD</small>
                          </span>
                        </div>
                        <div className="col-xl-6 col-lg-12 blits-name">
                          {isNaN(allValues.cost * conversion) ? null : (
                            <span>
                              {" "}
                              <img src={bltsImg} alt="" />{" "}
                              {allValues.cost * conversion} Blits Coins
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>
                        Total dilution<span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input">
                      <CreatableSelect
                        formatCreateLabel={(userInput) =>
                          `Make ${userInput} % dilution`
                        }
                        placeholder="Select Dilution value"
                        isClearable
                        value={
                          allValues.dilution
                            ? //   field.value
                              {
                                label: allValues.dilution + " %",
                                value: allValues.dilution,
                              }
                            : ""
                        }
                        onChange={(e) => setDilution(e)}
                        // onInputChange={this.handleInputChange}
                        options={formattedArrayPercent(ranges)}
                        styles={customStyles}
                        components={{
                          // DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        name="dilution"
                      />
                    </div>

                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>Dilution amount</label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input listproperty_disabled">
                      <input
                        name="dilution_amount"
                        onChange={changeHandler}
                        value={
                          isNaN(allValues.cost / allValues.dilution)
                            ? ""
                            : allValues.cost / allValues.dilution
                        }
                        type="text"
                        placeholder="0"
                        disabled
                      />
                    </div>

                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>
                        Total number of shares<span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input">
                      <input
                        name="shares"
                        type="text"
                        placeholder="0"
                        onChange={setTotalShares}
                        value={allValues.shares}
                      />
                    </div>

                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>
                        Value of 1 share<span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input">
                      <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-12">
                          <span className="inp-wrap">
                            <input
                              name="share_value"
                              type="text"
                              placeholder="0"
                              onChange={setShareValue}
                              value={allValues.share_value}
                              // disabled
                            />
                            <small>USD</small>
                          </span>
                        </div>
                        <div className="col-xl-6 col-lg-12 blits-name">
                          {isNaN(allValues.share_value * conversion) ? null : (
                            <span>
                              <img src={bltsImg} alt="" />
                              {allValues.share_value * conversion} Blits Coins
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>
                        Investment open window(In Days)<span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input">
                      <Select
                        components={{
                          // DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        className=""
                        isSearchable={true}
                        // placeholder={placeholder}
                        onChange={(value) => {
                          setAllValues((prev) => ({
                            ...prev,
                            open_window: value.value,
                          }));
                        }}
                        value={
                          allValues.open_window
                            ? {
                                label: allValues.open_window + " days",
                                value: allValues.open_window,
                              }
                            : ""
                        }
                        styles={customStyles}
                        options={formattedArray(options)}
                      />
                    </div>

                    <div className="col-12 listproperty__media mb-4 mt-3">
                      <h4>Upload documents</h4>
                      <p>
                        Upload every certificate to get it approved for listing.
                        You can upload multiple certificates for each.(Only PDFs
                        are supported)
                      </p>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 doc_upload">
                      <h4>
                        1. Land ownership certificate
                        <span style={{ color: "red" }}>*</span>
                      </h4>

                      {docs.length > 0 &&
                        docs.map((data, index) => (
                          <span className="doc_type" key={index}>
                            <span className="doc_type_in  __pdf">PDF</span>
                            Document
                            <img
                              src={closeImg}
                              onClick={(e) => deleteDocument(data.path)}
                              className="cursorPointer"
                              alt=""
                            />
                          </span>
                        ))}
                      <div className="file-input">
                        <input
                          type="file"
                          name="file-input"
                          id="file-input"
                          className="file-input__input"
                          onChange={(event) => handleFile(event)}
                        />
                        <label
                          className="file-input__label"
                          htmlFor="file-input"
                        >
                          +<span>Choose file</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 doc_upload">
                      <h4>
                        2. Digital Signature{" "}
                        <span style={{ color: "red" }}>*</span>
                      </h4>
                      {digiSign.length > 0 &&
                        digiSign.map((data, index) => (
                          <span className="doc_type" key={index}>
                            <span className="doc_type_in  __pdf">PDF</span>
                            Document
                            <img
                              src={closeImg}
                              onClick={(e) => deleteSignDocument(data.path)}
                              className="cursorPointer"
                              alt=""
                            />
                          </span>
                        ))}
                      <div className="file-input">
                        <input
                          type="file"
                          name="file-input2"
                          id="file-input2"
                          className="file-input__input"
                          onChange={(event) => handleSignFile(event)}
                        />
                        <label
                          className="file-input__label"
                          htmlFor="file-input2"
                        >
                          +<span>Choose file</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <p className="note mt-4">
                    Note: You can come back later and change the details
                  </p>

                  <div className="col-12 sub-btns">
                    <button
                      type="button"
                      className="back-btn"
                      onClick={(e) => setPageBack(e)}
                    >
                      Go back
                    </button>
                    <button type="submit" className="def-btn">
                      Preview your listing
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 listproperty_right ">
                <div className="listproperty_right_in">
                  <span>3</span>
                  <h3>Add price and share details carefully </h3>
                  <p>
                    This is the step where you add the basic but the top
                    important details of the property. There’s no optional
                    fileds and you need add the details in a very clear manner
                    in order to get attestion from the other properties listed
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
export default withRouter(AddPropertyCosting);
