import { API_URLS } from "../../configs/urls";
import axios from "axios";
import { getToken } from "../../utils/helper";

export async function getConversion() {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: API_URLS.BLITS.CURRENT_PRICING,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Add wallet
 */
export async function addWalletAddress(params) {
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.BLITS.ADD_WALLET,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getTransactions() {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: API_URLS.BLITS.TRANSACTIONS,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getCoins() {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: API_URLS.BLITS.COINSBALANCE,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
