import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import blitImg from "../../assets/images/chinese-coin.png";
import searchImg from "../../assets/images/search-icon.svg";
import downImg from "../../assets/images/ar-down.svg";
import UserHeader from "../../components/UserHeader/UserHeader";
import React, { useRef, useEffect, useState } from 'react';
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import withRouter from "../../utils/withRouter";
import { getConversion, getTransactions } from "../../modules/blits/actions";
import moment from "moment";
import tokenABI from "../../utils/tokenABI";
import Spinner from "../../components/Loader";

function MyBlitsCoin() {
  const navigate = useNavigate();

  // const [web3Enabled, setWeb3Enabled] = useState(false);

  const [accounts, setAccounts] = useState([]);

  const [balance, setBalance] = useState([]);

  const [transactionsList, setTransactions] = useState([]);

  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);

  const [conversion, setConversion] = useState("");

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    ethEnabled();
    fetchTransaction();
    fetchRate();
  }, []);

  const fetchTransaction = () => {
    setIsFetchingTransactions(true);
    getTransactions().then(
      (res) => {
        let data = res.data.coinData;
        setTransactions(data);
        setIsFetchingTransactions(false);
      },
      (err) => {
        setIsFetchingTransactions(false);
      }
    );
  };

  const fetchRate = () => {
    getConversion().then(
      (res) => {
        let conversion = res.data.data[0].conversion;
        setConversion(conversion);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  let web3 = new Web3();

  const ethEnabled = async () => {
    setIsloading(true);
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information from the MetaMask provider information
      web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.enable();

        metamaskDetails();
        return true;
      } catch (e) {
        // User denied access
        return false;
      }
    } else {
      alert("Please install MetaMask to use this service!");
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
    }

    return false;
  };

  const metamaskDetails = async () => {
    // setWeb3Enabled(true);
    //const tokenAddress = "0x452bBde39d3a14965da2448bfCbDcC8BfEDF8c11";
    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;

    var accs = await web3.eth.getAccounts();
    const accounts = await new web3.eth.getAccounts();
    const NameContract = new web3.eth.Contract(tokenABI, tokenAddress);
    try {
      const balance = await NameContract.methods.balanceOf(accounts[0]).call();

      let value = Web3.utils.fromWei(balance, "ether");

      setBalance(Math.floor(value * 1000) / 1000);
      setIsloading(false);
    } catch (error) {
      setBalance(0);
      setIsloading(false);
    }
    setAccounts(accs);
  };

  return (
    <>
      <UserHeader />
      {isLoading && (
        <div className="spinner-padding">
          <Spinner size={false} />
          <p>Loading....</p>
        </div>
      )}
      {!isLoading && (
        <section className="profle_content">
          <div className="container">
            {accounts.length > 0 ? (
              <>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-12 profle_left_sect">
                    <h3>
                      My coin status{" "}
                      <span onClick={(e) => navigate("/buytoken")}>
                        + Buy coins
                      </span>
                    </h3>

                    <div className="property_invested_balance">
                      <img src={blitImg} alt="" />
                      <h6>Current balance</h6>
                      <strong>{balance}</strong>
                      <small>Fiat value ${balance * conversion}</small>
                      <span className="rt_change">Last 24 hours change 0%</span>
                      <div className="col-12 text-center d-flex justify-content-center">
                        <button
                          type="button"
                          className="more-det"
                          onClick={(e) => navigate("/property")}
                        >
                          Invest on share
                        </button>
                        <button
                          type="button"
                          className="def-btn"
                          onClick={(e) => navigate("/buytoken")}
                        >
                          Buy coins
                        </button>
                      </div>
                    </div>
                    <h5>
      Note: if you're not able to see coin balance please import 0xB2d4Cc77f233e1e9442EBd11E22D9A286f2D35C3
    </h5>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-12 profile_right_side">
                    <h1>My transactions</h1>

                    <div className="col-12 property_filt">
                      <div className="row">
                        <div className="col-xl-6 col-lg-12 drop_wrap">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              Showing all
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                Date
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Newly added
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="col-xl-6 col-lg-12 filter_search">
                          <Form className="d-flex">
                            <Form.Control
                              type="search"
                              placeholder="Search transactions"
                              aria-label="Search"
                              className="search_form"
                            />
                            <Button variant="outline-success">
                              <img src={searchImg} alt="searchImg" />
                            </Button>
                          </Form>
                        </div>
                      </div>
                    </div>

                    {!isFetchingTransactions &&
                      transactionsList.length === 0 && (
                        <div className="col-12 transact_listing">
                          <div className="row text-center pt-5">
                            <h3>No transactions found</h3>
                          </div>
                        </div>
                      )}

                    {isFetchingTransactions && (
                      <div className="col-12 transact_listing">
                        <div className="row text-center">
                          <h3>Loading...</h3>
                        </div>
                      </div>
                    )}

                    {!isFetchingTransactions &&
                      transactionsList.length > 0 &&
                      transactionsList.map((data, index) => (
                        <div className="col-12 transact_listing">
                          <div className="row">
                            <div className="col-xl-5 price_manage">
                              <div className="price_icon">
                                <img src={downImg} alt="" />
                              </div>
                              <div className="property_time_det">
                                <strong>Purchased Blits coins</strong>
                                <span>
                                  {moment(data.created_at).fromNow()} | Done via
                                  fiat
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-5 ref_number">
                              {/* Ref ID: BT 45824586 525 */}
                            </div>
                            <div className="col-xl-2">
                              <div className="property_prices_det">
                                <strong>{data.coins} BTS</strong>
                                <span>${data.usd_amount.toFixed(2)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-12 text-center py-5">
                  <h1>Please connect metamask to access this page!</h1>
                  <div
                    type="button"
                    className="def-btn mt-4"
                    onClick={ethEnabled}
                  >
                    Connect Metamask
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}
export default withRouter(MyBlitsCoin);
