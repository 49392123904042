import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import demoImg from "../../assets/images/img.jpg";
// import LocateImg from '../../assets/images/locate.svg'
import CoinImg from "../../assets/images/chinese-coin.png";
import bed from "../../assets/images/bed.svg";
import built from "../../assets/images/built.svg";
import land from "../../assets/images/land.svg";
import garage from "../../assets/images/garb.svg";
import prop from "../../assets/images/prop.svg";
import wash from "../../assets/images/wash.svg";
import map from "../../assets/images/map.png";
import downl from "../../assets/images/down.svg";
import tic from "../../assets/images/tic.svg";
import RecentListing from "./RecentListing";
import { Link } from "react-router-dom";
import PaymentArea from "./PaymentArea";
import withRouter from "../../utils/withRouter";
import {
  getOtherProperties,
  getPropertyDetails,
  buyShares,
} from "../../modules/listing/actions";
import moment from "moment";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MyMapComponent from "../../components/MyMapComponent/MyMapComponent";
import { getConversion } from "../../modules/blits/actions";

function DetailPage(props) {
  const [isFetchingProperty, setIsFetchingProperty] = useState(false);
  const [propertyDetail, setPropertyDetails] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [docs, setDocs] = useState([]);
  const [images, setImages] = useState([]);
  const [conversion, setConversion] = useState("");

  let url = props.params.url;
  useEffect(() => {
    fetchDetails();
  }, [url]);

  useEffect(() => {
    fetchRate();
  }, [url]);

  const fetchRate = () => {
    getConversion().then(
      (res) => {
        let conversion = res.data.data[0].conversion;
        setConversion(conversion);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchDetails = () => {
    setIsFetchingProperty(true);
    getPropertyDetails(url).then(
      (res) => {
        let details = res.data;
        setPropertyDetails(details.data[0]);
        setAmenities(details.amenities.data);
        setDocs(details.docs.data);
        setImages(details.images.data);
        setIsFetchingProperty(false);
      },
      (err) => {
        setIsFetchingProperty(false);
        console.log(err);
      }
    );
  };

  const [sidePropertyList, setSideProperyList] = useState([]);
  const [isFetchingSideProperty, setIsFetchingSideProperty] = useState(false);
  useEffect(() => {
    getAllSideProperty();
  }, []);

  const getAllSideProperty = () => {
    setIsFetchingSideProperty(true);
    var limit = 4;
    getOtherProperties(limit).then(
      (res) => {
        let list = res.data.data;
        setSideProperyList(list);
        setIsFetchingSideProperty(false);
      },
      (err) => {
        setIsFetchingSideProperty(false);
        console.log(err);
      }
    );
  };

  const topSection = useRef(null);

  const scrollTop = () => {
    window.scrollTo({
      top: topSection.current["offsetTop"],
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollTop();
  }, []);

  const render = (status) => {
    switch (status) {
      default:
        return <></>;
      case Status.LOADING:
        return <></>;
      case Status.FAILURE:
        return <></>;
      case Status.SUCCESS:
        return <MyMapComponent />;
    }
  };
  let buyPercent = (propertyDetail.purchased / propertyDetail.shares) * 100;
  // calculation of open window
  var utc = moment.utc(propertyDetail.launched_at, "YYYY-MM-DD HH:mm:ss");
  var local = utc.local();

  let finalDate = local.add("days", propertyDetail.open_window);
  var now = moment();
  let open_status;
  if (now < finalDate) {
    open_status = true;
  } else {
    open_status = false;
  }
  // images videos calculation
  let imgs = [];
  let vids = [];
  images.map((data) => {
    if (data.type === 0) {
      imgs.push(data);
    } else if (data.type === 1) {
      vids.push(data);
    }
  });

  return !isFetchingProperty ? (
    <>
      <section className="detail-header" ref={topSection}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6 det-title-wrap">
              <Link to="/property">
                <svg
                  width="12"
                  height="23"
                  viewBox="0 0 12 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 21.9604L1 11.9632L11 1.96606"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              <div className="det-title">
                <h1>
                  {propertyDetail.title}
                  <span>
                    {propertyDetail.type === "0" ? "Residential" : "Commercial"}
                  </span>
                </h1>
                <small>
                  {propertyDetail.city}, {propertyDetail.country}
                </small>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 search-form">
              <div className="form-control-sr">
                <input
                  type={"text"}
                  placeholder="Search with name, location or type..."
                />
                <button>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00017 14.9964C11.8663 14.9964 15.0003 11.8632 15.0003 7.99819C15.0003 4.1332 11.8663 1 8.00017 1C4.13408 1 1 4.1332 1 7.99819C1 11.8632 4.13408 14.9964 8.00017 14.9964Z"
                      stroke="#CECECE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.95 12.9473L17.0001 16.9963"
                      stroke="#CECECE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {images.length > 0 && (
        <section className="det-banner">
          <div className="car-desc">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                {propertyDetail.hold === 0 ? (
                  <>
                    {open_status ? (
                      <span className="d-name position-relative">
                        Closing in: {finalDate.diff(now, "days")} days
                      </span>
                    ) : (
                      <span className="r-name position-relative">
                        Closing soon
                      </span>
                    )}
                  </>
                ) : (
                  <span className="r-name position-relative">
                    Property on hold
                  </span>
                )}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 sp-dc">
                <span className="post-dte">
                  {imgs.length} images,{vids.length} videos
                </span>
              </div>
            </div>
          </div>
          <Carousel>
            {images.length > 0 &&
              images.map((data, index) => (
                <Carousel.Item key={index}>
                  {data.type === 0 ? (
                    <img
                      className="d-block w-100 h-85 object-fit-cover"
                      src={data.path}
                      alt="First slide"
                    />
                  ) : (
                    <video
                      width="400"
                      controls
                      autoPlay
                      className="d-block w-100 h-85 object-fit-cover"
                    >
                      <source src={data.path} type="video/mp4" />
                      <source src={data.path} type="video/ogg" />
                      Your browser does not support HTML video.
                    </video>
                  )}
                </Carousel.Item>
              ))}
          </Carousel>
        </section>
      )}
      {images.length === 0 && <div style={{ paddingTop: "10rem" }}></div>}
      <section className="det-prop-ow">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-5 b-coin">
              <img src={CoinImg} alt="" />${propertyDetail.cost} ~
              <span>${propertyDetail.cost}</span>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-7 b-dets">
              <div className="ownr-name">
                <div className="own-stat">
                  {propertyDetail?.user_name?.charAt(0)}
                </div>
                <div>
                  <small>Owner/Builder</small>
                  <span className="ow-nme">{propertyDetail.user_name}</span>
                </div>
              </div>

              <div className="sub-status">
                <small>Launched on</small>
                <p>{moment(propertyDetail.created_at).format("DD MMM YYYY")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details-of-property">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-sm-12 det-left">
              <h2>Property overview</h2>
              <div className="col-12">
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={bed} alt="" />
                        {propertyDetail.type === "0" ? "Bed rooms" : "Floors"}
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_1}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={wash} alt="" />
                        {propertyDetail.type === "0"
                          ? "Wash room"
                          : "Elevators"}
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_2}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={built} alt="" />
                        Built area
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_3}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={land} alt="" />
                        Land area
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_4}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={garage} alt="" />
                        {propertyDetail.type === "0"
                          ? "Garage Capacity"
                          : "Total Parking"}
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_5}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 am-det-w">
                    <div className="am-det-in">
                      <span className="amnty-title">
                        <img src={prop} alt="" />
                        Property type
                      </span>
                      <span className="amnty-desc">
                        {propertyDetail.overview_6 === "0"
                          ? "Nuclear Family"
                          : propertyDetail.overview_6 === "1"
                          ? "Extended Family"
                          : propertyDetail.overview_6 === "2"
                          ? "Office space"
                          : propertyDetail.overview_6 === "3"
                          ? "Industrial use"
                          : propertyDetail.overview_6 === "4"
                          ? "Multi-family rental"
                          : propertyDetail.overview_6 === "5"
                          ? "Retail Spaces"
                          : "NA"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4 ab-proprety mb-4">
                <h2>About property</h2>
                <p>{propertyDetail.description}</p>
              </div>
              {amenities.length > 0 && (
                <div className="col-12 mt-4">
                  <div className="ab-amnty">
                    <table width="100%">
                      <tbody>
                        <th>
                          <td>
                            <h2>Amenities</h2>
                          </td>
                        </th>
                        {amenities.length > 0 &&
                          amenities.map((data, index) => (
                            <tr key={index}>
                              <td>{data.amenity_name}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <div className="col-12 mt-4 ab-proprety">
                <h2>Speciality of the property</h2>
                <p>{propertyDetail.speciality}</p>
              </div>

              {/* {docs.length > 0 && (
                <div className="col-12 mt-2 ex-proprety ex-legal">
                  <h2>Property documents/ Legal documents</h2>
                  <table width="80%">
                    {docs.length > 0 &&
                      docs.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <img src={tic} alt="" />
                            {data.title}
                          </td>
                          <td>
                            <a href={data.path}>
                              Download <img src={downl} alt="" />{" "}
                            </a>
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
              )} */}

              {/* <div className="col-12 mt-4 ex-proprety">
                <h2>Existing investers of this property (14)</h2>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td>
                        <div className="aut-img">S</div>
                        <div className="au-detp">
                          Émile Germain
                          <span className="cntr">France</span>
                        </div>
                      </td>

                      <td>
                        <span className="dte-invst">2 Sep 2022</span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="aut-img">
                          <img
                            src="https://cdn.pixabay.com/photo/2015/07/20/12/57/ambassador-852766__340.jpg"
                            alt=""
                          />
                        </div>
                        <div className="au-detp">
                          Émile Germain
                          <span className="cntr">France</span>
                        </div>
                      </td>

                      <td>
                        <span className="dte-invst">2 Sep 2022</span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className="aut-img">
                          <img
                            src="https://cdn.pixabay.com/photo/2015/07/20/12/57/ambassador-852766__340.jpg"
                            alt=""
                          />
                        </div>
                        <div className="au-detp">
                          Émile Germain
                          <span className="cntr">France</span>
                        </div>
                      </td>

                      <td>
                        <span className="dte-invst">2 Sep 2022</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              <div className="col-12 mt-4 ab-invest">
                <h2>
                  Why should you invest? <span>How to invest?</span>
                </h2>

                <p>{propertyDetail?.why_invest}</p>
                {/* <ul>
                    <li>Highest retun in short term</li>
                    <li>More number of shares available</li>
                    <li>Big shots are already invested on this property</li>
                  </ul> */}
              </div>

              <div className="col-12 mt-4 ab-invest">
                <h2>Location and nearby</h2>
                {/* <img src={map} alt="" /> */}
                <Wrapper
                  apiKey={process.env.REACT_APP_MAPS_KEY}
                  render={render}
                >
                  <MyMapComponent
                    center={{
                      lat: parseInt(propertyDetail?.latitude),
                      lng: parseInt(propertyDetail?.longitude),
                    }}
                    zoom={12}
                    isMarkerShown
                  />
                </Wrapper>
              </div>
              {propertyDetail.hold === 0 ? (
                <div className="col-12 mt-4 ab-invest ow-adrs">
                  <h2>About owner/ builder</h2>
                  <table width="80%">
                    <tr>
                      <td>Name of owner/ builder</td>
                      <td>: {propertyDetail.user_name}</td>
                    </tr>
                    <tr>
                      <td>Member since</td>
                      <td>
                        {moment(propertyDetail.member_since).format(
                          "DD MMM YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href={`mailto:${propertyDetail.email}`}
                          className="def-btn"
                        >
                          Contact owner
                        </a>
                      </td>
                      <td>
                        <Link to="/property" className="def-l-text">
                          See other properties
                        </Link>
                      </td>
                    </tr>
                  </table>
                </div>
              ) : null}
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12 det-right">
              <div className="col-12 mt-4 ab-invest ow-adrs stickyArea">
                <h2>Investment details</h2>
                <table width="80%">
                  <tr>
                    <td>Subscription status</td>
                    <td>
                      <span className="text-gold">
                        : {buyPercent.toFixed(0)}% Subscribed
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Available shares</td>
                    <td>
                      : {propertyDetail.shares - propertyDetail.purchased}{" "}
                      Shares
                    </td>
                  </tr>
                  <tr>
                    <td>Value of 1 share</td>
                    <td>
                      : ${propertyDetail.share_value} (
                      {propertyDetail.share_value * conversion} Blits Coin)
                    </td>
                  </tr>
                  <tr>
                    <td>Total dilution</td>
                    <td>
                      : {propertyDetail.shares * propertyDetail.share_value} (
                      {propertyDetail.dilution}%) / {propertyDetail.shares}{" "}
                      shares
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Min/Max investment</td>
                    <td>
                      :{" "}
                      {propertyDetail.min_share +
                        "/" +
                        propertyDetail.max_share}
                    </td>
                  </tr> */}
                </table>
                {propertyDetail.hold === 0 ? (
                  <PaymentArea propertyDetail={propertyDetail} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="recent-list">
        <div className="container">
          <h2>Similar Properties</h2>
          <RecentListing
            item={sidePropertyList}
            isFetchingProperty={isFetchingSideProperty}
          />
        </div>
      </section>
    </>
  ) : (
    <section className="details-of-property">
      <div className="container">
        <div className="row" style={{ height: "100vh" }}>
          <h1 className="text-center mt-5 pt-5" style={{ fontSize: "50px" }}>
            Loading
          </h1>
        </div>
      </div>
    </section>
  );
}

export default withRouter(DetailPage);
