import React, { useEffect, useRef } from "react";

function MyMapComponent({ center, zoom, onClick, clicks }) {
  const ref = useRef();

  useEffect(() => {
    new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
  });

  return (
    <div
      ref={ref}
      id="map"
      style={{
        width: "650px",
        height: "200px",
      }}
    />
  );
}
export default MyMapComponent;
