import { GoogleLogin } from "react-google-login";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";

import { authGoogle, signUpNewUser } from "../../modules/auth/actions";
import { useEffect, useState } from "react";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { getLocalStorage, setLocalStorage } from "../../utils/helper";
import OpenEye from "../../assets/images/eye-solid.svg";
import CloseEye from "../../assets/images/eye-slash-solid.svg";
import Spinner from "../../components/Loader";

function RegisterPage() {
  const clientId =
    "593874054451-dvi993l9i70kos5prqhtj8n94h5vidvf.apps.googleusercontent.com";
  const navigate = useNavigate();

  const [viewpassword, setViewPassword] = useState(false);

  const signupUser = (values, { setSubmitting, resetForm }) => {
    var params = {
      name: values.full_name,
      email: values.email,
      password: values.password,
    };
    signUpNewUser(params).then(
      (res) => {
        showSuccessMsg("Registered successfully. Login in now!");
        setSubmitting(false);
        navigate("/login");
      },
      (err) => {
        setSubmitting(false);
        showErrorMsg(err.message);
      }
    );
  };

  useEffect(() => {
    checkUserData();
  });

  useEffect(() => {
    const setAuth = async () => {
      await gapi.load(gapi, clientId, "email");
    };
    setAuth();
  }, []);

  const checkUserData = () => {
    const storage = getLocalStorage();
    if (storage !== null) {
      navigate("/");
    }
  };

  // Google auth code
  const onSuccess = (response) => {
    // const { socialCallback } = this.props;
    var params = {
      provider: "google",
      access_token: response.tokenObj.id_token,
    };
    if (response) {
      authGoogle(params).then(
        (res) => {
          setLocalStorage(res);
          showSuccessMsg("Logged in");
          navigate("/");
          window.location.reload(true);
        },
        (err) => {
          if (err?.statuscode === 400) {
            showErrorMsg(
              "Your account has been deactivated. Please contact the support team"
            );
          } else {
            showErrorMsg(
              "We were not able to authenticate via google. Please try another way."
            );
          }
        }
      );
    }
  };

  const signUpSchema = Yup.object().shape({
    full_name: Yup.string().required("Please enter a full name"),
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
    password: Yup.string()
      .min(
        8,
        "Please enter a password containing at least 1 upper case, 1 lower case, 1 numeric, and 1 special character."
      )
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
      )
      .required("Required"),
  });

  const signupValuesSchema = () => {
    return {
      full_name: "",
      email: "",
      password: "",
    };
  };

  return (
    <>
      <section className="reg-wrap">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 mx-auto">
              <div className="reg-in">
                <h1>Create account</h1>
                <p>Signup with your google account</p>

                <GoogleLogin
                  clientId={clientId}
                  render={(renderProps) => (
                    <button
                      type="button"
                      className="g-btn"
                      onClick={renderProps.onClick}
                    >
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 8.5625H16C16 10.0808 15.4385 11.5521 14.4111 12.7257C13.3837 13.8993 11.954 14.7027 10.3656 14.9989C8.77726 15.2951 7.12848 15.0658 5.70022 14.3501C4.27196 13.6344 3.1526 12.4766 2.53285 11.0739C1.91309 9.67116 1.83131 8.1104 2.30142 6.65751C2.77153 5.20462 3.76446 3.94949 5.11101 3.10599C6.45756 2.26249 8.07443 1.88279 9.68612 2.03161C11.2978 2.18043 12.8046 2.84854 13.9497 3.92212"
                          stroke="white"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Continue with Google
                    </button>
                  )}
                  onSuccess={onSuccess}
                  // onFailure={(response) => responseGoogle(response)}
                  cookiePolicy={"single_host_origin"}
                />
                <p>Or get it created in less than a minute</p>

                <h3>
                  Already have an account?<Link to="/login">Login now</Link>
                </h3>

                <Formik
                  initialValues={signupValuesSchema()}
                  validationSchema={signUpSchema}
                  onSubmit={signupUser}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row">
                        <div className="col-xl-6 col-g-12 mb-3">
                          <label>Full name</label>
                          <Field
                            type="text"
                            placeholder="Enter your name"
                            className="form-control"
                            name="full_name"
                          />
                          <ErrorMessage
                            name="full_name"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-xl-6 col-g-12 mb-3">
                          <label>Email id</label>
                          <Field
                            type="email"
                            placeholder="binseva@gmail.com"
                            className="form-control"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>

                        <div className="col-xl-12 col-g-12 mb-3">
                          <label>Password</label>
                          <div className="d-flex">
                            <Field
                              type={!viewpassword ? "password" : "text"}
                              placeholder="**************"
                              className="form-control"
                              name="password"
                            />
                            <div
                              className="eye-icon"
                              onClick={(e) => setViewPassword(!viewpassword)}
                            >
                              <img
                                src={!viewpassword ? CloseEye : OpenEye}
                                alt=""
                              />
                            </div>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>

                        <div className="col-xl-12 col-g-12 mb-3">
                          <p className="terms">
                            By creating an account, I’m accepting the{" "}
                            <span onClick={(e) => navigate("/terms")}>
                              terms and conditions
                            </span>
                          </p>
                        </div>
                        <div className="col-xl-12 col-g-12">
                          <button
                            type="submit"
                            className="def-btn"
                            disabled={isSubmitting}
                          >
                            {isSubmitting && <Spinner size={true} />}
                            {!isSubmitting && <>Register now</>}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegisterPage;
