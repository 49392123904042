import React from 'react'

const PrivacyNotice = () => {
  return (
    <>
  <section className='terms_page'>
      <div className='container'>
      <p><h1>PRIVACY NOTICE</h1></p>
<h2>HOW DO WE USE PERSONAL INFORMATION AND HOW IS IT COLLECTED? </h2>
<p>We collect many categories of personal information.  The categories we collect may include: Contact  and profile information; Business information, Property information,  chat and opinions; Payment and transaction  information; Location information; Information provided by social networks;  Information provided by third parties;  Information about fraudulent or criminal activity  related to your account. <br/>
  This personal information is collected from you in many ways, which are described in the full Policy. A few examples include:</p>
<ol>
  <li><strong>When You Submit Personal Information To  Us. </strong>We collect personal  information about you when you register for an account, make a purchase, sign up for  marketing communications, or send it to us.</li>
  <li><strong>When You Use Our Service. </strong>We collect  some personal information about you automatically as you use our Service.</li>
  <li><strong>From Our Third-Party Partners. </strong>We may also collect personal information  from our third-party partners who  help us to provide the Service or from other third party partners to supplement or enhance our information.</li>
</ol>

<p>We may link or combine information that we collect  about you from various sources to help ensure a consistent user experience regardless of how you interact  with us – online, or on social  media. <br/>
  We use personal information to provide our Service;  to fulfill your requests, process transactions and contact you; to send you personalized advertising and  promotional content and materials; to analyze  and improve our Service and our business; and for other reasons  described more fully in our Privacy Policy.</p>
<h2>HOW DO WE SHARE INFORMATION? </h2>
<p>We share information in a variety of circumstances  to provide our services, respond to your requests, improve your experience, and otherwise  conduct our business. For example, we may share information:</p>
<ol>
  <li>With our affiliates and companies in the Blits Estates family  of companies, who may use your information in the same way as we can under this Privacy Policy </li>
  <li>With third parties to fulfill your request, such as real estate agents,  payment gateways,insurance etc  on Blits Estates.</li>
  <li>With select third parties,  so that they can provide  you with information and marketing messages about  products and services you may enjoy</li>
</ol>
<br clear="all"/>
<div>
  
  <h2>WHAT CHOICES DO YOU HAVE? </h2>
  <p align="left">We want you to  understand your choices regarding how we may process your personal and Business information. Depending on how you use the Service,  these choices may include the following: </p>
  <ol>
    <li><strong>Interest-based advertising. </strong>Visit the <a href="http://www.networkadvertising.org/choices">Network  Advertising Initiativeʼs online resources</a>, to learn about how you may opt-out  of certain interest-based advertising. Some of these opt-outs may not be effective unless your browser  is set to accept cookies.  Furthermore, if you use a different device, change  browsers or delete cookies, you may need to perform the opt-out task  again</li>
    <li><strong>Cookies Settings and Preferences. </strong>You may disable cookies and other  tracking technologies through  the settings in your browser.</li>
    <li><strong>E-mail Settings and Preferences. </strong>If you no longer want to receive  marketing e-mails from us, you may choose to unsubscribe at any  time. You can also set your e-mail options to  prevent the automatic  downloading of images that may contain tracking  technologies. </li>
  </ol>
  
  <h2>QUESTIONS ABOUT OUR PRIVACY POLICY? </h2>
  <p align="left">For more questions about our Privacy  Policy, please feel free to email us at <a href="mailto:Support@Blitsestates.com">Support@Blitsestates.com</a></p>
  <h2>CALIFORNIA RESIDENTS </h2>
  <p align="left">California residents (Refer the California Privacy  Law) to learn how we process information in accordance with California law.</p>
  <h2>WHAT INFORMATION DO WE COLLECT ABOUT YOU AND HOW DO WE USE IT? </h2>
  <p>When you  use our Service, read our emails, or otherwise engage  with us through  a computer or mobile device, we and our third party partners,  automatically collect information about how you access and use the Service  and information about  the device you use to access the Service. <br/>
    We use this information to enhance and personalize your user experience, to monitor and improve our websites and services, and for other  internal purposes.<br/>
    We typically collect this information through a variety  of tracking technologies, including cookies, Flash objects, web beacons, embedded  scripts, location-identifying technologies, and similar technology (collectively, <strong>&ldquo;tracking technologies&rdquo;</strong>).<br/>
    Information we collect automatically about you may  be combined with other personal information we  collect directly.</p>
</div>
<br clear="all"/>
<div>
  <h2>INFORMATION AUTOMATICALLY COLLECTED THROUGH COOKIES AND OTHER TRACKING TECHNOLOGY. </h2>
  <p>When you  use our Service, read our emails, or otherwise engage  with us through  a computer or mobile device, we and our third party partners,  automatically collect information about how you access and use the Service  and information about  the device you use to access the Service. <br/>
    We use this information to enhance and personalize your user experience, to monitor and improve our websites and services, and for other  internal purposes.<br/>
    We typically collect this information through a variety  of tracking technologies, including cookies, Flash objects, web beacons, embedded  scripts, location-identifying technologies, and similar technology (collectively, <strong>&ldquo;tracking technologies&rdquo;</strong>).<br/>
    Information we collect automatically about you may  be combined with other personal information we  collect directly.</p>
  <h2>HOW WE SHARE YOUR INFORMATION </h2>
  <p>We may share your personal information in the instances  described below. For further information on your choices  regarding your information, see the &ldquo;Control  Over Your Information&rdquo; section below. <br/>
    We may share  your personal information with the following  parties: <br/>
    We may also share information with others  in an aggregated or otherwise anonymized form that does not reasonably identify  you.</p>
  <ol>
    <ol>
      <li>Our affiliates and companies in  the same group of companies as us: Our affiliates and subsidiaries (i.e., any  organization or brand we own or control) or our ultimate holding Blits Estates (i.e., any organization that owns  or controls us) and any subsidiaries it owns. These companies may use your personal information in the same way as  we can under this Privacy Policy.</li>
      <li>Promotional partners: Third  parties with whom we partner to provide contests and sweepstakes, or other  promotional activities, which will usually be identified by name in the Official Rules of the contest or sweepstakes.</li>
      <li>Select third parties: Third  parties, so that they can provide you with information and marketing  messages about products or services that may interest you. To opt-out of  sharing your personal  information with such companies, see Your Choices and Control Over Your Information, below.</li>
      <li>Service  providers and advisors: Third party vendors and other service providers that  perform services  for us, on our behalf, which may include identifying and serving targeted advertisements, providing mailing or email services,  tax and accounting services, product  fulfillment, payments processing, data  enhancement services, fraud prevention, web hosting, or providing analytic  services.</li>
    </ol>
  </ol>
</div>
<br clear="all"/>
<div>
  <ol>
    <ol>
      <li>Product  Suppliers: Third party product suppliers who may have a legitimate business or  legal interest in receiving such information.</li>
      <li>The  public: When you provide feedback or post user content on our site. For  example, if you post a product review on our website or a comment on our social  media sites, your information, such as your first name, last initial,  state of residence, and your comments, may  be displayed on our website  or on our social media  pages.</li>
      <li>Purchasers and third parties  in connection with a business  transaction: Personal information,real estate  or property information may be disclosed  to third parties  in connection with a Blits Estates-related transaction, such as a merger,  sale of Blits  Estates assets or shares, reorganization, financing, change of control or acquisition of all or a portion  of our business by another Blits Estates or third  party, or in the event of a bankruptcy or related or similar proceedings; and</li>
      <li>Law  enforcement, regulators and other parties for legal reasons: Third parties as  required by law or subpoena or if we  reasonably believe that such action is necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) to enforce  our Terms of Use or to protect the security or integrity of our Service;  and/or (c) to exercise or protect the rights, property, or personal safety  of Blits Estates, our visitors, or others.</li>
    </ol>
  </ol>
  <h2>YOUR CHOICES AND CONTROL OVER YOUR INFORMATION </h2>
  <p><strong>Profile and data sharing settings: </strong>You may update your profile information, such as  your address, or billing  information, and may change some of your data sharing  preferences on your Account Page. <br/>
    <strong>How to control your email preferences: </strong>You can stop receiving promotional email communications from us by clicking on the &ldquo;unsubscribe link&rdquo; provided in such communications. We make every  effort to promptly process all  unsubscribe requests. You may not opt out of service-related communications (e.g.,  account verification, transactional communications, changes/updates to features of the Service,  technical and security notices). If you receive  SMS text messages, you may opt-out  at any time by replying STOP  or following the instructions in the text message communication.<br/>
    <strong>Modifying or deleting your information: </strong>If you have any questions about reviewing,  modifying, or deleting your information, or if you want to remove your name or comments from our website  or publicly displayed content, you can contact us directly at Support@Blits  Estates.com. We may not be able to modify or delete your  information in all circumstances.<br/>
    <strong>Geolocation and in-branch location: </strong>You may control location tracking by adjusting your  location services settings on your mobile device.  We may continue to approximate your location based on your IP address  when you access  the Service through  a computer or device. </p>
  <h2>THIRD PARTY DATA COLLECTION AND INTEREST BASED ADVERTISING. </h2>
  <p>We engage in interest-based advertising and permit third party online advertising networks, social  media  companies and other third party services, to collect information about your use of our websites</p>
</div>
<br clear="all"/>
<div>
  <p>over time so  that they may play or display ads on our Service,  on other websites, apps or services  you may  use, and on other devices you may use. We may also use third party services to  collect information about your  browser or device, or your online activity on our Service so that we may enhance  or personalize your experience, better understand the demographics of our users  and how they interact  with our Service,  evaluate the success  of our marketing campaigns, track reporting, referrals and attribution, and similar activities.</p>
  <h2>HOW WE STORE AND PROTECT YOUR INFORMATION </h2>
  <p><strong>Data storage and transfer: </strong>Your information  collected through our website may be stored and processed in the United States or any other country in which Blits Estates  or its affiliates or service providers maintain facilities.<br/>
    <strong>Keeping your information safe: </strong>Security of your information is very important to  us, and we have put in place safeguards to preserve the  integrity and security of information we collect and that we share with our service  providers. However, no security system  is impenetrable and we cannot  guarantee the security  of our systems 100%. In the event that any information under our control  is compromised as a result of a breach of security, we will  take reasonable steps to investigate the situation and, where appropriate, notify those individuals  whose information may have been compromised and take other steps,  in accordance with  any applicable laws  and regulations. </p>
  <h2>5. THIRD PARTY DATA COLLECTION AND INTEREST BASED ADVERTISING. </h2>
  <p>We engage in interest-based advertising and permit third party online advertising networks, social  media  companies and other third party services, to collect information about your use of our websites over time so that they may play or display  ads on our Service, on other websites or services you may use, and on other devices you may use.  We may also use third party services to collect information about your browser or device, or  your online activity on our Service so that we may enhance or personalize  your experience, better understand the demographics of our users and how they  interact with our Service,  evaluate the success of our marketing  campaigns, track reporting, referrals and attribution, and similar activities.</p>
  <h2>CHILDRENʼS PRIVACY </h2>
  <p>Blits Estates does not knowingly  collect or solicit any information from anyone under the age of 13 on this Site. In the event that we learn that  we have inadvertently collected personal information from a child  under age 13, we will delete that information.  If you believe that we might  have any information from a child  under 13, please  contact us at Support@Blits Estates.com </p>
  <h2>STATE-SPECIFIC DISCLOSURES </h2>
  <p>We may choose or be required  by law to provide different or additional disclosures relating to the processing of personal  information about residents of certain states.  Please refer below for disclosures that may be applicable to you:</p>
</div>
<br clear="all"/>
<ol>
  <ol>
    <li>California. If you are a California resident, (Refer to Privacy Notice  of California) information. </li>
    <li>Nevada. Chapter  603A of the Nevada Revised  Statutes permits a Nevada resident  to opt out of future  sales of certain covered information that a website  operator has collected or will collect  about the resident. To submit such a request,  please submit a request via email on support@Blits Estates.com</li>
  </ol>
</ol>
<h2>LINKS TO OTHER WEB SITES AND SERVICES </h2>
<p>The Services  may contain links to and from third party websites of our business  partners, advertisers, and social media sites and our users may  post links to third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies  and that we do not accept any responsibility or liability for their policies. We strongly recommend  that you read their privacy policies and terms and conditions  of use to understand how they collect, use, and share information. We are not  responsible for the privacy practices or the content on the websites of third party sites.</p>
<h2>HOW TO CONTACT  US </h2>
<p>If you have any questions about this Privacy Policy or the website,  please Contact support@Blits Estates.com</p>
<h2>CHANGES TO OUR PRIVACY POLICY </h2>
<p>We may modify or update this Privacy Policy from  time to time to reflect the changes in our business and practices, and you should review this page periodically.  We will update the ʻlast modifiedʼ date at  the bottom of this page when we post changes to this Policy. If you  object to any changes, you may close your account.  Continuing to use our Services  after we publish  changes to this Privacy Policy  means that you are consenting to the changes.</p>
      </div>
    </section>
    </>
  )
}

export default PrivacyNotice