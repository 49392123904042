import ListPropertyHeader from "./ListPropertyHeader";
import { useEffect, useRef, useState } from "react";
import {
  getLocalStorage,
  getPropertyStorage,
  setPropertyStorage,
} from "../../utils/helper";
import { useNavigate } from "react-router-dom";

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MyMapComponent from "../../components/MyMapComponent/MyMapComponent";
import Geocode from "react-geocode";
import binImg from "../../assets/images/bin.svg";
import fileImg from "../../assets/images/img-map.svg";
import S3FileUpload, { deleteFile } from "react-s3";
import { showErrorMsg } from "../../utils/notification";
import vidImg from "../../assets/images/vid-map.svg";
import withRouter from "../../utils/withRouter";
import Web3 from "web3";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import tokenABI from "../../utils/tokenABI";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Spinner from "../../components/Loader";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import CoinValidationModal from "../../components/Modal/CoinValidationModal";

Geocode.setApiKey(process.env.REACT_APP_MAPS_KEY);
Geocode.setLanguage("en");
Geocode.setRegion("in");
Geocode.setLocationType("ROOFTOP");

const render = (status) => {
  switch (status) {
    default:
      return <></>;
    case Status.LOADING:
      return <></>;
    case Status.FAILURE:
      return <></>;
    case Status.SUCCESS:
      return <MyMapComponent />;
  }
};

function AddPropertyDetails({ location }) {
  //  to get width of browser
  const { width } = useWindowDimensions();

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_BUCKET_DIR,
    region: process.env.REACT_APP_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };

  // to show or not show map
  const [showMap, setShowMap] = useState(false);
  // to set lat and long
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  // for images and videos state
  const [allImages, setAllImages] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  // for map clicks
  const [clicks, setClicks] = useState({});
  // image uploading loader
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  // video uploading loader
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);

  // for meta mask
  const [accounts, setAccounts] = useState([]);

  // set coin balance
  const [balance, setBalance] = useState([]);

  // For Modal Validations
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // For loader of Edit and local data
  const [mode, setMode] = useState("add");
  const [isLoading, setIsloading] = useState(false);

  // form states
  const [allValues, setAllValues] = useState({});

  useEffect(() => {
    editCheck();
  }, []);

  const editCheck = () => {
    if (location?.state?.mode === "edit") {
      setIsloading(true);
      let editData = location.state;
      setMode("edit");
      setAllValues(editData);
      let imgs = [];
      let vids = [];
      editData.images.map((data) => {
        if (data.type === 0) {
          imgs.push(data);
        } else if (data.type === 1) {
          vids.push(data);
        }
      });
      setAllImages(imgs);
      setAllVideos(vids);
      setLat(parseInt(editData.latitude));
      setLong(parseInt(editData.longitude));

      const timer = setTimeout(() => {
        setData(`${editData?.city}, ${editData?.state}, ${editData?.country}`);
        setShowMap(true);
        setIsloading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  };
  // const [localData, setLocalData] = useState({});

  useEffect(() => {
    ethEnabled();
    //   setEditDataAssets();
  }, []);

  let web3 = new Web3();

  const ethEnabled = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information from the MetaMask provider information
      web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.enable();

        metamaskDetails();
        return true;
      } catch (e) {
        if (e.code === -32002) {
          showErrorMsg(
            "Please reopen browser and try again to connect your account"
          );
        }
        // User denied access
        return false;
      }
    } else {
      alert("Please install MetaMask to use this service!");
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
    }

    return false;
  };

  const metamaskDetails = async () => {
    // token address
    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
    // factory address
    const factoryAddress = process.env.REACT_APP_FACTORY_ADDRESS;

    var accs = await web3.eth.getAccounts();

    const accounts = await new web3.eth.getAccounts();
    const NameContract = new web3.eth.Contract(tokenABI, tokenAddress);
    try {
      const balance = await NameContract.methods.balanceOf(accounts[0]).call();

      let value = Web3.utils.fromWei(balance, "ether");
      let finval = Math.floor(value * 1000) / 1000;
      setBalance(finval);
      if (finval < 100) {
        setShow(true);
      }
    } catch (error) {
      // console.log(error);
      setBalance(0);
      setShow(true);
    }

    setAccounts(accs);
  };

  // for map clicks
  const onClick = (e) => {
    // avoid directly mutating state
    setClicks([e.latLng]);
  };
  // for navigation
  const navigate = useNavigate();
  // for file reference input
  const fileInput = useRef();
  // for video reference input
  const videoInput = useRef();

  // upload image function
  const handleImage = async (e) => {
    // console.log(e);
    const file = e.target.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg" ||
      file.type === "image/jpg" ||
      file.type === "image/webp"
    ) {
      setIsUploadingImage(true);
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          let item = { type: 0, path: data.location };
          if (allImages.length <= 15) {
            setAllImages([...allImages, item]);
            setIsUploadingImage(false);
          } else {
            showErrorMsg("Only 15 Images are allowed");
            setIsUploadingImage(false);
          }
        })
        .catch((err) => {
          alert(err);
          setIsUploadingImage(false);
        });
    } else {
      showErrorMsg("File Type not supported");
      setIsUploadingImage(false);
    }
  };

  // upload video function
  const handleVideo = async (e) => {
    const file = e.target.files[0];
    if (file.type === "video/mp4") {
      setIsUploadingVideo(true);
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          generateVideoThumbnails(file, 1)
            .then((thumbs) => {
              let item = { type: 1, path: data.location, thumb: thumbs[0] };
              if (allVideos.length <= 15) {
                setAllVideos([...allVideos, item]);
                setIsUploadingVideo(false);
              } else {
                showErrorMsg("Only 15 Videos are allowed");
                setIsUploadingVideo(false);
              }
            })
            .catch((err) => {
              alert(err);
              setIsUploadingVideo(false);
            });
        })
        .catch((err) => {
          alert(err);
          setIsUploadingVideo(false);
        });
    } else {
      showErrorMsg("File Type not supported");
      setIsUploadingVideo(false);
    }
  };

  // delete image function
  const deleteImage = (url) => {
    let data = allImages.filter(function (data) {
      return data.path !== url;
    });
    setAllImages(data);
  };
  // delete video function
  const deleteVideo = (url) => {
    let data = allVideos.filter(function (data) {
      return data.path !== url;
    });
    setAllVideos(data);
  };

  const checkUserData = () => {
    const storage = getLocalStorage();
    if (!storage) {
      navigate("/signup");
    }
  };

  // setting map data
  const [data, setData] = useState("");
  // setting map data
  useEffect(() => {
    data === "" ? setData("") : setData(data);
  }, [data]);
  // autocomplete for map
  const setLocation = (data) => {
    let location = data?.value?.terms;
    let geoVal;
    if (location.length < 3) {
      showErrorMsg("Please enter more accurate location");
    } else if (location.length > 3) {
      setAllValues({
        ...allValues,
        country: location[3].value,
        state: location[2].value,
        city: location[1].value,
      });
      setData(
        `${location[3].value}, ${location[2].value}, ${location[1].value}`
      );
      geoVal = location[1].value;
    } else {
      setAllValues({
        ...allValues,
        country: location[2].value,
        state: location[1].value,
        city: location[0].value,
      });
      setData(
        `${location[2].value}, ${location[1].value}, ${location[0].value}`
      );
      geoVal = location[0].value;
    }
    setShowMap(true);
    Geocode.fromAddress(geoVal).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLat(lat);
        setLong(lng);
        setAllValues((prev) => ({
          ...prev,
          latitude: lat,
          longitude: lng,
        }));
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // for setting form values
  const changeHandler = (e) => {
    setAllValues((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const mapStyles = {
    container: (provided) => ({
      ...provided,
      display: "inline-block",
      width: "100%",
      minHeight: "1px",
      textAlign: "left",
      border: "none",
      fontSize: "16px",
    }),
    control: (provided) => ({
      ...provided,
      border: "2px solid #cccccc",
      borderRadius: "6px",
      width: width !== null && width < 991 ? "100%" : "90%",
    }),
    input: (provided) => ({
      ...provided,
      minHeight: "1px",
      height: "40px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
      paddingTop: "0",
      paddingBottom: "0",
      color: "#c0c0c0",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      minHeight: "1px",
      height: "24px",
      background: "#fff",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      minHeight: "1px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      //   minHeight: '1px',
      //   height: '40px',
      paddingTop: "0",
      paddingBottom: "0",
    }),
    singleValue: (provided) => ({
      ...provided,
      minHeight: "1px",
      height: "20px",
      paddingBottom: "2px",
      fontWeight: 700,
    }),
    placeholder: (provided) => ({
      ...provided,
      top: "55%",
      color: "#C0C0C0",
      fontWeight: "400",
    }),
  };

  const formSubmit = (e) => {
    e.preventDefault();
    var params = allValues;
    params.mode = mode;
    params.images = allImages.concat(allVideos);
    params.pageComplete = 1;

    if (params.title === undefined || params.title === "") {
      showErrorMsg("Please enter a Property Name");
    } else if (params.city === undefined || params.city === "") {
      showErrorMsg("Please enter location");
    } else if (params.type === undefined || params.type === "") {
      showErrorMsg("Please enter a type of property");
    } else if (params.completed === undefined || params.completed === null) {
      showErrorMsg("Please enter completion status");
    } else if (params.description === undefined || params.description === "") {
      showErrorMsg("Please enter a description of property");
    } else if (allImages.length === 0) {
      showErrorMsg("Please add atleast one image");
    } else if (balance < 100) {
      showErrorMsg("You need 100 BTS Coins to list a property");
    } else {
      setPropertyStorage(params);
      navigate("/add-features", {
        state: params,
      });
    }
  };

  return (
    <>
      {show && (
        <CoinValidationModal
          show={show}
          handleClose={handleClose}
        ></CoinValidationModal>
      )}
      <ListPropertyHeader mode={mode} />
      {isLoading && (
        <div className="spinner-padding">
          <Spinner size={false} />
          <p>Loading....</p>
        </div>
      )}
      {!isLoading && (
        <section className="listproperty_wrap">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-12 listproperty_left">
                <h4>Add basic details</h4>
                <form onSubmit={formSubmit}>
                  <div className="row">
                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>
                        Name of the property<span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input">
                      <input
                        type="text"
                        placeholder="Doran pool villa"
                        name="title"
                        onChange={changeHandler}
                        value={allValues.title}
                      />
                    </div>
                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>
                        Location<span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_inputh">
                      <GooglePlacesAutocomplete
                        debounce={800}
                        apiKey={process.env.REACT_APP_MAPS_KEY}
                        selectProps={{
                          defaultInputValue: data, //set default value
                          onChange: setLocation, //save the value gotten from google
                          placeholder: "Enter Location",
                          styles: mapStyles,
                        }}
                        onLoadFailed={(error) => {}}
                      />
                      {/* <input
                    as="select"
                    component={autoCompleteGoogle}
                    type="text"
                    name="location"
                  /> */}
                    </div>
                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>Country</label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input listproperty_disabled">
                      <input
                        type="text"
                        placeholder="Enter Country"
                        name="country"
                        disabled={true}
                        value={allValues.country}
                      />
                    </div>
                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>State</label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input listproperty_disabled">
                      <input
                        type="text"
                        placeholder="Enter State"
                        name="state"
                        disabled={true}
                        value={allValues.state}
                      />
                    </div>
                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>City</label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input listproperty_disabled">
                      <input
                        type="text"
                        placeholder="Enter City"
                        name="city"
                        disabled={true}
                        value={allValues.city}
                      />
                    </div>
                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>
                        Type of property<span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input">
                      &nbsp;&nbsp;&nbsp;
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          onClick={(e) =>
                            setAllValues({ ...allValues, type: 0 })
                          }
                          checked={
                            allValues.type === "0" || allValues.type === 0
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Residential
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                          onClick={(e) =>
                            setAllValues({ ...allValues, type: 1 })
                          }
                          checked={
                            allValues.type === "1" || allValues.type === 1
                              ? true
                              : false
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          Commercial
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-3 mb-3 listproperty_left_label">
                      <label>Completion status</label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input">
                      <select
                        onChange={(e) =>
                          setAllValues((prev) => ({
                            ...prev,
                            completed: parseInt(e.target.value),
                          }))
                        }
                        value={allValues.completed}
                      >
                        <option value="">Select status</option>
                        <option value="0">Under Construction</option>
                        <option value="1">To be Constructed</option>
                        <option value="2">Completed</option>
                      </select>
                    </div>
                    <div className="col-xl-3 mb-3 listproperty_left_label align-items-start">
                      <label>
                        About property<span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-9 mb-3 listproperty_left_input">
                      <input
                        as="textarea"
                        name="description"
                        value={allValues.description}
                        onChange={changeHandler}
                        placeholder="Add a description about your property"
                      />
                    </div>
                  </div>
                  {showMap ? (
                    <div className="col-12 listproperty__media mb-4 mt-3">
                      <h4>Add location in map</h4>
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-12">
                          <div className="col-xl-12 listproperty_left_label_secondpage mb-3 listproperty_disabled">
                            <label>Lat address</label>
                            <input
                              type="text"
                              placeholder="Enter latitude"
                              name="lat"
                              disabled
                              value={allValues.latitude}
                            />
                          </div>
                          <div className="col-xl-12 listproperty_left_label_secondpage mb-3 listproperty_disabled">
                            <label>Long address</label>
                            <input
                              type="text"
                              placeholder="Enter longitude"
                              name="longitude"
                              disabled
                              value={allValues.longitude}
                            />
                          </div>
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-12">
                          <div className="g-map">
                            <Wrapper
                              apiKey={process.env.REACT_APP_MAPS_KEY}
                              render={render}
                            >
                              <MyMapComponent
                                center={{ lat: lat, lng: long }}
                                zoom={12}
                                isMarkerShown
                                onClick={onClick}
                                clicks={clicks}
                              />
                            </Wrapper>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-12 listproperty__media mb-4">
                    <h4>Add basic details</h4>
                    <p>
                      Add images(You can add images of size less than 2mb each
                      and upto 15 images, in png or jpeg format)
                    </p>
                    <ul>
                      {allImages.length > 0 &&
                        allImages.map((data, index) => (
                          <li key={index}>
                            <div className="upload-btn-wrapper">
                              <div
                                className="media_delete"
                                onClick={(e) => deleteImage(data.path)}
                              >
                                <span>Delete and add new</span>
                                <img src={binImg} alt="" />
                              </div>
                              <button
                                className="btn-file"
                                style={{
                                  backgroundImage: `url(${data.path})`,
                                }}
                              ></button>
                              <input type="file" name="myfile" />
                            </div>
                          </li>
                        ))}
                      <li>
                        <div className="upload-btn-wrapper">
                          <button className="btn-file">
                            <img src={fileImg} alt="" />
                            Add image
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            ref={fileInput}
                            onChange={(event) => handleImage(event)}
                          />
                        </div>
                      </li>
                    </ul>
                    {isUploadingImage && (
                      <div className="position-relative">
                        <div className="loading_overlay">
                          <h1>Uploading...</h1>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-12 listproperty__media __video">
                    <p>
                      Add videos(You can add videos of size less than 20mb each
                      and upto 4 videos, in mp4, avi, 3gp, mov or avi format)
                    </p>
                    <ul>
                      {allVideos.length > 0 &&
                        allVideos.map((data, index) => (
                          <li key={index}>
                            <div className="upload-btn-wrapper">
                              <div
                                className="media_delete"
                                onClick={(e) => deleteVideo(data.path)}
                              >
                                <span>Delete and add new</span>
                                <img src={binImg} alt="" />
                              </div>
                              <button
                                className="btn-file"
                                style={{
                                  backgroundImage: `url(${data.thumb})`,
                                }}
                              ></button>
                              <input type="file" name="myfile" />
                            </div>
                          </li>
                        ))}
                      <li>
                        <div className="upload-btn-wrapper">
                          <button className="btn-file">
                            <img src={vidImg} alt="" />
                            Add video
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            ref={videoInput}
                            onChange={(event) => handleVideo(event)}
                          />
                        </div>
                      </li>
                    </ul>
                    {isUploadingVideo && (
                      <div className="position-relative">
                        <div className="loading_overlay">
                          <h1>Uploading...</h1>
                        </div>
                      </div>
                    )}
                  </div>
                  <br />
                  <p className="note">
                    Note: You can come back later and change the details
                  </p>
                  {!isUploadingImage && !isUploadingVideo && (
                    <div className="col-12 sub-btns">
                      <button
                        type="button"
                        className="cancel-btn"
                        onClick={(e) => navigate("/property")}
                      >
                        Cancel
                      </button>
                      {accounts.length > 0 ? (
                        <button
                          type="submit"
                          disabled={isUploadingImage && isUploadingVideo}
                          className="def-btn"
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="def-btn"
                          onClick={ethEnabled}
                        >
                          Connect Metamask
                        </button>
                      )}
                    </div>
                  )}
                </form>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 listproperty_right ">
                <div className="listproperty_right_in">
                  <span>1</span>
                  <h3>The top most part of your listing</h3>
                  <p>
                    This is the step where you add the basic but the top
                    important details of the property. There’s no optional
                    fileds and you need add the details in a very clear manner
                    in order to get attestion from the other properties listed
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
export default withRouter(AddPropertyDetails);
