/**
 * Import action creator constants & dependencies
 */

import { API_URLS } from "../../configs/urls";
import axios from "axios";
import { getToken } from "../../utils/helper";
const cancelApiRequests = [];

/**
 *  Add New Users
 */
export async function signUpNewUser(params) {
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.AUTH.SIGNUP,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Login Users
 */
export async function loginUser(params) {
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.AUTH.LOGIN,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Forgot Password
 */
export async function forgotPassword(params) {
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.AUTH.FORGOT_PASSWORD,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Reset Password
 */
export async function updatePassword(params) {
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.AUTH.UPDATE_PASSWORD,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  User Details
 */
export async function getUserProfile(params) {
  const token = getToken();
  try {
    const response = await axios({
      method: "get",
      url: API_URLS.AUTH.PROFILE,
      data: params,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.error;
  }
}

/**
 *  Authenticate google
 */
export async function authGoogle(params) {
  const token = getToken();
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.AUTH.GOOGLE_AUTH,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Update profile
 */
export async function updateProfile(params) {
  const token = getToken();
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.AUTH.EDIT_PROFILE,
      data: params,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Deactivate account
 */
export async function deactivateProfile() {
  const token = getToken();
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.AUTH.DEACTIVATE,
      // data: params,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Newsletter Subscribe
 */
export async function subscribeToNewsletter(params) {
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.AUTH.NEWSLETTER_SUBSCRIBE,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
