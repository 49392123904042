import Nav from "react-bootstrap/Nav";
import withRouter from "../../utils/withRouter";
import { useEffect } from "react";
import { getLocalStorage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
const UserHeader = () => {
  let path = window.location.pathname;

  const navigate = useNavigate();

  useEffect(() => {
    checkUserData();
  });

  const checkUserData = () => {
    const storage = getLocalStorage();
    if (storage === null) {
      navigate("/signup");
    }
  };
  return (
    <>
      <section className="profile_header">
        <div className="container">
          <div className="profile_head_in">
            <h2>Blits dashboard</h2>
            <Nav
              activeKey={path}
              // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
              <Nav.Item>
                <Nav.Link href="/my-investments">My investments</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/my-blitscoin">My Blits coins </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/my-listings">My Property listings</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/my-profile">My Profile</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </section>
    </>
  );
};
export default withRouter(UserHeader);
