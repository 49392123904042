import bannerImg from "../../assets/images/home-banner.png";
import aboutImg from "../../assets/images/about-banner.png";
import blitsImg from "../../assets/images/blits-coin.png";
import buildImg from "../../assets/images/Buildings.svg";
import discImg from "../../assets/images/Disc.svg";
import cofeeImg from "../../assets/images/Coffee.svg";
import targetImg from "../../assets/images/Target.svg";
import castImg from "../../assets/images/Screencast.svg";
import mphoeImg from "../../assets/images/Megaphone.svg";
import homeImg from "../../assets/images/ab-bg.svg";
import lineImg from "../../assets/images/line-2.svg";
import faqImg from "../../assets/images/faq.svg";
import homeIcon from "../../assets/images/home-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import RecentListing from "../DetailPage/RecentListing";
import { useEffect, useRef, useState } from "react";
import { getOtherProperties } from "../../modules/listing/actions";
import { Link, useNavigate } from "react-router-dom";
import { showSuccessMsg, showWarningMsg } from "../../utils/notification";
import { getUserProfile } from "../../modules/auth/actions";
import withRouter from "../../utils/withRouter";
import tokenABI from "../../utils/tokenABI";
import Web3 from "web3";
import { getCoins } from "../../modules/blits/actions";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import digitalJournalLogo from "../../assets/images/digital-journal.png";
import benzingaLogo from "../../assets/images/benzinga.svg";
import secureDAppLogo from "../../assets/images/secure-dapp.png";
import mediumLogo from "../../assets/images/medium.svg";
import issuuLogo from "../../assets/images/issuu.svg";
import canadianBusiessLogo from "../../assets/images/canadian-business-today.svg";
import myRealEstateLogo from "../../assets/images/my-real-estate.svg";

function HomePage(props) {
  let preData = props.location.state;
  const navigate = useNavigate();

  const howToBuyRef = useRef(null);
  const aboutSection = useRef(null);
  const introBlits = useRef(null);
  const tokenomicsRef = useRef(null);
  const faqSection = useRef(null);

  const scrollToHowBuy = () => howToBuyRef.current.scrollIntoView();

  const [sidePropertyList, setSideProperyList] = useState([]);

  const [isFetchingSideProperty, setIsFetchingSideProperty] = useState(false);

  const [userName, setUsername] = useState(null);

  const [isLoading, setisLoading] = useState(false);

  const [totalSupply, setTotalSupply] = useState(9000000);

  const [currentSupply, setCurrentSupply] = useState(3400000);

  const [soldCoins, setSoldCoins] = useState({});

  useEffect(() => {
    // console.log("preData", preData);
    if (preData?.scrollRef) {
      setTimeout(() => {
        scrollToSection(preData.scrollRef);
      }, 200);
    }
  }, [preData]);

  const scrollToSection = (section) => {
    switch (section) {
      case "aboutSection":
        aboutSection.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        break;

      case "introBlits":
        introBlits.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        break;

      case "howToBuyRef":
        howToBuyRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        break;

      case "tokenomicsRef":
        tokenomicsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        break;

      case "faqSection":
        faqSection.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        break;

      default:
        return;
    }
  };

  useEffect(() => {
    getAllSideProperty();
  }, []);

  useEffect(() => {
    getUserData();
    getCoinData();
    // ethEnabled();
  }, []);

  const getCoinData = () => {
    getCoins().then((res) => {
      setSoldCoins(res.data.data);
    });
  };

  const getAllSideProperty = () => {
    setIsFetchingSideProperty(true);
    var limit = 4;
    getOtherProperties(limit).then(
      (res) => {
        let list = res.data.data;
        setSideProperyList(list);
        setIsFetchingSideProperty(false);
      },
      (err) => {
        setIsFetchingSideProperty(false);
        console.log(err);
      }
    );
  };

  const getUserData = () => {
    setisLoading(true);
    getUserProfile().then(
      (res) => {
        var user =
          res.data[0].user_name !== null ? res.data[0].user_name : "NA";
        setUsername(user);
        setisLoading(false);
      },
      (err) => {
        setUsername(null);
        console.log(err);
        setisLoading(false);
      }
    );
  };

  const checkAuthandRouteCoin = () => {
    if (userName !== null) {
      navigate("/buytoken");
    } else {
      showWarningMsg("You need to create an account before buying tokens.");
      setTimeout(() => {
        navigate("/signup");
      }, 2000);
    }
  };

  let web3 = new Web3();

  // const ethEnabled = async () => {
  //   if (typeof window.ethereum !== "undefined") {
  //     // Instance web3 with the provided information from the MetaMask provider information
  //     web3 = new Web3(window.ethereum);
  //     try {
  //       // Request account access
  //       await window.ethereum.enable();

  //       getSupply();
  //       return true;
  //     } catch (e) {
  //       // User denied access
  //       return false;
  //     }
  //   } else {
  //     alert("Please install MetaMask to use this service!");
  //     window.open(
  //       "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
  //       "_blank"
  //     );
  //   }

  //   return false;
  // };

  // const getSupply = async () => {
  //   const tokenAddress = "0x452bBde39d3a14965da2448bfCbDcC8BfEDF8c11";

  //   const NameContract = new web3.eth.Contract(tokenABI, tokenAddress);
  //   let coin;
  //   // total blits supply
  //   await NameContract.methods
  //     .totalSupply()
  //     .call()
  //     .then((result) => setTotalSupply(web3.utils.fromWei(result, "ether")));
  // };
  let supPercent = (currentSupply / totalSupply) * 100;
  let soldPercent = (soldCoins.coins_sold / totalSupply) * 100;

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  return (
    <>
      <section className="home_banner">
        <div
          className="home_banner_r_imge"
          style={{ backgroundImage: `url("${bannerImg}")` }}
        ></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 banner_text">
              <h1>
                Invest your
                <br /> money in DAO{" "}
              </h1>
              <p>
                We pride ourselves on the commitment we show to our investors &
                builders. The Blits estates DAO model allows investors to invest
                in a tangible real estate asset and gives them better ROI
                year-on-year(YoY). You will be able to get the opportunity to
                own a percentage of property with all legal compliances handled
                by us within a click of a button.
              </p>
              <div className="col-12 btn_home">
                <button
                  type="button"
                  className="def-btn"
                  onClick={(e) => navigate("/property")}
                >
                  Invest on properties
                </button>
                <button
                  type="button"
                  className="more-det"
                  onClick={scrollToHowBuy}
                >
                  How to invest?
                </button>
              </div>
            </div>
            <div className="col-md-7">
              <div className="how_help_card">
                <h3 className="how_help_title">
                  <img src={homeIcon} alt="home-icon" /> How may we help?
                </h3>
                <div className="how_help_btns">
                  <Link className="def-btn" to="/inquiry/agent">
                    I'm an Agent
                  </Link>
                  <Link className="def-btn" to="/inquiry/seller">
                    I'm a Seller
                  </Link>
                  <Link className="def-btn" to="/inquiry/buyer">
                    I'm a Buyer
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home_listing">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>Our top picks</h2>
            </div>
            <RecentListing
              item={sidePropertyList}
              isFetchingSideProperty={isFetchingSideProperty}
            />
            <div className="col-12">
              <button
                type="button"
                className="def-btn"
                onClick={(e) => navigate("/property")}
              >
                See more properties
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="about_section" ref={aboutSection}>
        <div
          className="home_banner_r_imge"
          style={{ backgroundImage: `url("${aboutImg}")` }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-6"></div>
            <div className="col-xl-6 col-lg-7 col-md-6 banner_text">
              <h3>About Blits Estates? </h3>
              <p>
                We have provided investors with the unique opportunity to
                digitally invest in Real Estate around the world via Fiat. Our
                experienced team selects the best properties and has
                independently verified all properties showcased on our
                marketplace. Whether you are interested in residential or
                commercial properties, come and buy shares of the Top real
                estate worldwide and make decisions regarding their operations.
              </p>
              <div className="col-12 btn_home d-none">
                <button
                  type="button"
                  className="more-det ms-0"
                  onClick={(e) =>
                    showSuccessMsg("We will soon contact you with all details")
                  }
                >
                  Know more
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blits_coin_intro" ref={introBlits}>
        <div className="container">
          <div className="col-xl-10  mx-auto intro_in">
            <div className="row">
              <div className="col-xl-7 col-lg-7 __title">
                <h3>Introducing Blits Coin</h3>
                <p>
                  Blits Coin is a revolutionary concept that entitles you to
                  become shareholders of the DAO and part owners of your
                  property. Blits Coin has made it easier for property builders
                  and owners to obtain project funds. It has also provided a
                  transparent model for investors & builders to get quick
                  returns on their investments. You can now purchase Blits Coin
                  through USD and start investing.
                </p>
                <div className="col-12 intro_btn">
                  <button
                    type="button"
                    className="def-btn"
                    onClick={checkAuthandRouteCoin}
                  >
                    Buy Blits coin
                  </button>
                  <button
                    type="button"
                    className="more-det"
                    onClick={(e) =>
                      navigate("/buytoken", {
                        state: {
                          scrollRef: "whyBlits",
                        },
                      })
                    }
                  >
                    Why Blits?
                  </button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 __blimg">
                <img src={blitsImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why_blits">
        <div className="container">
          <div className="row">
            <h2>Why invest in Blits Estates?</h2>
            <p className="sub_title">
              The real estate industry has always been a stable and profitable
              investment. Blits Estates offer a new way to invest in real estate
              or raise funds for your project with the added benefits of
              blockchain technology. There will be no overhead from an investing
              firm because a DAO is owned by its members. All earnings are
              reinvested or distributed as dividends to all members. Here are
              some reasons why you must invest in Blits Estates
            </p>
          </div>
          <div className="row justify-content-evenly mt-5">
            <div className="_why_icon">
              <img src={buildImg} alt="" />
              <h4>Operate in a transparent manner</h4>
              <p>
                Blits Estates operations are transparent, and all members have
                access to the same information. This allows for more democratic
                decision-making since all members have an equal say.
              </p>
            </div>
            <div className="_why_icon">
              <img src={cofeeImg} alt="" />
              <h4>Use smart contracts</h4>
              <p>
                Smart contracts are the backbone of achieving a consensus in the
                DAO framework. It is a program that executes automatically when
                the conditions typically if-then of the contract are fulfilled.
              </p>
            </div>
            <div className="_why_icon">
              <img src={castImg} alt="" />
              <h4>Potential for high returns</h4>
              <p>
                The potential for high returns is one of the main reasons why
                people are interested in investing in Blits Estates. With the
                added benefits of blockchain technology, there is a lot of
                growth potential.
              </p>
            </div>
            <div className="_why_icon">
              <img src={mphoeImg} alt="" />
              <h4>Enhanced transparency and security</h4>
              <p>
                The use of blockchain technology enhances transparency and
                security. The data is stored on a decentralized network, making
                it more secure. The decentralized nature of the network also
                makes it more resistant to hacks.
              </p>
            </div>
            <div className="_why_icon">
              <img src={discImg} alt="" />
              <h4>Lower costs</h4>
              <p>
                Another benefit of investing in Blits Estates is that the costs
                are lower. There is no need to pay for an investment firm
                because the DAO is owned by its members. All earnings are
                reinvested or distributed as dividends to all members.
              </p>
            </div>
            <div className="_why_icon">
              <img src={targetImg} alt="" />
              <h4>Improved liquidity</h4>
              <p>
                {" "}
                AAnother benefit of investing in Blits Estates is that it is
                easier to sell your stake. Since the data is stored on a
                decentralized network, investors can sell their shares quickly
                and easily.
              </p>
            </div>
          </div>

          <div className="col-12 ic-text">
            <strong>
              DAOs are a new way of investing that comes with a lot of benefits.
              <br /> If you're thinking about investing in Blits Estates, keep
              these things in mind.
            </strong>
          </div>
        </div>
        <img src={homeImg} className="ab_bg" />
      </section>
      <section className="how_blits" ref={howToBuyRef}>
        <div className="container">
          <div className="row">
            <h2>How to buy Blits Coin?</h2>
            <p className="sub_title">
              The process to purchase Blits Coin is straightforward.
            </p>
          </div>
          <div
            className="row mt-5 row_numb"
            style={{ backgroundImage: `url("${lineImg}")` }}
          >
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 how_work">
              <h4>Install MetaMask Wallets</h4>
              <span className="_number">1</span>
              <p>
                Download the MetaMask Chrome Extension. Once installed you will
                see the MetaMask homepage and you can get started.{" "}
              </p>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 how_work">
              <h4>Connect Wallet</h4>
              <span className="_number">2</span>
              <p>
                Follow the installation procedure. Create a password for your
                wallet. We recommend you use a strong password of at least 8
                characters long.{" "}
              </p>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 how_work">
              <h4>Buy Blits Coin</h4>
              <span className="_number">3</span>
              <p>
                You only need to connect the wallet address specified on our
                website to purchase Blits Coin and pay via USD.{" "}
              </p>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 how_work">
              <h4>Start investing</h4>
              <span className="_number">4</span>
              <p>
                After you purchase Blits Coin, you can buy any Property shares
                over the DAO Marketplace.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 text-center mt-4">
            <button
              type="button"
              className="def-btn"
              onClick={(e) => navigate("/buytoken")}
            >
              Buy Blits coin
            </button>
          </div>
        </div>
      </section>

      <section className="scale_roadmap">
        <div className="container">
          <div className="row">
            <h2>Coin Sale Roadmap</h2>
            <p className="sub_title">
              To invest in Blits Estates you must understand the funding
              process.
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-xl-4 col-lg-4 col-md-12 sale_in">
              <span className="number no_activated">1</span>
              <div className="activated_border">
                <h4>Pre-Sale ICO</h4>
                <small>Feb 2, 2023</small>
                <p>
                  {soldCoins.total_coins !== undefined
                    ? numberWithCommas(soldCoins.total_coins * 0.2)
                    : 0}{" "}
                  Tokens
                  <br />$
                  {soldCoins.coins_sold !== undefined
                    ? numberWithCommas(soldCoins.coins_sold)
                    : 0}{" "}
                  Raised
                </p>
                <span className="pre_text">
                  Pre-sale ICO or Initial Coin Offering is similar to an IPO it
                  is a wonderful opportunity for you to invest as a VIP investor
                  in Blits Estates with discounts of up to 20% while it is still
                  in the development stage before Public Release.
                </span>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 sale_in">
              <span className="number">2</span>
              <h4>Public Release</h4>
              <small>Oct 2, 2023</small>
              <p>
                {soldCoins.total_coins !== undefined
                  ? numberWithCommas(soldCoins.total_coins * 0.3)
                  : 0}{" "}
                Tokens
                <br />
                $0 Raised
              </p>
              <span className="pre_text">
                Now that the DAO is operational, you can start buying & selling
                tokens in exchange for real-estate shares. These shares will act
                as a percentage of the property in real-time.
              </span>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 sale_in">
              <span className="number">3</span>
              <h4>Growth Release</h4>
              <small>May 2, 2024</small>
              <p>
                {soldCoins.total_coins !== undefined
                  ? numberWithCommas(soldCoins.total_coins * 0.5)
                  : 0}{" "}
                Tokens
                <br />
                $0 Raised
              </p>
              <span className="pre_text">
                The focus of this stage is to continue acquiring properties and
                building DAO's market share. The goal is to have a diversified
                portfolio of properties that will generate income and
                appreciation over time for builders and investors.
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="tocken_intro" ref={tokenomicsRef}>
        <div className="container">
          <div className="tocken_wrap">
            <h3>Tokenomics</h3>
            <p className="sub_title">
              The maximum supply of Blits Coin(BTS) will be 450 MILLION which
              will leave enough investment growth for our DAO & real estate
              investors to offer more on the marketplace in order to continue
              growing the Real Estate investment portfolio.
            </p>
            <div className="col-12 tocken_sects">
              <h6>Token offering</h6>
              <div className="row">
                <div className="init_title">
                  <small>Initial tokens</small>
                  {numberWithCommas(currentSupply)}
                </div>
                <div className="init_range">
                  <span id="rangeValue">{supPercent.toFixed(2)}%</span>
                  <input
                    className="range"
                    type="range"
                    value={supPercent.toFixed(2)}
                    min="0"
                    max="100"
                  />
                </div>
                <div className="init_total">
                  <small>Total tokens</small>
                  {numberWithCommas(totalSupply)}
                </div>
              </div>
            </div>

            <div className="col-12 tocken_sects">
              <h6>Current token sale status</h6>
              <div className="row">
                <div className="init_title">
                  <small>Token sold</small>
                  {soldCoins.coins_sold !== undefined
                    ? numberWithCommas(soldCoins.coins_sold)
                    : 0}
                </div>
                <div className="init_range">
                  <span
                    id="rangeValue"
                    className={
                      soldPercent.toFixed(0) < 20 ? "text-black" : "text-white"
                    }
                  >
                    {soldPercent.toFixed(2)}%
                  </span>
                  <input
                    className="range"
                    type="range"
                    value={soldPercent.toFixed(2)}
                    min="0"
                    max="100"
                  />
                </div>
                <div className="init_total">
                  <small>In market</small>
                  {numberWithCommas(totalSupply)}
                </div>
              </div>
            </div>

            {/* <div className="col-12 tocken_sects">
              <h6>DAO token hold</h6>
              <div className="row">
                <div className="init_title">
                  <small>Token hold</small>
                  5,80,000
                </div>
                <div className="init_range">
                  <span id="rangeValue">28%</span>
                  <input
                    className="range"
                    type="range"
                    value="60"
                    min="0"
                    max="100"
                  />
                </div>
                <div className="init_total">
                  <small>Futre release</small>
                  10,00,000
                </div>
              </div>
            </div> */}

            <div className="col-12 tocken_sects">
              <h6>DAO Token Hold</h6>
              <div className="row">
                <div className="init_title">
                  <small>Token Hold</small>
                  {numberWithCommas(totalSupply)}
                </div>
                <div className="init_range">
                  <span
                    id="rangeValue"
                    className={`
                      ${
                        ((totalSupply / 450000000) * 100).toFixed(0) < 20
                          ? "text-black"
                          : "text-white"
                      }`}
                  >
                    {((totalSupply / 450000000) * 100).toFixed(2)}%
                  </span>
                  <input
                    className="range"
                    type="range"
                    value={((totalSupply / 450000000) * 100).toFixed(2)}
                    min="0"
                    max="100"
                  />
                </div>
                <div className="init_total">
                  <small>Future Release</small>
                  {numberWithCommas(450000000)}
                </div>
              </div>
            </div>

            {/* <p className="sub_title text-left mt-5">
              *The Minimum Transaction Fee is 1%
            </p>
            <p className="sub_title  text-left mt-4">
              *The Maximum listing fee is 100$
            </p> */}

            <div className="col-12 text-center mt-4 no-float">
              <button
                type="button"
                className="def-btn"
                onClick={(e) => navigate("/buytoken")}
              >
                Buy Blits coin
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="faq" ref={faqSection}>
        <div className="container">
          <div className="col-xl-10 mx-auto">
            <div className="row">
              <h2>Frequently Asked Questions</h2>

              <div className="col-xl-5 col-lg-6 col-md-6 col-12 faq_img">
                <img src={faqImg} alt="" />
              </div>
              <div className="col-xl-7 col-lg-6 col-md-6 col-12 ">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      What is the BlitsEstates DAO Real-Estate model?{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      DAO real estate is similar to traditional real estate
                      investing with a layer of blockchain but only
                      decentralized, allowing investors to participate in real
                      estate through digital payments and through different
                      ownership/partnership models. We are helping society to
                      get the investment in this exciting new technology of
                      Blockchain which is reliable & secure with
                      Proof-of-ownership.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How can I buy Blits Coin?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can purchase Blits Coin from blitsestates.com after
                      connecting the MetaMask wallet, and purchase the coin
                      using - USD.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Why should I choose Blistestates.com?
                    </Accordion.Header>
                    <Accordion.Body>
                      BlitsEstates has a wealth of experience in the property
                      market and financing. Our model ensures that the best
                      projects are approved by consensus and transparently. We
                      work with our investors to ensure high returns.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Who do I contact in case I face any issues?
                    </Accordion.Header>
                    <Accordion.Body>
                      BlitsEstates is happy to assist you with any issues you
                      may have. Our customer service department operates 24x7,
                      mail us at contact@blitsestates.com
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I verify the documents shown by the seller are
                      genuine?
                    </Accordion.Header>
                    <Accordion.Body>
                      We verify all contracts independently before being
                      converted into smart contracts. This makes the documents
                      immutable and impossible to forge. You can also examine
                      the contract after downloading it for any discrepancies
                      before investing.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      How many properties can I own?
                    </Accordion.Header>
                    <Accordion.Body>
                      You can own several properties depending on the amount you
                      are willing to invest and the shares available.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How to invest?</Accordion.Header>
                    <Accordion.Body>
                      If you're interested in investing in Blits Estates, there
                      are a few things you need to know.
                      <ul>
                        <li>
                          You need to purchase Blits Coin(BTS) using USD. You
                          can easily buy the coin after connecting to a MetaMask
                          wallet.
                        </li>
                        <li>
                          You can use the BTS coin to purchase shares of the
                          project listed on the DAO website Marketplace. Your
                          shares will be proportional to the amount you invest.{" "}
                        </li>
                        <li>
                          Once you purchase shares, you can start participating
                          in the DAO property.{" "}
                        </li>
                        <li>
                          Your shares entitle you to the property holding &
                          profit share on the investment.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      What are share-based DAOs?
                    </Accordion.Header>
                    <Accordion.Body>
                      As a share-based DAO organization, the ownership is based
                      on the number of shares each member holds. This type of
                      organization allows for voting rights to be proportionate
                      to the number of shares each member holds. In this case,
                      you own shares of the property listed on the marketplace
                      through Blits Coin.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="as-seen-on-sec common-sec pt-0">
        <div className="container">
          <div className="sec-head text-center">
            <h3 className="sec-title">As Seen On Top Publications</h3>
          </div>
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={30}
            slidesPerView={5}
            navigation={true}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              568: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1199: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
            }}
            className="as-seen-on-slider"
          >
            <SwiperSlide className="as-seen-on-slide">
              <a
                target="_blank"
                href="https://www.digitaljournal.com/pr/news/binary-news-network/blitsestates-redefining-real-estate-investments-with-blitscoin-bts-"
              >
                <div className="as-seen-on-slide-img">
                  <img src={digitalJournalLogo} alt="" />
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide className="as-seen-on-slide">
              <a
                target="_blank"
                href="https://www.benzinga.com/pressreleases/23/08/33583297/blitsestates-unveils-blitscoin-a-game-changer-for-real-estate-and-crypto-investors"
              >
                <div className="as-seen-on-slide-img">
                  <img src={benzingaLogo} alt="" />
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide className="as-seen-on-slide">
              <a
                target="_blank"
                href="https://securedapp.in/blits-estates-the-future-of-real-estate-investing/"
              >
                <div className="as-seen-on-slide-img">
                  <img src={secureDAppLogo} alt="" />
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide className="as-seen-on-slide">
              <a
                target="_blank"
                href="https://medium.com/@blitsestates.web3/a-new-way-to-invest-in-real-estate-blitsestates-3a3667956bc3"
              >
                <div className="as-seen-on-slide-img">
                  <img src={mediumLogo} alt="" />
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide className="as-seen-on-slide">
              <a target="_blank" href="https://issuu.com/blitsestates">
                <div className="as-seen-on-slide-img">
                  <img src={issuuLogo} alt="" />
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide className="as-seen-on-slide">
              <a
                target="_blank"
                href="https://www.canadianbusinesstoday.com/article/640718763-blits-estates-revolutionizes-property-ownership-with-innovative-web3-technology"
              >
                <div className="as-seen-on-slide-img">
                  <img src={canadianBusiessLogo} alt="" />
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide className="as-seen-on-slide">
              <a
                target="_blank"
                href="https://www.myrealestategazette.com/article/640718763-blits-estates-revolutionizes-property-ownership-with-innovative-web3-technology"
              >
                <div className="as-seen-on-slide-img">
                  <img src={myRealEstateLogo} alt="" />
                </div>
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
}
export default withRouter(HomePage);
