import { useNavigate } from "react-router-dom";
import successIcon from "../../assets/images/success.svg";

function InquiryThankYou() {
  const navigate = useNavigate();
  return (
    <section className="reg-wrap">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-12 mx-auto">
            <div className="notfound-block">
              <div className="notfound-icon-img">
                <img src={successIcon} alt="" />
              </div>
              <h1>Thank you!</h1>
              <p>Your message has been received We will update you shortly.</p>
              <button
                type="button"
                className="def-btn float-none mt-4"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default InquiryThankYou;
