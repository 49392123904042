import twitter from "../../assets/images/twitter.svg";
import insta from "../../assets/images/Vector.svg";
import linkin from "../../assets/images/Vector-1.svg";
import fab from "../../assets/images/Vector-3.svg";
import tele from "../../assets/images/telegram-app.svg";
import whatImg from "../../assets/images/whatsapimg.svg";
import youtubeImg from "../../assets/images/youtube.svg";
import atImg from "../../assets/images/at.svg";
import phoneImg from "../../assets/images/phone.svg";
import usaImg from "../../assets/images/united-states-of-america.png";
import brochurePdf from "../../assets/pdf/design-real-estate-brochure.pdf";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";
import {
  showErrorMsg,
  showSuccessMsg,
  showWarningMsg,
} from "../../utils/notification";
import { subscribeToNewsletter } from "../../modules/auth/actions";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, [toggleVisible]);

  const newsletterFormSubmit = (values, { setSubmitting, resetForm }) => {
    var params = {
      name: values.name,
      email: values.email,
    };
    subscribeToNewsletter(params).then(
      (res) => {
        setSubmitting(false);
        if (res?.statuscode === 200) {
          resetForm({ values: "" });
          showSuccessMsg(res?.message);
        } else {
          showWarningMsg(res?.message);
        }
      },
      (err) => {
        setSubmitting(false);
        showErrorMsg(err.message);
      }
    );
  };

  const newsletterSchema = Yup.object().shape({
    name: Yup.string().required("Please enter a name"),
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
  });

  const newsletterValuesSchema = () => {
    return {
      name: "",
      email: "",
    };
  };

  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md col-12">
              <div className="row align-items-center flex-wrap justify-content-md-start justify-content-center">
                <div className="col-auto soc-in">
                  <a
                    href="https://twitter.com/BlitsEstates"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={twitter} />
                  </a>
                </div>
                <div className="col-auto soc-in">
                  <a
                    href="https://www.instagram.com/blitsestates/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={insta} />
                  </a>
                </div>

                <div className="col-auto soc-in">
                  <a
                    className="tele-icon"
                    href="https://t.me/blitsestates"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={tele} />
                  </a>
                </div>
                <div className="col-auto soc-in">
                  <a
                    href="https://www.linkedin.com/company/blits-estates/about/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkin} />
                  </a>
                </div>
                <div className="col-auto soc-in">
                  <a
                    href="https://www.facebook.com/Blits-Estates-107173008906980"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={fab} />
                  </a>
                </div>
                <div className="col-auto soc-in">
                  <a
                    href="https://www.youtube.com/@BlitsEstates"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={youtubeImg} width={36} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-auto col-12">
              <div className="footer-newsletter-form">
                <h5 className="footer-newsletter-title">
                  Subscribe Our Newsletter
                </h5>
                <Formik
                  initialValues={newsletterValuesSchema()}
                  validationSchema={newsletterSchema}
                  onSubmit={newsletterFormSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row align-items-center g-0">
                        <div className="col-sm">
                          <Field
                            type="text"
                            placeholder="Enter your name"
                            className="form-control"
                            name="name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm mt-sm-0 mt-3 ms-sm-3 ms-0">
                          <Field
                            type="email"
                            placeholder="Enter your email"
                            className="form-control"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <button
                          type="submit"
                          className="def-btn float-none"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && <Spinner size={true} />}
                          {!isSubmitting && <>Send It</>}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid p-copy pt-3 pb-3">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 mx-auto text-center">
                <div className="row align-items-center">
                  <div className="col-xl-12 ft-contact">
                    <img alt="US flag" src={usaImg} />
                    <h5>Delaware</h5>
                    <p className="text-white">
                      651 N Broad St, Suite 201, <br />
                      Middletown Delaware 19709
                    </p>
                  </div>
                  <div className="text-center my-3">
                    <a
                      href={brochurePdf}
                      target="_blank"
                      className="download-brochure-link"
                    >
                      Download Brochure
                    </a>
                  </div>
                  <div className="col-xl-12 ft-contact mb-2">
                    <div className="blits-addr-cnt">
                      <a
                        href="https://wa.me/+14099953405"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img alt="whatsapp icon" src={whatImg} /> +1 (409)
                        995-3405
                      </a>
                      <a
                        href="mailto:contact@blitsestates.com"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img alt="email icon" src={atImg} />{" "}
                        contact@blitsestates.com
                      </a>
                      <a
                        href="tel:+14099953405"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img alt="phone icon" src={phoneImg} /> +1 (409)
                        995-3405
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid sk">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-3 col-md-12 privacy_link">
                All Rights Reserved @ Blits Estates 2020-{currentYear}
              </div>
              <div className="col-xl-8 col-lg-8 col-md-12 privacy_link">
                <a onClick={() => navigate("/california-terms")}>
                  California Privacy Notice
                </a>{" "}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a onClick={() => navigate("/terms")}>
                  Terms &amp; Conditions
                </a>{" "}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a onClick={() => navigate("/privacy-notice")}>
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {visible && (
        <button
          type="button"
          className="scroll-to-top def-btn"
          onClick={() => scrollToTop()}
        >
          <span></span>
        </button>
      )}
    </>
  );
};

export default Footer;
