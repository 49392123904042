import { GoogleLogin } from "react-google-login";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { gapi } from "gapi-script";
import { authGoogle, loginUser } from "../../modules/auth/actions";
import { getLocalStorage, setLocalStorage } from "../../utils/helper";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { useEffect, useState } from "react";
import OpenEye from "../../assets/images/eye-solid.svg";
import CloseEye from "../../assets/images/eye-slash-solid.svg";
import Spinner from "../../components/Loader";

function LoginPage() {
  // google client id
  const clientId =
    "593874054451-dvi993l9i70kos5prqhtj8n94h5vidvf.apps.googleusercontent.com";

  const navigate = useNavigate();

  const [viewpassword, setViewPassword] = useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
    password: Yup.string()
      .min(
        8,
        "Please enter a password containing at least 1 upper case, 1 lower case, 1 numeric, and 1 special character."
      )
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
      )
      .required("Required"),
  });

  const loginValuesSchema = () => {
    return {
      email: "",
      password: "",
    };
  };

  const loginUserNow = (values, { setSubmitting, resetForm }) => {
    var params = {
      email: values.email,
      password: values.password,
    };
    loginUser(params).then(
      (res) => {
        setLocalStorage(res);
        showSuccessMsg("Logged in");
        setSubmitting(false);
        navigate("/");
        window.location.reload(true);
      },
      (err) => {
        setSubmitting(false);
        showErrorMsg(err.message);
      }
    );
  };

  useEffect(() => {
    const setAuth = async () => {
      await gapi.load(gapi, clientId, "email");
    };
    setAuth();
  }, []);

  useEffect(() => {
    checkUserData();
  });

  const checkUserData = () => {
    const storage = getLocalStorage();
    if (storage !== null) {
      navigate("/property");
    }
  };

  // Google auth code
  const onSuccess = (response) => {
    // const { socialCallback } = this.props;
    var params = {
      provider: "google",
      access_token: response.tokenObj.id_token,
    };
    if (response) {
      authGoogle(params).then(
        (res) => {
          setLocalStorage(res);
          showSuccessMsg("Logged in");
          navigate("/");
          window.location.reload(true);
        },
        (err) => {
          if (err?.statuscode === 400) {
            showErrorMsg(
              "Your account has been deactivated. Please contact the support team"
            );
          } else {
            showErrorMsg(
              "We were not able to authenticate via google. Please try another way."
            );
          }
        }
      );
    }
  };

  return (
    <section className="reg-wrap">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-12 mx-auto">
            <div className="reg-in">
              <h1>Welcome back</h1>
              <p>Login with your google account</p>
              <GoogleLogin
                clientId={clientId}
                render={(renderProps) => (
                  <button
                    type="button"
                    className="g-btn"
                    onClick={renderProps.onClick}
                  >
                    <svg
                      width="18"
                      height="17"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 8.5625H16C16 10.0808 15.4385 11.5521 14.4111 12.7257C13.3837 13.8993 11.954 14.7027 10.3656 14.9989C8.77726 15.2951 7.12848 15.0658 5.70022 14.3501C4.27196 13.6344 3.1526 12.4766 2.53285 11.0739C1.91309 9.67116 1.83131 8.1104 2.30142 6.65751C2.77153 5.20462 3.76446 3.94949 5.11101 3.10599C6.45756 2.26249 8.07443 1.88279 9.68612 2.03161C11.2978 2.18043 12.8046 2.84854 13.9497 3.92212"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Continue with Google
                  </button>
                )}
                onSuccess={onSuccess}
                onFailure={(response) => console.log(response)}
                cookiePolicy={"single_host_origin"}
              />
              <p>Or login with your email and password</p>

              <h3>
                New to Blits Estate?<Link to="/signup">Signup free</Link>
              </h3>

              <Formik
                initialValues={loginValuesSchema()}
                validationSchema={loginSchema}
                onSubmit={loginUserNow}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 mb-3">
                        <label>Email id</label>
                        <Field
                          type="email"
                          placeholder="binseva@gmail.com"
                          className="form-control"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-msg pl-2"
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 mb-3">
                        <label>Password</label>

                        <div className="d-flex">
                          <Field
                            type={!viewpassword ? "password" : "text"}
                            placeholder="**************"
                            className="form-control"
                            name="password"
                          />
                          <div
                            className="eye-icon"
                            onClick={(e) => setViewPassword(!viewpassword)}
                          >
                            <img
                              src={!viewpassword ? CloseEye : OpenEye}
                              alt=""
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-msg pl-2"
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 mb-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="option1"
                          />
                          <label className="form-check-label">
                            {" "}
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 mb-3">
                        <p className="terms">
                          By creating an account, I’m accepting the{" "}
                          <span onClick={(e) => navigate("/terms")}>
                            terms and conditions
                          </span>
                        </p>
                      </div>
                      {/* For login page */}
                      <div className="col-xl-12 col-lg-12 for-login">
                        <Link to="/forgot-password" className="pl-text-btn">
                          Forgot password?
                        </Link>
                        <button
                          type="submit"
                          className="def-btn"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && <Spinner size={true} />}
                          {!isSubmitting && <>Log in</>}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
