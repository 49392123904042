import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Spinner from "../../components/Loader";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import whyChooseBanner from "../../assets/images/why-choose-banner.jpg";
import ratingIcon from "../../assets/images/rating.svg";
import benefitsIcon1 from "../../assets/images/web3-real-estate.svg";
import benefitsIcon2 from "../../assets/images/unmatched-investment.svg";
import benefitsIcon3 from "../../assets/images/web3-investment-website.svg";
import benefitsIcon4 from "../../assets/images/blitscoin-integration.svg";
import benefitsIcon5 from "../../assets/images/secure-transparent.svg";
import benefitsIcon6 from "../../assets/images/expert-guidance.svg";
import quoteIcon from "../../assets/images/quote.svg";
import { addAgentInquiry } from "../../modules/inquiry/actions";
import { useNavigate } from "react-router-dom";

function InquiryAgentPage() {
  const navigate = useNavigate();

  const agentFormSubmit = (values, { setSubmitting, resetForm }) => {
    var params = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      cityName: values.cityName,
      howManyDealCloseYearly: values.howManyDealCloseYearly,
    };
    addAgentInquiry(params).then(
      (res) => {
        setSubmitting(false);
        if (res?.statuscode === 200) {
          resetForm({ values: "" });
          navigate("/inquiry/thank-you");
        } else {
          showErrorMsg(res?.message);
        }
      },
      (err) => {
        setSubmitting(false);
        showErrorMsg(err.message);
      }
    );
  };

  const agentSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter a first name"),
    lastName: Yup.string().required("Please enter a last name"),
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
    phoneNumber: Yup.string().required("Please enter a phone number"),
    cityName: Yup.string().required("Please enter a city name"),
    howManyDealCloseYearly: Yup.string().required(
      "Please select yearly closed deals"
    ),
  });

  const agentValuesSchema = () => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      cityName: "",
      howManyDealCloseYearly: "",
    };
  };

  return (
    <>
      <section className="inquiry-sec common-sec" id="inquiryForm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="inquiry-con">
                <div className="sec-head">
                  <h5 className="sec-title title-sm">Are you an Agent?</h5>
                  <p>Please fill this form!</p>
                </div>
                <Formik
                  initialValues={agentValuesSchema()}
                  validationSchema={agentSchema}
                  onSubmit={agentFormSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label>First name</label>
                          <Field
                            type="text"
                            placeholder="Enter your first name"
                            className="form-control"
                            name="firstName"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Last name</label>
                          <Field
                            type="text"
                            placeholder="Enter your last name"
                            className="form-control"
                            name="lastName"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Email id</label>
                          <Field
                            type="email"
                            placeholder="binseva@gmail.com"
                            className="form-control"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Phone number</label>
                          <Field
                            type="tel"
                            placeholder="Enter your phone number"
                            className="form-control"
                            name="phoneNumber"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Location (City Name)</label>
                          <Field
                            type="text"
                            placeholder="Enter your city name"
                            className="form-control"
                            name="cityName"
                          />
                          <ErrorMessage
                            name="cityName"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>How many deals do you close per year?</label>
                          <Field
                            type="text"
                            placeholder="Enter your deals"
                            className="form-control"
                            name="howManyDealCloseYearly"
                          />
                          <ErrorMessage
                            name="howManyDealCloseYearly"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-lg-12 text-center">
                          <button
                            type="submit"
                            className="def-btn float-none"
                            disabled={isSubmitting}
                          >
                            {isSubmitting && <Spinner size={true} />}
                            {!isSubmitting && <>Send Request</>}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-sec common-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="why-choose-img">
                <img src={whyChooseBanner} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-con">
                <div className="sec-head">
                  <h5 className="sec-title">Why Choose Blits Estates?</h5>
                </div>
                <div className="cms-con">
                  <p>
                    Are you interested in making an investment in the next,
                    expanding real estate market? The leading Web3 Real Estates
                    Marketplace, Blits Estates can be your best option.
                    Investing in real estate with Blits coins opens up a world
                    of chances and advantages as the globe moves towards the era
                    of blockchain technology and decentralized banking.
                  </p>
                </div>
                <a href="#inquiryForm" className="def-btn ms-0">
                  Inquiry Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-benefits-sec common-sec">
        <div className="container">
          <div className="sec-head text-center">
            <h3 className="sec-title">Our Benefits</h3>
          </div>
          <div className="our-benefits-boxes">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon1} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Web3 Real Estates Marketplace
                    </h4>
                    <p>
                      Blits Estates operates on the cutting-edge Web3
                      technology, leveraging the power of blockchain and smart
                      contracts to bring you a secure, transparent, and
                      efficient real estate marketplace.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon2} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Unmatched Investment Potential
                    </h4>
                    <p>
                      Investing in real estate has always been a lucrative
                      venture, but with Blits Estates, you can take it to the
                      next level.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon3} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Web3 Investment Website
                    </h4>
                    <p>
                      Our platform is designed exclusively for Web3 enthusiasts
                      and forward-thinking investors. Our platform is designed
                      exclusively for Web3 enthusiasts and forward-thinking
                      investors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon4} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Blits coin Integration
                    </h4>
                    <p>
                      As the native cryptocurrency of the platform, Blitscoin
                      unlocks numerous advantages for investors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon5} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Secure and Transparent
                    </h4>
                    <p>
                      With blockchain technology underpinning the entire
                      process, you can trust Blits Estates for its robust
                      security and complete transparency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon6} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Expert Guidance from Seasoned Agents
                    </h4>
                    <p>
                      Our team of experienced real estate agents is always at
                      your service, ready to provide valuable insights and help
                      you make informed investment decisions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="clientSaySec common-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="sec-head text-center">
                <h3 className="sec-title">What Our Clients Say?</h3>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            navigation={true}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              568: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1199: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="clientSaySlider"
          >
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blitsestates has quickly become my go-to platform for listing
                properties. Listing my clients' homes has significantly
                increased visibility, and its modern design makes their
                properties stand out, while virtual tours make purchasing
                experiences seamless for potential buyers—it is truly
                indispensable! For my real estate business!
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Pablo Baird</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blitsestates has revolutionized my real estate brokerage
                practice. Their intuitive website creates an effortless user
                experience for both buyers and sellers.
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Joni Chan</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blitsestates was an outstanding partner during my home buying
                experience; their professional approach and customer
                satisfaction focus ensured an enjoyable, rewarding journey!
                Highly recommended!
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Molly Chapman</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blitsestates' customer support is unmatched; I know this from
                first-hand experience as I work alongside their team as an
                agent. From initial inquiry through contract completion,
                Blitsestates makes certain their clients' needs are fulfilled
                efficiently and promptly.
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Randolph McGrath</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="faqSec common-sec pt-0">
        <div className="container">
          <div className="sec-head text-center">
            <h3 className="sec-title">FAQ’S</h3>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="accordion" data-wow-delay="0.1s" id="faqAcordian">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-1"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What are the benefits of using Web 3.0 for property
                      transactions?
                    </button>
                  </div>
                  <div
                    id="faq-1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          With Web 3.0, property transactions become faster,
                          cost-effective, and less prone to fraud. The use of
                          smart contracts automates various stages of the
                          transaction, reducing the need for intermediaries and
                          paperwork.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-2"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How long does it typically take to sell a property?
                    </button>
                  </div>
                  <div
                    id="faq-2"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          The time it takes to sell a property can vary
                          depending on various factors, including the property's
                          condition, location, market demand, and pricing. It is
                          essential to work closely with your real estate agent
                          to determine a competitive listing price and implement
                          effective marketing strategies to attract potential
                          buyers. They will guide you through the process and
                          provide realistic expectations based on the current
                          market conditions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-3"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      What is Blits Estates, and how does it differ from
                      traditional real estate agents?
                    </button>
                  </div>
                  <div
                    id="faq-3"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          Blits Estates is a pioneering Web 3.0 real estate
                          platform that leverages cutting-edge technologies like
                          blockchain and smart contracts to revolutionize
                          property transactions. Unlike traditional real estate
                          agents, Blits Estates offers a secure, transparent,
                          and efficient environment for buying, selling, and
                          fractional ownership of properties.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InquiryAgentPage;
