import Nav from "react-bootstrap/Nav";

function ListPropertyHeader({ mode }) {
  let formMode = mode;
  let path = window.location.pathname;
  return (
    <section className="listprop_header_wrap">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-sm-12 listprop_header_title">
            <h5>{formMode === "edit" ? "Edit" : "List"} your property</h5>
          </div>

          <div className="col-xl-6 col-lg-9 col-sm-12 listprop_header_nav">
            <Nav
              // activeKey="#tab1"
              activeKey={`#${
                path === "/add-property"
                  ? "tab1"
                  : path === "/add-features"
                  ? "tab2"
                  : path === "/add-costing"
                  ? "tab3"
                  : "tab4"
              }`}
              // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
              <Nav.Item>
                <Nav.Link
                  href="#tab1"
                  className={
                    path === "/add-features" ||
                    path === "/add-costing" ||
                    path === "/property-preview"
                      ? "nav-link-completed successtext"
                      : null
                  }
                >
                  Basic details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="#tab2"
                  className={
                    path === "/add-costing" || path === "/property-preview"
                      ? "nav-link-completed successtext"
                      : null
                  }
                >
                  More details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="#tab3"
                  className={
                    path === "/property-preview"
                      ? "nav-link-completed successtext"
                      : null
                  }
                >
                  Share and costing
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#tab4">Publishing</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="col-xl-3 col-lg-3 col-sm-12 listprop_header_small">
            <p>
              {path === "/add-property"
                ? "You’re 3 steps away from listing"
                : path === "/add-features"
                ? "You’re 2 steps away from listing"
                : path === "/add-costing"
                ? "You’re 1 steps away from listing"
                : "You’re almost done"}{" "}
            </p>
          </div>
          {path === "/property-preview" ? (
            <div className="mt-3 listprop_header_title">
              <h5>Check the preview and publish your property</h5>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default ListPropertyHeader;
