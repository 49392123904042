const PREFIX = {
  BLITS: process.env.REACT_APP_BASE_URL,
};

/**
 * URLS
 */
export const API_URLS = {
  /**
   * Auth Url's
   */
  AUTH: {
    LOGIN: PREFIX.BLITS + "/authenticate",
    SIGNUP: PREFIX.BLITS + "/register",
    GOOGLE_AUTH: PREFIX.BLITS + "/authenticategoogle",
    PROFILE: PREFIX.BLITS + "/user",
    EDIT_PROFILE: PREFIX.BLITS + "/updateuser",
    FORGOT_PASSWORD: PREFIX.BLITS + "/forgot-password",
    UPDATE_PASSWORD: PREFIX.BLITS + "/update-password",
    DEACTIVATE: PREFIX.BLITS + "/deactivate",
    NEWSLETTER_SUBSCRIBE: PREFIX.BLITS + "/subscriptions/add",
  },

  LISTING: {
    AMENITIES: PREFIX.BLITS + "/amenities",
    LOCATIONS: PREFIX.BLITS + "/locations",
    PROPERTIES: PREFIX.BLITS + "/listing",
    PROPERTY_DETAIL: PREFIX.BLITS + "/details",
    OTHER_PROPERTIES: PREFIX.BLITS + "/sideproperties",
    CREATE_PROPERTY: PREFIX.BLITS + "/listproperty",
    EDIT_PROPERTY: PREFIX.BLITS + "/editproperty",
    GET_SHARES: PREFIX.BLITS + "/posttransactions",
  },

  DASHBOARD: {
    USER_LISTING: PREFIX.BLITS + "/mylisting",
    USER_INVESTMENT: PREFIX.BLITS + "/myinvestment",
  },

  BLITS: {
    CURRENT_PRICING: PREFIX.BLITS + "/blitsdata",
    PAYMENT: PREFIX.BLITS + "/pay",
    ADD_WALLET: PREFIX.BLITS + "/addaddress",
    TRANSACTIONS: PREFIX.BLITS + "/mytransactions",
    COINSBALANCE: PREFIX.BLITS + "/coinbalance",
  },

  INQUIRY: {
    AGENT: PREFIX.BLITS + "/agents/add",
    BUYER: PREFIX.BLITS + "/investments/add",
    SELLERS: PREFIX.BLITS + "/sellers/add",
  },

  BLOGS: {
    LISTING: PREFIX.BLITS + "/blogs",
  },
};
