import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBlogs, getSingleBlog } from "../../modules/blogs/actions";
import { showErrorMsg } from "../../utils/notification";
import { Spinner } from "react-bootstrap";
import moment from "moment";

function BlogDetailsPage() {
  const { id } = useParams();

  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getBlog();
  }, []);

  const getBlog = () => {
    getSingleBlog(id).then(
      (res) => {
        setLoading(false);
        if (res?.statuscode === 200) {
          setBlog(res.data);
        } else {
          showErrorMsg(res?.message);
        }
      },
      (err) => {
        setLoading(false);
        showErrorMsg(err.message);
      }
    );
  };

  return (
    <>
      {!loading && blog && (
        <section className="blog-sec common-sec">
          <div className="container">
            <h1 className="blog-details-title">{blog?.title}</h1>
            <p className="blog-details-date">
              {moment(blog?.created_at).format("DD MMM YYYY")}
            </p>
            <div className="blog-details-img">
              <img src={blog?.image} alt="" />
            </div>
            <div
              className="cms-con"
              dangerouslySetInnerHTML={{ __html: blog?.description }}
            ></div>
            <div className="blog-box-tags">
              {blog?.tags?.split(",")?.map((item, index) => (
                <span className="blog-box-tag" key={index}>
                  {item}
                </span>
              ))}
            </div>
          </div>
        </section>
      )}
      {loading && (
        <div className="text-center">
          <Spinner size={true} />
        </div>
      )}
    </>
  );
}

export default BlogDetailsPage;
