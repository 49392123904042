import { API_URLS } from "../../configs/urls";
import { getToken } from "../../utils/helper";

export const stripePaymentMethodHandler = async (data, cb) => {
  const { amount, propertyid, coins, result } = data;
  if (result.error) {
    // show error in payment form
    cb(result);
  } else {
    const paymentResponse = await stripePayment({
      payment_method_id: result.paymentMethod.id,
      name: result.paymentMethod.billing_details.name,
      email: result.paymentMethod.billing_details.email,
      amount: amount,
      propertyid: propertyid,
      coins: coins,
    });
    cb(paymentResponse);
  }
};

// place backend API call for payment
const stripePayment = async (data) => {
  const token = getToken();
  const res = await fetch(API_URLS.BLITS.PAYMENT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  return await res.json();
};
