import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Spinner from "../../components/Loader";
import { showErrorMsg } from "../../utils/notification";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import whyChooseBanner from "../../assets/images/why-choose-banner.jpg";
import ratingIcon from "../../assets/images/rating.svg";
import benefitsIcon1 from "../../assets/images/lower-transaction.svg";
import benefitsIcon2 from "../../assets/images/faster-satttlements.svg";
import benefitsIcon3 from "../../assets/images/community-benefits.svg";
import benefitsIcon4 from "../../assets/images/global-accessibility.svg";
import benefitsIcon5 from "../../assets/images/loyalty-program.svg";
import benefitsIcon6 from "../../assets/images/transparent-decentralize.svg";
import quoteIcon from "../../assets/images/quote.svg";
import { addSellerInquiry } from "../../modules/inquiry/actions";
import { useNavigate } from "react-router-dom";

function InquirySellerPage() {
  const navigate = useNavigate();

  const agentFormSubmit = (values, { setSubmitting, resetForm }) => {
    var params = {
      fullName: values.fullName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      location: values.location,
      description: values.description,
    };
    addSellerInquiry(params).then(
      (res) => {
        setSubmitting(false);
        if (res?.statuscode === 200) {
          resetForm({ values: "" });
          navigate("/inquiry/thank-you");
        } else {
          showErrorMsg(res?.message);
        }
      },
      (err) => {
        setSubmitting(false);
        showErrorMsg(err.message);
      }
    );
  };

  const sellersSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter a name"),
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
    phoneNumber: Yup.string().required("Please enter a phone number"),
    location: Yup.string().required("Please enter a city name"),
    description: Yup.string().required("Please enter a description"),
  });

  const sellersValuesSchema = () => {
    return {
      fullName: "",
      email: "",
      phoneNumber: "",
      location: "",
      description: "",
    };
  };

  return (
    <>
      <section className="inquiry-sec common-sec" id="inquiryForm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="inquiry-con">
                <div className="sec-head">
                  <h5 className="sec-title title-sm">Are you Seller?</h5>
                  <p>Please fill this form!</p>
                </div>
                <Formik
                  initialValues={sellersValuesSchema()}
                  validationSchema={sellersSchema}
                  onSubmit={agentFormSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label>Full Name</label>
                          <Field
                            type="text"
                            placeholder="Enter your name"
                            className="form-control"
                            name="fullName"
                          />
                          <ErrorMessage
                            name="fullName"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Email id</label>
                          <Field
                            type="email"
                            placeholder="binseva@gmail.com"
                            className="form-control"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Phone number</label>
                          <Field
                            type="tel"
                            placeholder="Enter your phone number"
                            className="form-control"
                            name="phoneNumber"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Location (City Name)</label>
                          <Field
                            type="text"
                            placeholder="Enter your city name"
                            className="form-control"
                            name="location"
                          />
                          <ErrorMessage
                            name="location"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-lg-12 mb-3">
                          <label>Description about property</label>
                          <Field
                            as="textarea"
                            placeholder="Please tell us about property"
                            className="form-control"
                            name="description"
                            rows="5"
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-lg-12 text-center">
                          <button
                            type="submit"
                            className="def-btn float-none"
                            disabled={isSubmitting}
                          >
                            {isSubmitting && <Spinner size={true} />}
                            {!isSubmitting && <>Send Request</>}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-sec common-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="why-choose-img">
                <img src={whyChooseBanner} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-con">
                <div className="sec-head">
                  <h5 className="sec-title">Why Choose Blits Estates?</h5>
                </div>
                <div className="cms-con">
                  <p>
                    Welcome to the best and well known web3 real estate
                    marketplace: Blits Estates! Take advantage of the Blits
                    Coins strength, a unique cryptocurrency we created to
                    improve the way you sell real estate. Benefit from
                    blockchain technology's security, quicker settlements, and
                    cheaper transaction fees. Join a vibrant community of buyers
                    and sellers to ensure a smooth and successful selling
                    process. List your property with Blits Estates right away to
                    confidently and easily enter the future of real estate
                    investment. You can rely on us to give you a secure and
                    effective platform to meet your selling objectives.
                  </p>
                </div>
                <a href="#inquiryForm" className="def-btn ms-0">
                  Inquiry Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-benefits-sec common-sec">
        <div className="container">
          <div className="sec-head text-center">
            <h3 className="sec-title">Our Benefits</h3>
          </div>
          <div className="our-benefits-boxes">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon1} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Lower Transaction Fees
                    </h4>
                    <p>
                      Say goodbye to outrageous costs! Enjoy much lower
                      transaction fees with Blits Coin to make the most of your
                      real estate investments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon2} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Faster Settlements
                    </h4>
                    <p>
                      Time is money, and we value yours! Blits Coin facilitates
                      swift and efficient settlements, streamlining the buying
                      and selling process for all parties involved.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon3} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Exclusive Community Benefits
                    </h4>
                    <p>
                      As a member of Blits Estates, you have access to exclusive
                      benefits and advantages that enable you to maximize your
                      real estate efforts.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon4} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Global Accessibility
                    </h4>
                    <p>
                      Borders are irrelevant and the limitations of conventional
                      payment methods are gone with Blits Coin.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon5} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Rewarding Loyalty Program
                    </h4>
                    <p>
                      Your rewards increase as you use Blits Coin more
                      frequently. Earn loyalty points, get access to premium
                      deals, and open up enticing investment options.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon6} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Transparent and Decentralised
                    </h4>
                    <p>
                      Blits Coin runs on a decentralized and transparent
                      blockchain network, guaranteeing that all real estate
                      transactions are recorded openly and made available to all
                      users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="clientSaySec common-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="sec-head text-center">
                <h3 className="sec-title">What Our Clients Say?</h3>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            navigation={true}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              568: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1199: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="clientSaySlider"
          >
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blits Estates made selling my property a breeze! From the moment
                I connected with them through their user-friendly website, I was
                impressed with their efficiency and dedication. The team was
                friendly, professional, and always ready to address my concerns.
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Frankie Holman</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blits Estates was an outstanding partner during my selling
                experience! Their responsiveness and professionalism stood out
                while offering invaluable market knowledge that informed my
                decisions along the way. From initial inquiry through the
                closing of the deal, they were there every step of the way!
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Mike Frazier</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                I could not be more delighted by their expertise and results!
                From start to finish, their process for selling my property went
                seamlessly, while their team proved professional, efficient, and
                responsive in responding quickly to inquiries as well as
                providing exceptional guidance throughout.
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Van O'Neal</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blits Estates came highly recommended from me due to how quickly
                my property sold—earlier than anticipated! Their expertise in
                the real estate market combined with exceptional service made my
                journey through home selling easy, and each step was clearly
                understood.
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Carole Everett</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="faqSec common-sec pt-0">
        <div className="container">
          <div className="sec-head text-center">
            <h3 className="sec-title">FAQ’S</h3>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="accordion" data-wow-delay="0.1s" id="faqAcordian">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-1"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How does Web 3.0 technology benefit property buyers and
                      sellers on Blits Estates?
                    </button>
                  </div>
                  <div
                    id="faq-1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          Web 3.0 technology enhances property transactions by
                          providing faster, more cost-effective processes with
                          increased security and transparency. Smart contracts
                          automate key stages of the transaction, eliminating
                          the need for intermediaries and reducing paperwork.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-2"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How does Blits Estates work for property sellers?
                    </button>
                  </div>
                  <div
                    id="faq-2"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          As a property seller on Blits Estates, you can list
                          your property on the platform's DAO marketplace. This
                          allows investors to digitally invest in your property
                          and become part owners. Blits Coin, the native
                          cryptocurrency, enables property builders and owners
                          to obtain project funds. By listing your property, you
                          can attract investors and raise funds for your real
                          estate project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-3"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How can I list my property on Blits Estates?
                    </button>
                  </div>
                  <div
                    id="faq-3"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          To list your property on Blits Estates, you can
                          contact their customer service department via email at{" "}
                          <a href="mailto:contact@blitsestates.com">
                            contact@blitsestates.com
                          </a>
                          . They will guide you through the listing process and
                          provide any necessary assistance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-4"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How many properties can I list on Blits Estates?
                    </button>
                  </div>
                  <div
                    id="faq-4"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          There is no specific limit on the number of properties
                          you can list on Blits Estates. You can list several
                          properties depending on the amount you are willing to
                          invest and the available shares.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InquirySellerPage;
