import UserHeader from "../../components/UserHeader/UserHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserInvestment } from "../../modules/dashboard/actions";
import EmptyImage from "../../assets/illustration/empty.svg";
import blitImg from "../../assets/images/chinese-coin.png";
import moment from "moment";
import banImg from "../../assets/images/img.jpg";
import { getConversion } from "../../modules/blits/actions";
import Spinner from "../../components/Loader";

function MyInvestments() {
  const navigate = useNavigate();
  // for fetching data
  const [propertyList, setProperyList] = useState([]);
  const [isFetchingProperty, setIsFetchingProperty] = useState(false);
  // for property tab
  const [propIndex, setPropIndex] = useState(0);
  const [individualData, setIndividualData] = useState({});
  // for conversion rates
  const [conversion, setConversion] = useState("");

  useEffect(() => {
    getAllSideProperty();
    fetchRate();
  }, []);

  const selectProperty = (key) => {
    setPropIndex(key);
    setIndividualData(propertyList[key]);
  };

  const getAllSideProperty = () => {
    setIsFetchingProperty(true);
    getUserInvestment().then(
      (res) => {
        let list = res.data.data;
        setProperyList(list);
        setIndividualData(list[propIndex]);
        setIsFetchingProperty(false);
      },
      (err) => {
        setIsFetchingProperty(false);
      }
    );
  };

  const fetchRate = () => {
    getConversion().then(
      (res) => {
        let conversion = res.data.data[0].conversion;
        setConversion(conversion);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <>
      <UserHeader />
      {isFetchingProperty && (
        <div className="spinner-padding">
          <Spinner size={false} />
          <p>Loading....</p>
        </div>
      )}
      {!isFetchingProperty && (
        <section className="profle_content">
          <div className="container">
            {propertyList.length === 0 && !isFetchingProperty && (
              <>
                <div className="text-center pt-5">
                  <img src={EmptyImage} alt="EmptyImage" width={300} />
                  <h1 className="text-grey pt-5">No Investments made</h1>
                  <h2
                    className="green_link"
                    onClick={(e) => navigate("/property")}
                  >
                    + Make some investments now
                  </h2>
                </div>
              </>
            )}

            {isFetchingProperty && (
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 profle_left_sect">
                  <h3>
                    My Investments
                    <span onClick={(e) => navigate("/property")}>
                      + Make some investments
                    </span>
                  </h3>
                  <div
                    className="property_invested under_varification text-center justify-content-center"
                    style={{ height: "25vh" }}
                  >
                    <h3 className="text-grey text-center mt-3">Loading...</h3>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 profile_right_side">
                  <div className="col-12 mt-3 holding_details">
                    <div className="ab-amnty" style={{ height: "35vh" }}>
                      <h1 className="text-grey text-center mt-5 pt-3">
                        Loading...
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {propertyList.length > 0 && !isFetchingProperty && (
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 profle_left_sect">
                  <h3>
                    Properties in which I’ve invested{" "}
                    <span onClick={(e) => navigate("/property")}>
                      + Invest more
                    </span>
                  </h3>
                  {/* {propertyList.length === 0 && !isFetchingProperty && (
                <h6 className="text-center text-grey pt-5">No Investments made</h6>
              )} */}
                  {propertyList.length > 0 &&
                    !isFetchingProperty &&
                    propertyList.map((data, index) => (
                      <div
                        className={`property_invested ${
                          propIndex === index ? "_selectedProperty" : ""
                        }`}
                        key={index}
                        onClick={(e) => selectProperty(index)}
                      >
                        <div className="property_inv_img">
                          <div className="property_inv_img_in">
                            <img src={data.tile_image} alt="" />
                          </div>
                          <span>
                            {moment(data.bought_on).format("DD MMM YYYY")}{" "}
                          </span>
                        </div>
                        <div className="property_inv_details">
                          <h4>{data.title}</h4>
                          <small>
                            {data.city}, {data.country}
                          </small>
                          <span className="bl_amount">
                            <img src={blitImg} /> 235 BTS invested
                          </span>
                          <span className="bl_sh_budget">
                            Shares bought:{" "}
                            <strong>{data.quantity} shares</strong>
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 profile_right_side">
                  <div className="proprty_title">
                    <div className="proprty_title_in">
                      <h1>
                        {individualData.title} <span>Residential</span>
                      </h1>
                      <small>
                        {individualData.city}, {individualData.country}
                      </small>
                    </div>

                    <button type="button" className="def-btn">
                      Download report
                    </button>
                  </div>

                  <div className="property_price">
                    <strong>${individualData.cost}</strong>{" "}
                    {individualData.cost * conversion} Blits Coins
                  </div>

                  <div className="proprty_inv_details col-12">
                    <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-12 b-dets">
                        <div className="ownr-name">
                          <div className="own-stat">
                            {individualData?.user_name?.charAt(0)}
                          </div>
                          <div>
                            <small>Owner/Builder</small>
                            <span className="ow-nme">
                              {individualData.user_name}
                            </span>
                          </div>
                        </div>
                        <div className="sub-status">
                          <small>Launched on</small>
                          <p>
                            {moment(individualData.created_at).format(
                              "DD MMM YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-12 b-dets">
                        <div className="property_inv_img_in">
                          <img src={individualData.tile_image} alt="" />
                          <span className="gallery_number">14 more...</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="invested_date">
                    {/* <img src={invImg} /> */}
                    Invested on{" "}
                    {moment(individualData.bought_on).format("DD MMM YYYY")}
                  </div>

                  <div className="col-12 mt-3 holding_details">
                    <div className="ab-amnty">
                      <table width="100%">
                        <tbody>
                          <th>
                            <td>
                              <h2>My holdings and investment details</h2>
                            </td>
                          </th>
                          <tr>
                            <td>Shares I bought</td>
                            <td>
                              : {individualData.quantity} shares (
                              {(
                                individualData.quantity / individualData.shares
                              ).toFixed(2) * 100}
                              % of total available)
                            </td>
                          </tr>
                          <tr>
                            <td>Purchased value of unit share</td>
                            <td>
                              : {individualData.share_value * conversion}BTS (~$
                              {individualData.share_value})
                            </td>
                          </tr>
                          <tr className="cur_value">
                            <td>Current value of unit share</td>
                            <td>
                              : {individualData.share_value * conversion}BTS (~$
                              {individualData.share_value})
                            </td>
                          </tr>
                          <tr>
                            <td>Change in share value</td>
                            <td className="ch_value">: 0 BTS (0%)</td>
                          </tr>
                          <tr>
                            <td>Remaining shares to buy</td>
                            <td className="ch_sold">
                              :{" "}
                              {individualData.shares -
                                individualData.shares_sold}{" "}
                              shares
                            </td>
                          </tr>
                          <tr>
                            <td>Total dilution</td>
                            <td>
                              :{" "}
                              {individualData.shares *
                                individualData.share_value}{" "}
                              ({individualData.dilution}%) /{" "}
                              {individualData.shares} shares
                            </td>
                          </tr>
                          {/* <tr>
                            <td>Base investment</td>
                            <td>: $999 (25633 Blits coins)</td>
                          </tr>
                          <tr>
                            <td>Highest possible</td>
                            <td>: $100K</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-12 mt-4 ab-invest ow-adrs">
                    <h2>Transaction details</h2>
                    <table width="80%">
                      <tr>
                        <td>Transaction type</td>
                        <td>: With BTS</td>
                      </tr>
                      <tr>
                        <td>Wallet connected</td>
                        <td>: Metamask</td>
                      </tr>
                      <tr>
                        <td>Crypto used</td>
                        <td>: BTS</td>
                      </tr>
                      <tr>
                        <td>Transaction ID</td>
                        <td>: THP45789 25478 THD 962547</td>
                      </tr>
                      <tr>
                        <td>Date and time</td>
                        <td>
                          : Invested on{" "}
                          {moment(individualData.bought_on).format(
                            "DD MMM YYYY"
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div className="view_listing col-12">
                    <a href="#">
                      Click here to see the details of this property
                    </a>
                  </div>

                  <div className="view_listing col-12">
                    <button className="more-det">Share details on mail</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}
export default MyInvestments;
