import { API_URLS } from "../../configs/urls";
import axios from "axios";
import { getToken } from "../../utils/helper";
/**
 *  Get amenities
 */
export async function getAmenities() {
  try {
    const response = await axios({
      method: "get",
      url: API_URLS.LISTING.AMENITIES,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Get all locations
 */
export async function getLocations() {
  try {
    const response = await axios({
      method: "get",
      url: API_URLS.LISTING.LOCATIONS,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Get all property
 */
export async function getProperties(url) {
  try {
    const response = await axios({
      method: "get",
      url: API_URLS.LISTING.PROPERTIES + "?" + url,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Get property details
 */
export async function getPropertyDetails(url) {
  try {
    const response = await axios({
      method: "get",
      url: API_URLS.LISTING.PROPERTY_DETAIL + "/" + url,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Get other property
 */
export async function getOtherProperties(limit) {
  try {
    const response = await axios({
      method: "get",
      url: API_URLS.LISTING.OTHER_PROPERTIES + "?limit=" + limit,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  List property
 */
export async function listProperty(params) {
  const token = getToken();
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.LISTING.CREATE_PROPERTY,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Edit property
 */
export async function editProperty(params) {
  try {
    const token = getToken();
    const response = await axios({
      method: "post",
      url: API_URLS.LISTING.EDIT_PROPERTY,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Get shares
 */
export async function buyShares(params) {
  const token = getToken();
  try {
    const response = await axios({
      method: "get",
      url:
        API_URLS.LISTING.GET_SHARES +
        "?propertyId=" +
        params.propertyId +
        "&quantity=" +
        params.quantity,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
