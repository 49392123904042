import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Spinner from "../../components/Loader";
import { showErrorMsg } from "../../utils/notification";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import whyChooseBanner from "../../assets/images/why-choose-banner.jpg";
import ratingIcon from "../../assets/images/rating.svg";
import benefitsIcon1 from "../../assets/images/create-your-wallet.svg";
import benefitsIcon2 from "../../assets/images/blitscoin-integration.svg";
import benefitsIcon3 from "../../assets/images/browse-properties.svg";
import benefitsIcon4 from "../../assets/images/select-dream.svg";
import benefitsIcon5 from "../../assets/images/review-confirm.svg";
import benefitsIcon6 from "../../assets/images/ownership-transfer.svg";
import quoteIcon from "../../assets/images/quote.svg";
import { addBuyerInquiry } from "../../modules/inquiry/actions";
import { useNavigate } from "react-router-dom";

function InquiryBuyerPage() {
  const navigate = useNavigate();

  const buyerFormSubmit = (values, { setSubmitting, resetForm }) => {
    var params = {
      name: values.fullName,
      email: values.email,
      phone: values.phoneNumber,
      type: values.purchaseType === "property" ? false : true,
      description: values.description,
    };
    addBuyerInquiry(params).then(
      (res) => {
        setSubmitting(false);
        if (res?.statuscode === 200) {
          resetForm({ values: "" });
          navigate("/inquiry/thank-you");
        } else {
          showErrorMsg(res?.message);
        }
      },
      (err) => {
        setSubmitting(false);
        showErrorMsg(err.message);
      }
    );
  };

  const buyerSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter a name"),
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
    phoneNumber: Yup.string().required("Please enter a phone number"),
    description: Yup.string().required("Please enter a description"),
  });

  const buyerValuesSchema = () => {
    return {
      fullName: "",
      email: "",
      phoneNumber: "",
      purchaseType: "blitscoin",
      description: "",
    };
  };

  return (
    <>
      <section className="inquiry-sec common-sec" id="inquiryForm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="inquiry-con">
                <div className="sec-head">
                  <h5 className="sec-title title-sm">Are you a Buyer?</h5>
                  <p>Please fill this form!</p>
                </div>
                <Formik
                  initialValues={buyerValuesSchema()}
                  validationSchema={buyerSchema}
                  onSubmit={buyerFormSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label>Full Name</label>
                          <Field
                            type="text"
                            placeholder="Enter your name"
                            className="form-control"
                            name="fullName"
                          />
                          <ErrorMessage
                            name="fullName"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Email id</label>
                          <Field
                            type="email"
                            placeholder="binseva@gmail.com"
                            className="form-control"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Phone number</label>
                          <Field
                            type="tel"
                            placeholder="Enter your phone number"
                            className="form-control"
                            name="phoneNumber"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label>Purchase Type</label>
                          <div className="mt-2">
                            <div className="form-check form-check-inline">
                              <Field
                                type="radio"
                                name="purchaseType"
                                value="blitscoin"
                                id="purchase_1"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="purchase_1"
                              >
                                Blits Coin
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Field
                                type="radio"
                                name="purchaseType"
                                value="property"
                                id="purchase_2"
                                className="form-check-input"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="purchase_2"
                              >
                                Property
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 mb-3">
                          <label>Description about property</label>
                          <Field
                            as="textarea"
                            placeholder="Please tell us about property"
                            className="form-control"
                            name="description"
                            rows="5"
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                        <div className="col-lg-12 text-center">
                          <button
                            type="submit"
                            className="def-btn float-none"
                            disabled={isSubmitting}
                          >
                            {isSubmitting && <Spinner size={true} />}
                            {!isSubmitting && <>Send Request</>}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-sec common-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="why-choose-img">
                <img src={whyChooseBanner} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-con">
                <div className="sec-head">
                  <h5 className="sec-title">Why Choose Blits Estates?</h5>
                </div>
                <div className="cms-con">
                  <p>
                    At Blits Estates, By taking advantage of blockchain and web3
                    technologies, Blits Estates has changed the process of
                    purchasing real estate. Our platform lets customers learn
                    real estate using Blits Coin, offering a number of
                    advantages that make the process simple, secure, and
                    financially rewarding.
                  </p>
                </div>
                <a href="#inquiryForm" className="def-btn ms-0">
                  Inquiry Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-benefits-sec common-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-md-10">
              <div className="sec-head text-center">
                <h3 className="sec-title title-sm">
                  So, how can you start buying property using Blits Coin on our
                  web3 platform?
                </h3>
              </div>
            </div>
          </div>
          <div className="our-benefits-boxes">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon1} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Create Your Wallet
                    </h4>
                    <p>
                      First, you'll need a digital wallet that supports Blits
                      Coin. Choose from one of the compatible wallets to
                      securely store and manage your cryptocurrency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon2} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Purchase Blits Coin
                    </h4>
                    <p>
                      Acquire Blits Coin from various exchanges or directly
                      through our platform.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon3} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Browse Properties
                    </h4>
                    <p>
                      Explore our diverse range of properties listed on the
                      website, both residential and commercial, located in prime
                      areas worldwide.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon4} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Select Your Dream Property
                    </h4>
                    <p>
                      Once you find your ideal property, proceed to the checkout
                      page, where you can initiate the purchase using Blits
                      Coin.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon5} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Review and Confirm
                    </h4>
                    <p>
                      Double-check all the transaction details and review the
                      smart contract terms before confirming the purchase.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="our-benefits-box">
                  <div className="our-benefits-box-img">
                    <img src={benefitsIcon6} alt="" />
                  </div>
                  <div className="our-benefits-box-con">
                    <h4 className="our-benefits-box-title">
                      Ownership Transfer
                    </h4>
                    <p>
                      Upon completing the transaction, the smart contract will
                      automatically transfer ownership to your wallet once the
                      predefined conditions are met.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="clientSaySec common-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="sec-head text-center">
                <h3 className="sec-title">What Our Clients Say?</h3>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            navigation={true}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              568: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1199: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="clientSaySlider"
          >
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Thank you to Blits Estates for making my house hunting journey
                simpler! Their website was easy to use, while their team
                provided outstanding support throughout my property hunt—they
                perfectly understood my needs before providing great solutions
                that met them!
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Wade Dickerson</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blits Estates was an absolute pleasure to deal with! Their
                website contains properties that perfectly meet my property
                criteria; moreover, their staff was quick to reply to my
                inquiries!
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Richard McDowell</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blits Estates was an invaluable partner when I found my perfect
                property! If you need reliable real estate advice, Blits Estates
                should be at the top of your list!
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Andres Gentry</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="clientSaySlide">
              <div className="clientSayQuote">
                <img src={quoteIcon} alt="" />
              </div>
              <p>
                Blits Estates was an invaluable partner during my search for
                property! Their user-friendly website made my property search
                easier, and their staff provided excellent customer service
                according to my unique requirements while providing numerous
                options within my budget range. Their knowledge of local markets
                enabled informed choices
              </p>
              <div className="clientSayUser clientSayUserSEC">
                <h5 className="clientSayUserName">Carolina Newman</h5>
                <div className="clientSayRating">
                  <img src={ratingIcon} alt="" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="faqSec common-sec pt-0">
        <div className="container">
          <div className="sec-head text-center">
            <h3 className="sec-title">FAQ’S</h3>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="accordion" data-wow-delay="0.1s" id="faqAcordian">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-1"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Can I use cryptocurrency to transact on Blits Estates?
                    </button>
                  </div>
                  <div
                    id="faq-1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          Yes, Blits Estates accepts various cryptocurrencies
                          for property transactions. You can use popular
                          cryptocurrencies like Bitcoin (BTC), Ethereum (ETH),
                          and other ERC-20 tokens supported on the platform.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-2"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How can I purchase Blits Coin?
                    </button>
                  </div>
                  <div
                    id="faq-2"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          To purchase Blits Coin, you need to download the
                          MetaMask Chrome Extension and follow the installation
                          procedure. Create a password for your wallet and
                          connect it to the address specified on the Blits
                          Estates website. Blits Coin can be purchased using
                          USD, and after the purchase, you can buy property
                          shares on the Blits Estates Marketplace.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-3"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How are the documents provided by the sellers verified?
                    </button>
                  </div>
                  <div
                    id="faq-3"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          Blits Estates independently verifies all contracts
                          before converting them into smart contracts. This
                          ensures the documents are immutable and impossible to
                          forge. As an investor, you can also examine the
                          contract for any discrepancies before making your
                          investment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-4"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How does ownership work in Blits Estates?
                    </button>
                  </div>
                  <div
                    id="faq-4"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          Blits Estates operates on a share-based DAO
                          organization. Ownership is determined by the number of
                          shares each member holds. Voting rights and profit
                          sharing are proportionate to the number of shares
                          held. As an investor, owning shares entitles you to
                          property holdings and a share of the profits from the
                          investment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-5"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Who can I contact for any issues or inquiries?
                    </button>
                  </div>
                  <div
                    id="faq-5"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          BlitsEstates has a 24x7 customer service department.
                          For any issues or inquiries, you can reach out to them
                          by emailing{" "}
                          <a href="mailto:contact@blitsestates.com">
                            contact@blitsestates.com
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-6"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How does Web 3.0 technology benefit property buyers and
                      sellers on Blits Estates?
                    </button>
                  </div>
                  <div
                    id="faq-6"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAcordian"
                  >
                    <div className="accordion-body">
                      <div className="cms-con">
                        <p>
                          Web 3.0 technology enhances property transactions by
                          providing faster, more cost-effective processes with
                          increased security and transparency. Smart contracts
                          automate key stages of the transaction, eliminating
                          the need for intermediaries and reducing paperwork.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InquiryBuyerPage;
