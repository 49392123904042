import { API_URLS } from "../../configs/urls";
import axios from "axios";

/**
 *  Inquiry Agent
 */
export async function addAgentInquiry(params) {
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.INQUIRY.AGENT,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Inquiry Seller
 */
export async function addSellerInquiry(params) {
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.INQUIRY.SELLERS,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Inquiry Buyer
 */
export async function addBuyerInquiry(params) {
  try {
    const response = await axios({
      method: "post",
      url: API_URLS.INQUIRY.BUYER,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
