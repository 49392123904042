import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "../../modules/auth/actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Loader";

function ForgotPassword() {
  const navigate = useNavigate();

  const forgotValuesSchema = () => {
    return {
      email: "",
    };
  };

  const forgotSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email")
      .required("Please enter a valid email"),
  });

  const sendResetLink = (values, { setSubmitting, resetForm }) => {
    var params = {
      email: values.email,
      password: values.password,
    };
    forgotPassword(params).then(
      (res) => {
        showSuccessMsg(res.message);
        setSubmitting(false);
        navigate("/reset-password-success");
      },
      (err) => {
        setSubmitting(false);
        showErrorMsg(err.message);
      }
    );
  };
  return (
    <>
      <section className="reg-wrap">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 mx-auto">
              <div className="forgot-reg-in">
                <h1>Forgot Password?</h1>
                <p>Reset your password on a click now!</p>

                <Formik
                  initialValues={forgotValuesSchema}
                  validationSchema={forgotSchema}
                  onSubmit={sendResetLink}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 mb-3">
                          <label>Email id</label>
                          <Field
                            type="email"
                            placeholder="binseva@gmail.com"
                            className="form-control"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>

                        <div className="col-xl-12 col-lg-12 for-forgot">
                          <button type="submit" className="def-btn ml-auto">
                            {isSubmitting && <Spinner size={true} />}
                            {!isSubmitting && <>Send Reset Link now</>}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
