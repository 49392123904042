import { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { getConversion } from "../../modules/blits/actions";
import { buyShares } from "../../modules/listing/actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import withRouter from "../../utils/withRouter";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import sharesABI from "../../utils/sharesABI";
import tokenABI from "../../utils/tokenABI";
import Spinner from "../../components/Loader";

const PaymentArea = (props) => {
  let data = props.propertyDetail;
  let perSharePrice = data.share_value;
  let leftShares = data.shares - data.purchased;
  const navigate = useNavigate();
  const [paymentActive, setPaymentActive] = useState(false);
  const [paymentMode, setpaymentMode] = useState("blits");
  // for modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // let amount = 1000;
  // let coinCount = 1000;
  // let params = { amount, coinCount };

  // for meta mask
  const [web3Enabled, setWeb3Enabled] = useState(false);

  const [accounts, setAccounts] = useState([]);

  const [conversion, setConversion] = useState("");

  const [coinAmt, setCoinAmt] = useState(null);

  const [shareAmt, setShareAmt] = useState(null);

  const [nameContract, setNameContract] = useState(null);

  const [appContract, setAppContract] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ethEnabled();
  }, []);

  let web3 = new Web3();

  const ethEnabled = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information from the MetaMask provider information
      web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.enable();

        metamaskDetails();
        return true;
      } catch (e) {
        // User denied access
        return false;
      }
    } else {
      alert("Please install MetaMask to use this service!");
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
    }

    return false;
  };
  // var Badd = '0x330BB4feFd3ef8c30F04768E14D8B570577c7923';

  const metamaskDetails = async () => {
    setWeb3Enabled(true);
    var accs = await web3.eth.getAccounts();
    setAccounts(accs);
    const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
    //const tokenAddress = "0x452bBde39d3a14965da2448bfCbDcC8BfEDF8c11";
    const shareAddress = data.wallet_address;

    const NameContract = new web3.eth.Contract(sharesABI, shareAddress);
    const ApproveContract = new web3.eth.Contract(tokenABI, tokenAddress);

    setNameContract(NameContract);
    setAppContract(ApproveContract);
  };
  const showConfirmation = () => {
    if (
      shareAmt !== null &&
      shareAmt <= leftShares &&
      shareAmt <= data.max_share
    ) {
      setShow(true);
    } else if (shareAmt > leftShares) {
      showErrorMsg("Not enough shares available");
    } else if (shareAmt > data.max_share) {
      showErrorMsg(`You can only buy ${data.max_share} shares at max`);
    } else {
      showErrorMsg("Please select a share amount.");
    }
  };

  const confirmPurchase = async () => {
    setIsLoading(true);
    var params = {
      propertyId: data.id,
      quantity: shareAmt,
    };
    // share amt x share price
    await appContract.methods
      .approve(data.wallet_address, shareAmt * perSharePrice)
      .send({ from: accounts[0] })
      .on("transactionHash", function (hash) {})
      .on("receipt", function (receipt) {
        nameContract.methods
          .buyShares(shareAmt)
          .send({ from: accounts[0] })
          .on("transactionHash", function (hash) {})
          .on("receipt", function (receipt) {
            buyShares(params).then(
              (res) => {
                setIsLoading(false);
                setShow(false);
                showSuccessMsg("Your Purchase was successful.");
                navigate("/my-investments");
              },
              (err) => {
                setShow(false);
                setIsLoading(false);
                showErrorMsg(
                  "Something went wrong with purchase. Please contact admin."
                );
              }
            );
            // location.reload();
          })
          .on("error", function (error, receipt) {
            console.log(error);
            setShow(false);
            setIsLoading(false);
            showErrorMsg("Payment rejected");
          });
        // window.location.reload();
      })
      .on("error", function (error, receipt) {
        showErrorMsg("Something went wrong. Please contact admin");
        console.log(error);
      });
  };

  const buyBlitsRoute = () => {
    if (coinAmt === null) {
      showErrorMsg("Please select an amount");
    } else {
      navigate("/buytoken", {
        state: {
          coinAmt: coinAmt,
        },
      });
    }
  };

  useEffect(() => {
    fetchRate();
  }, []);

  const fetchRate = () => {
    getConversion().then(
      (res) => {
        let conversion = res.data.data[0].conversion;
        setConversion(conversion);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const buyCoinTabs = [
    {
      title: "5K",
      coins: 5000,
    },
    {
      title: "10K",
      coins: 10000,
    },
    {
      title: "15K",
      coins: 15000,
    },
    {
      title: "20K",
      coins: 20000,
    },
  ];
  const buySharesTab = [
    {
      title: "5K",
      coins: 5000,
    },
    {
      title: "10K",
      coins: 10000,
    },
    {
      title: "15K",
      coins: 15000,
    },
    {
      title: "20K",
      coins: 20000,
    },
  ];

  return (
    <div>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>Purchase Confirmation</Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <Modal.Body>
            Purchasing the shares. This might take a while...
          </Modal.Body>
        ) : (
          <Modal.Body>
            Do you really want to buy {shareAmt} shares of {data.title}?
          </Modal.Body>
        )}
        <Modal.Footer>
          {isLoading ? (
            <Button variant="primary" disabled>
              Processing &nbsp; <Spinner size={true} />
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={confirmPurchase}>
                Yes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {!paymentActive ? (
        <>
          <div className="d-main-buttons">
            <h3 className="mt-4">Interested in buying the shares?</h3>
            <td>
              {accounts.length > 0 ? (
                <div
                  className="def-btn mt-2"
                  onClick={(e) => setPaymentActive(true)}
                >
                  Invest on this property
                </div>
              ) : (
                <div
                  className="def-btn mt-2"
                  // onClick={handleConnectOnce}
                >
                  Connect to metamask
                </div>
              )}
            </td>
          </div>
        </>
      ) : (
        <div>
          <h2 className="mt-4">Invest using crypto or fiat</h2>
          <Nav
            variant="tabs"
            defaultActiveKey="#blits"
            className="pay-tab mb-4"
          >
            <Nav.Item>
              <Nav.Link href="#blits" onClick={(e) => setpaymentMode("blits")}>
                Via Blits Coin(BTS)
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#fiat" onClick={(e) => setpaymentMode("fiat")}>
                Via fiat($)
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {paymentMode === "blits" ? (
            <>
              {data.wallet_address !== null ? (
                <>
                  <div className="cur-btns">
                    {buySharesTab.length > 0 &&
                      buySharesTab.map((data, index) => (
                        <>
                          {(data.coins * conversion) / perSharePrice <=
                          leftShares ? (
                            <button
                              className={`${
                                shareAmt === data.coins ? "cur-btn-active" : ""
                              }`}
                              type="button"
                              key={index}
                              onClick={(e) =>
                                setShareAmt(
                                  Math.round(
                                    (data.coins * conversion) / perSharePrice
                                  )
                                )
                              }
                            >
                              {data.title} Coins
                              <span>
                                {(
                                  (data.coins * conversion) /
                                  perSharePrice
                                ).toFixed(2)}{" "}
                                shares
                              </span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              key={index}
                              className="disabled_tab"
                              onClick={(e) =>
                                showErrorMsg(
                                  (
                                    (data.coins * conversion) /
                                    perSharePrice
                                  ).toFixed(2) +
                                    " Shares are not available at this property."
                                )
                              }
                            >
                              {data.title} Coins
                              <span>
                                {(
                                  (data.coins * conversion) /
                                  perSharePrice
                                ).toFixed(0)}{" "}
                                shares
                              </span>
                            </button>
                          )}
                        </>
                      ))}
                  </div>
                  <p>Or enter manually</p>
                  <div className="enter-man">
                    <span className="inside-inp">
                      <input
                        type="text"
                        placeholder="Enter here..."
                        onChange={(e) =>
                          setShareAmt(
                            Math.round(
                              (e.target.value * conversion) / perSharePrice
                            )
                          )
                        }
                      />
                      <small>Coins</small>
                    </span>
                    {shareAmt !== null ? `~ ${shareAmt} share` : null}
                  </div>
                  <div className="ftr-buy">
                    <button
                      type="button"
                      className="cancel-btn"
                      onClick={(e) => setPaymentActive(false)}
                    >
                      Cancel
                    </button>
                    {accounts.length > 0 ? (
                      <button
                        type="button"
                        className="buy-btn"
                        onClick={showConfirmation}
                      >
                        Buy shares
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="buy-btn"
                        onClick={ethEnabled}
                      >
                        Connect Metamask
                      </button>
                    )}
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <>
              <div className="cur-btns">
                {buyCoinTabs.length > 0 &&
                  buyCoinTabs.map((data, index) => (
                    <button
                      className={`${
                        coinAmt === data.coins ? "cur-btn-active" : ""
                      }`}
                      type="button"
                      key={index}
                      onClick={(e) => setCoinAmt(data.coins)}
                    >
                      {data.title} Coins
                      <span>{data.coins * conversion} usd</span>
                    </button>
                  ))}
              </div>
              <div className="ftr-buy">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={(e) => setPaymentActive(false)}
                >
                  Cancel
                </button>
                {accounts.length > 0 ? (
                  <button
                    type="button"
                    className="buy-btn"
                    onClick={buyBlitsRoute}
                  >
                    Buy coins
                  </button>
                ) : (
                  <button
                    type="button"
                    className="buy-btn"
                    onClick={ethEnabled}
                  >
                    Connect Metamask
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default withRouter(PaymentArea);
