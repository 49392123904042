import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { stripePaymentMethodHandler } from "../../modules/payment/actions";
const API_ENDPOINT = process.env.REACT_APP_BASE_URL;
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CheckoutForm(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    setErrorMsg("");

    const paymentMethodObj = {
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email,
      },
    };
    const paymentMethodResult = await stripe.createPaymentMethod(
      paymentMethodObj
    );

    stripePaymentMethodHandler(
      {
        result: paymentMethodResult,
        amount: props.amount,
        propertyid: props.plan,
        coins: props.blitscount,
      },
      handleResponse
    );
  };

  // callback method to handle the response
  const handleResponse = (response) => {
    setLoading(false);
    if (response.error) {
      setErrorMsg(
        typeof response.error === "string"
          ? response.error
          : response.error.message
      );
      return;
    }
    props.setPaymentCompleted(response.success ? true : false);
    setTimeout(function () {
      navigate("/my-blitscoin");
    }, 1000);
  };
  // subscription -----
  const handleSubmitSub = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);
    setErrorMsg("");

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: name,
        email: email,
      },
    });
    if (result.error) {
      setLoading(false);
      setErrorMsg(result.error.message);
    } else {
      const res = await fetch(`${API_ENDPOINT}/sub`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          payment_method: result.paymentMethod.id,
          email: email,
          name: name,
          plan: props.plan,
        }),
      });

      //const movies = await res.json();  //sudeep
      const {
        client_secret,
        status,
        invoice,
        sub_id,
        user_id,
        duration_ends,
        error,
      } = await res.json();

      // eslint-disable-next-line camelcase

      //const {client_secret, status} = res.data;
      if (status === "requires_action") {
        stripe.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            if (result.error) {
              setErrorMsg(
                typeof result.error === "string"
                  ? result.error
                  : result.error.message
              );
              navigate("/details/" + props.productid, {
                state: { userId: null, error: result.error },
              });
              return;
            }
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
          } else {
            props.setPaymentCompleted(status ? true : false);
            navigate("/details/" + props.productid, {
              state: {
                userId: user_id,
                durationEnds: duration_ends,
                stripeId: sub_id,
              },
            });
          }
        });
      } else if (status === "already_exist") {
        props.setPaymentCompleted(status ? false : true);
        setLoading(false);
        setErrorMsg("You already purchased this product");
        navigate("/details/" + props.productid, {
          state: {
            userId: null,
            error: "You’ve already purchased this product",
            error_title: "Already Purchased",
          },
        });
      } else if (status === "no_plan") {
        props.setPaymentCompleted(status ? false : true);
        setLoading(false);
        setErrorMsg("No such plan");
        navigate("/details/" + props.productid, {
          state: { userId: null, error: error, error_title: "Failed" },
        });
      } else {
        setLoading(false);
        props.setPaymentCompleted(status ? true : false);
        navigate("/details/" + props.productid, {
          state: {
            userId: user_id,
            durationEnds: duration_ends,
            stripeId: sub_id,
          },
        });

        // No additional information was needed
        // Show a success message to your customer
      }
    }
  };

  //
  return (
    <React.Fragment>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Pay with card</span>
      </h4>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-name">Name on card</label>
            <input
              id="cc-name"
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-email">Email</label>
            <input
              id="cc-email"
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card Number</label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="expiry">Expiration Date</label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <button className="btn btn-dark w-100" type="submit" disabled={loading}>
          {loading ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            ></div>
          ) : (
            `SUBSCRIBE`
          )}
        </button>
        {errorMsg && (
          <div className="text-danger mt-2 text-center">{errorMsg}</div>
        )}
      </form>
    </React.Fragment>
  );
}
