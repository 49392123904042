import React from "react";
import demoImg from "../../assets/images/img.jpg";
import LocateImg from "../../assets/images/locate.svg";
import CoinImg from "../../assets/images/chinese-coin.png";
import "./pie.min.css";
import { useNavigate } from "react-router-dom";
import withRouter from "../../utils/withRouter";
import moment from "moment";

const PropertyCard = (props) => {
  const navigate = useNavigate();
  let item = props.item;
  let buyPercent = (item.purchased / item.shares) * 100;
  var utc = moment.utc(item.launched_at, "YYYY-MM-DD HH:mm:ss");
  var local = utc.local();

  let finalDate = local.add("days", item.open_window);
  var now = moment();
  let open_status;
  if (now < finalDate) {
    open_status = true;
  } else {
    open_status = false;
  }
  return (
    <>
      <div
        className="single-l-in"
        onClick={(e) =>
          navigate(`/detail-page/${item.public_url}`, { state: item })
        }
      >
        <div className="sin-img">
          <img src={item.tile_image} alt="" />
          {item.hold === 0 ? (
            <>
              {open_status ? (
                <span className="d-name">
                  Closing in: {finalDate.diff(now, "days")} days
                </span>
              ) : (
                <span className="r-name">Closing soon</span>
              )}
            </>
          ) : (
            <span className="r-name">Property on hold</span>
          )}
          <span className="post-dte-sng">
            <small>Launched on</small>
            {moment(item.launched_at).format("DD MMM YYYY")}
          </span>
        </div>
        <h3>{item.title}</h3>
        <span className="loct">
          <img src={LocateImg} alt="" />
          {item.city + ", " + item.country}
        </span>
        <span className="loct-d">${item.cost}</span>
        <div className="sub-status">
          <small>Subscription status</small>
          <div className="pie-red">
            <div className="pie-wrapper">
              <div className="csspie" data-value={buyPercent.toFixed(0)}></div>
              <p className="pie-label">{buyPercent.toFixed(2)}% subscribed</p>
            </div>
          </div>
        </div>
        <span className="dial">
          <small>Dilution:</small>
          <p>
            {item.shares * item.share_value} ({item.dilution}%) / {item.shares}{" "}
            {/* {item.shares / item.dilution} ({item.dilution}%) {item.shares}{" "} */}
            shares
          </p>
        </span>
        <div className="ownr-name">
          <small>Owner/Builder</small>
          <div className="own-stat">{item.user_name.charAt(0)}</div>
          <span className="ow-nme">{item.user_name}</span>
        </div>
        <span className="more-det">More Details</span>
      </div>
    </>
  );
};

export default withRouter(PropertyCard);
